import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import makeCarousel from "react-reveal/makeCarousel";
import translate from "../../i18n/translate";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import "./login.style.css";
import LoginLogo from '../../assets/login2.png'
import SquareBigImage from '../../assets/squareBig.svg'
import YellowDotImage from '../../assets/yellow_dot.png'

const useStyles = makeStyles((theme) => ({
  imgStyle: {
    position: "relative",
    width: "60%",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
  text: {
    position: "absolute",
    bottom: "15%",
    left: "5%",
  },
  titleStyle: {
    color: "white",
    fontFamily: "RobotoBoldCondensed",
    [theme.breakpoints.down('sm')]: {
      fontFamily: "RobotoMedium",
      fontWeight: "bold",
      fontSize: "1rem",
    },
  },
  descriptionText: {
    color: "white",
    fontFamily: "RobotoRegular",
    fontSize: "1rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.7rem",
    },
  },
  descriptionBox: {
    width: "30em",
    height: "100px",
  },
}));

const CarouselUI = ({ position, total, handleClick, children }) => (
  <div className="slider-container">
    <div className="slider-item">{children}</div>
    <span className="slider-dots">
      {Array(...Array(total)).map((val, index) => (
        <span
          className="slider-dot"
          key={index}
          onClick={handleClick}
          data-position={index}
        >
          {index === position ? "● " : "○ "}
        </span>
      ))}
    </span>
  </div>
);
const Carousel = makeCarousel(CarouselUI);

const LoginLogoCard = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.imgStyle}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={LoginLogo}
          alt="Login logo"
        />

        <Box className="animation-login">
          <Box>
            <img
              className="square-big"
              src={SquareBigImage}
              alt="animation square"
            />
          </Box>
        </Box>

        <Box className="animation2-login">
          <Box>
            <img
              className="square-small"
              src={SquareBigImage}
              alt="animation square"
            />
          </Box>
        </Box>

        <Box className="animation3-login">
          <Box>
            <img
              className="yellow-dot"
              src={YellowDotImage}
              alt="animation square"
            />
          </Box>
        </Box>
        <Box width="100%" className={classes.text}>
          <Typography variant="h5" className={classes.titleStyle}>
            {translate("loginTitle")}, <br /> {translate("loginSubTitle")}
          </Typography>
          <Carousel>
            <Fade top duration={500} enter={false}>
              <Slide left exit={false}>
                <div>
                  <p className={classes.descriptionText}>
                    {translate("loginSlider1")}
                  </p>
                </div>
              </Slide>
            </Fade>
            <Fade top duration={500} enter={false}>
              <Slide left exit={false}>
                <div>
                  <p className={classes.descriptionText}>
                    {translate("loginSlider2")}
                  </p>
                </div>
              </Slide>
            </Fade>
            <Fade top duration={500} enter={false}>
              <Slide left exit={false}>
                <div>
                  <p className={classes.descriptionText}>
                    {translate("loginSlider3")}
                  </p>
                </div>
              </Slide>
            </Fade>
          </Carousel>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default LoginLogoCard;

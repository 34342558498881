import React from "react";
import BrandsProvider from "../../lib/context/brands/BrandsProvider";
import BrandsTable from "../../components/table-brands";

const BrandsPage = ({dateFilter}) => {
  return (
    <BrandsProvider>
      <BrandsTable dateFilter={dateFilter}/>
    </BrandsProvider>
  );
};

export default BrandsPage;

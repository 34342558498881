import React, { useState, useContext, useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BrandsContext } from "../../../../lib/context/brands/BrandsContext";
import LogoCard from "../logo-card/LogoCard";
import Carousel, { consts } from "react-elastic-carousel";
import LeftIcon from "./Left";
import RightIcon from "./Right";
import Indicator from "./Indicator";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .slider-container": {
      width: "100%",
      height: "30px !important",
    },
  },
  navBtn: {
    background: "inherit",
    marginTop: "4%",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(150%)",
    },
  },
  indicator: {
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
    marginTop: 5,
  },
}));

const BrandSlider = ({ symbol, isTotal, dateFilter }) => {
  const classes = useStyles();

  const skeletonArray = Array(4).fill(Math.random());

  const { getBrandsData, brandsData } = useContext(BrandsContext);
  const [isLoaded, setIsLoaded] = React.useState(false);
  
  // eslint-disable-next-line
  const [breakPoints, setBreakPoints] = useState([
    { width: 1, itemsToShow: 2, itemsToScroll: 2 },
    { width: 550, itemsToShow: 3, itemsToScroll: 3 },
    { width: 850, itemsToShow: 4, itemsToScroll: 4 },
  ]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      await getBrandsData(dateFilter);
      setIsLoaded(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <RightIcon /> : <LeftIcon />;
    if (type === consts.PREV) {
    }
    return (
      <Box className={classes.navBtn} onClick={onClick} disabled={isEdge}>
        {pointer}
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Carousel
        breakPoints={breakPoints}
        renderArrow={myArrow}
        itemsToShow={4}
        itemsToScroll={4}
        style={{ marginTop: 0, marginBottom: 10 }}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <Box
              display="flex"
              flexDirection="row"
              className={classes.indicator}
            >
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return (
                  <Indicator
                    key={page}
                    onClick={() => onClick(page)}
                    activePage={isActivePage}
                  />
                );
              })}
            </Box>
          );
        }}
      >
        {isLoaded
          ? brandsData.map(
            ({
              brandLogo,
              totalLiters,
              totalLitersChange,
              qualityScoreChange,
              brandName,
              qualityScore,
            }) => (
              <LogoCard
                key={brandLogo + Math.random()}
                totalLitersChange={isTotal ? totalLitersChange * 100 : qualityScoreChange * 100}
                brandLogo={brandLogo}
                totalLiters={isTotal ? totalLiters : qualityScore}
                brandName={brandName}
                isLoaded={isLoaded}
                symbol={symbol}
                isTotal={isTotal}
              />
            )
          )
          : skeletonArray.map((item) => (
            <Skeleton
              key={item * Math.random()}
              variant="circular"
              height="75px"
              width="75px"
            />
          ))}
      </Carousel>
    </div>
  );
};

export default BrandSlider;

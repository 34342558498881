import React from "react";

const QualityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.101"
      height="19.293"
      viewBox="0 0 8.101 19.293"
    >
      <g
        id="Group_170"
        data-name="Group 170"
        transform="translate(-3481.675 -1341.774)"
      >
        <path
          id="Path_306"
          data-name="Path 306"
          d="M3489.771,1345.4l0-.041h-.006a1.291,1.291,0,0,0-1.035-1.17,1.493,1.493,0,0,0-2.294-1.023,1.645,1.645,0,0,0-3.254.021,1.747,1.747,0,0,0-1.512,1.729,1.3,1.3,0,0,0,.093.445h0l.373,3.759h5.46a6.858,6.858,0,0,1-.27.9v0c-.021.063-.054.162-.063.185a13.547,13.547,0,0,0-1.079,4.9,10.54,10.54,0,0,0,.6,3.336l.06.167h1.728l-.1-.327a9.315,9.315,0,0,1,.215-7.044l.082-.234c.126-.362.3-.808.3-.812l0-.009a9.225,9.225,0,0,0,.342-1.249l.005-.013.344-3.46a.082.082,0,0,0,.006-.028C3489.772,1345.421,3489.772,1345.411,3489.771,1345.4Zm-.847,3.422a8.836,8.836,0,0,1-.326,1.192c-.018.049-.179.472-.3.822l-.082.233a11.692,11.692,0,0,0-.832,4.039,11.056,11.056,0,0,0,.512,2.995h-.689a10.19,10.19,0,0,1-.516-3.016,13.081,13.081,0,0,1,1.045-4.7c.016-.042.06-.174.071-.206a5.233,5.233,0,0,0,.336-1.289l.032-.281H3482.6l-.327-3.3h0a1.172,1.172,0,0,1-.1-.4,1.247,1.247,0,0,1,1.246-1.246.249.249,0,0,0,.249-.249,1.146,1.146,0,1,1,2.22.4.249.249,0,0,0,.461.189,1,1,0,0,1,1.9.411.249.249,0,0,0,.25.249.748.748,0,0,1,.748.748c0,.035,0,.069,0,.1Z"
          transform="translate(0 0)"
          fill="#9ca7b6"
        />
        <path
          id="Path_307"
          data-name="Path 307"
          d="M3630.74,1392.048a.105.105,0,0,0,0,.21.682.682,0,0,1,.682.681.1.1,0,1,0,.21,0A.892.892,0,0,0,3630.74,1392.048Z"
          transform="translate(-143.589 -48.461)"
          fill="#9ca7b6"
        />
        <path
          id="Path_308"
          data-name="Path 308"
          d="M3563.373,1360.06a.1.1,0,1,0,0,.21.682.682,0,0,1,.681.682.1.1,0,0,0,.21,0A.892.892,0,0,0,3563.373,1360.06Z"
          transform="translate(-78.651 -17.627)"
          fill="#9ca7b6"
        />
        <path
          id="Path_309"
          data-name="Path 309"
          d="M3652.552,1424.217a.1.1,0,1,0,.1.1A.1.1,0,0,0,3652.552,1424.217Z"
          transform="translate(-164.614 -79.471)"
          fill="#9ca7b6"
        />
        <path
          id="Path_310"
          data-name="Path 310"
          d="M3585.183,1391.5a.1.1,0,1,0,.105.1A.1.1,0,0,0,3585.183,1391.5Z"
          transform="translate(-99.674 -47.934)"
          fill="#9ca7b6"
        />
        <path
          id="Path_311"
          data-name="Path 311"
          d="M3490.858,1570.091c.056-.144.124-.317.193-.489a10.781,10.781,0,0,0,.758-3.809,14.055,14.055,0,0,0-1.119-5.083c-.018-.047-.043-.1-.044-.107l-.071-.162h-1.88l.145.369s.139.355.27.729l.085.242a11.674,11.674,0,0,1,.833,4.021,14.567,14.567,0,0,1-1.122,4.732l-.029.076c-.066.179-.329.866-.332.873l-.141.367h7.635l-.677-1.77Zm-1.669,1.218c.075-.2.159-.419.194-.513,0-.011.012-.033.025-.065a15.079,15.079,0,0,0,1.158-4.935,12.13,12.13,0,0,0-.863-4.194l-.085-.241c-.047-.135-.094-.266-.136-.379h.732a13.393,13.393,0,0,1,1.054,4.792,10.274,10.274,0,0,1-.72,3.628c-.172.43-.335.861-.337.865l-.14.367,4.917-.011.263.687Z"
          transform="translate(-6.484 -210.783)"
          fill="#9ca7b6"
        />
      </g>
    </svg>
  );
};

export default QualityIcon;

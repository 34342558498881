import React from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Paper, Skeleton } from "@mui/material";
import ScrollableWidgetItem from "./scrollable-card/ScrollableWidgetItem";
import translate from "../../../i18n/translate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "25%",

    [theme.breakpoints.down('lg')]: {
      width: "100%",
      height: "100%",
      margin: "20px 0 30px 0",
    },
    [theme.breakpoints.down('md')]: {
      margin: "20px 0 50px 0",
    },
  },
  list: {
    overflow: "auto",
    height: 235,
  },
  boxStyle: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  title: {
    fontSize: "1.3em",
    width: "100%",
    fontFamily: "RobotoRegular",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  boxWrapper: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "20px",
  },
  info: {
    color: "#8993A0",
  },
}));

const QualityDownWidget = ({
  title,
  kegData,
  warmBeerData,
  maintenances,
  scroll,
  type,
  info,
  isLoaded,
  isWarmBeer,
}) => {
  const classes = useStyles();

  const skeleton = (
    <React.Fragment>
      <Skeleton
        style={{ margin: "5px 0" }}
        variant="rectangular"
        animation="wave"
        width="100%"
        height={35}
      />
      <Skeleton
        style={{ margin: "5px 0" }}
        variant="rectangular"
        animation="wave"
        width="100%"
        height={35}
      />
      <Skeleton
        style={{ margin: "5px 0" }}
        variant="rectangular"
        animation="wave"
        width="100%"
        height={35}
      />
      <Skeleton
        style={{ margin: "5px 0" }}
        variant="rectangular"
        animation="wave"
        width="100%"
        height={35}
      />
      <Skeleton
        style={{ margin: "5px 0" }}
        variant="rectangular"
        animation="wave"
        width="100%"
        height={35}
      />
      <Skeleton
        style={{ margin: "5px 0" }}
        variant="rectangular"
        animation="wave"
        width="100%"
        height={35}
      />
    </React.Fragment>
  );

  const data =
    type === "maintenance"
      ? maintenances
      : type === "warmestBeer"
      ? warmBeerData
      : kegData;
      
  data.sort(function (a, b) {
    var c = new Date(a.maintenanceDate);
    var d = new Date(b.maintenanceDate);
    return c - d;
  });
  return (
    <Paper className={classes.root} elevation={5}>
      <div className={classes.boxWrapper}>
        <div className={classes.boxStyle}>
          <p className={classes.title}>{title}</p>
        </div>
        <div>
          <p className={classes.info}> {info ? translate(info) : ""}</p>
        </div>
      </div>
      <Divider />
      <div className={scroll ? classes.list : classes.noscroll}>
        {isLoaded
          ? data.map((data, i) => (
            <div key={Math.random() + data}>
            <ScrollableWidgetItem
              data={data}
              type={type}
              title={title}
              isWarmBeer={isWarmBeer}
            />

            {data.length === i + 1 ? null : <Divider />}
          </div>
            ))
          : skeleton}
      </div>
    </Paper>
  );
};

export default QualityDownWidget;

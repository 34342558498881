import React from "react";
import { makeStyles } from "@mui/styles";
import Quality from "../../components/quality/QualityChart";
import BestWorstSection from "../../components/efficiency/best-worst/BestWorstSection";
import QualityDownWidgets from "../../components/quality/quality-scrollable-widgets/QualityDownWidgets";
import SalesProvider from "../../lib/context/sales/SalesProvider";
import BrandsProvider from "../../lib/context/brands/BrandsProvider";
import CustomersProvider from "../../lib/context/customers/CustomersProvider";
import MaintenancesProvider from "../../lib/context/maintenances/MaintenancesProvider";
import TapsProvider from "../../lib/context/taps/TapsProvider";

const useStyles = makeStyles((theme) => ({
  alignTop: {
    display: "flex",
    marginTop: ".6%",
    [theme.breakpoints.down('lg')]: {
      display: "block",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      minWidth: "600px",
    },
  },
  alignDown: {
    display: "flex",
    width: "100%",
    marginTop: "1%",
    [theme.breakpoints.down('lg')]: {
      display: "block",
    },
  },
  leftChildLeft: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
      marginRight: "0 !important",
    },
  },
  leftChildRight: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
      marginLeft: "0 !important",
    },
  },
  leftDown: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
      display: "block !important",
    },
  },
}));

const QualityPage = ({dateFilter,setDateFilter}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.alignTop}>
        <SalesProvider>
          <BrandsProvider>
            <TapsProvider>
              <Quality dateFilter={dateFilter} setDateFilter={setDateFilter}/>
            </TapsProvider>
          </BrandsProvider>
        </SalesProvider>
        <CustomersProvider>
          <BestWorstSection isHome={false} dateFilter={dateFilter} />
        </CustomersProvider>
      </div>
      <div className={classes.alignDown}>
        <div
          className={classes.leftDown}
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <MaintenancesProvider>
            <TapsProvider>
              <QualityDownWidgets dateFilter={dateFilter}/>
            </TapsProvider>
          </MaintenancesProvider>
        </div>
      </div>
    </div>
  );
};

export default QualityPage;

import React from "react";

const SegmentIcon = () => {
  const [color, setColor] = React.useState("#9ca7b6");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.862"
      height="13.555"
      viewBox="0 0 13.862 13.555"
      onClick={() => setColor(color === "#9ca7b6" ? "#C43042" : "#9ca7b6")}
    >
      <path
        id="Path_314"
        data-name="Path 314"
        d="M-9.293-9.066h-4.259a.248.248,0,0,0-.248.248v3.7a.249.249,0,0,1-.076.179L-20.9,1.851l.023.023h-1.781a.248.248,0,0,0-.248.248V4.241a.248.248,0,0,0,.248.248h2.118a.248.248,0,0,0,.248-.248V2.48l.012.013,6.967-6.734a.247.247,0,0,1,.173-.07h3.847a.248.248,0,0,0,.248-.248V-8.818A.248.248,0,0,0-9.293-9.066ZM-21.136,3.894h-.928a.248.248,0,0,1-.248-.248V2.718a.248.248,0,0,1,.248-.248h.928a.248.248,0,0,1,.248.248v.928A.248.248,0,0,1-21.136,3.894Zm10.95-9.1H-12.66a.248.248,0,0,1-.248-.248V-7.925a.248.248,0,0,1,.248-.248h2.474a.248.248,0,0,1,.248.248v2.473A.248.248,0,0,1-10.186-5.2Z"
        transform="translate(22.907 9.066)"
        fill={color}
      />
    </svg>
  );
};

export default SegmentIcon;

import axios from "axios";

export const Exports = async (token, dateFilter, mainUrl, type, order, MAX_SHOPS) => {
  let QUERY_DATES;
  let exportName;
  const endAt = new Date();
  endAt.setDate(endAt.getDate() - 1);
  const startAt = new Date();
  const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
  const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
  startAt.setDate(startAt.getDate() - queryDateDiff);
  const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);
  if (type === ExportTypes.QualityWidgetSalesData) {
    QUERY_DATES = `/ExportQuality?WithSales=true&StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}`;
    exportName = "Quality-Trend";
  } else if (type === ExportTypes.WidgetSalesData) {
    QUERY_DATES = `/Export?WithSales=true&StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}`;
    exportName = "Total-Liters";
  } else if (type === ExportTypes.WorstQualityLocationsData) {
    QUERY_DATES = `/Export?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&Limit=${MAX_SHOPS}&Sort=quality&Order=${order}`;
    exportName = "Worst-Locations";
  } else if (type === ExportTypes.QualityLocationsData) {
    QUERY_DATES = `/Export?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&Limit=${MAX_SHOPS}&Sort=quality&Order=${order}`;
    exportName = "Best-Locations";
  } else if (type === ExportTypes.WorstEfficiencysData) {
    QUERY_DATES = `/Export?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&Limit=${MAX_SHOPS}&Order=${order}`;
    exportName = "Worst-Efficiency";
  } else if (type === ExportTypes.EfficiencysData) {
    QUERY_DATES = `/Export?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&Limit=${MAX_SHOPS}&Order=${order}`;
    exportName = "Best-Efficiency";
  } else if (type === ExportTypes.LastAddedLocationsData) {
    QUERY_DATES = `?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&Limit=${MAX_SHOPS}&Sort=efficiency&Order=${order}`;
    exportName = "Last-Added-Locations";
  } else if (type === ExportTypes.Locations) {
    QUERY_DATES = `/Export?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&Limit=-1`;
    exportName = "Locations";
  } else if (type === ExportTypes.WidgetInstallationsData) {
    QUERY_DATES = `/Export?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}`;
    exportName = "Locations-SmartTaps";
  } else if (type === ExportTypes.SkippedMaintenanceData) {
    QUERY_DATES = `/Export?Limit=-1&Sort=skipped&Order=asc`;
    exportName = "Skipped-Maintenances";
  } else if (type === ExportTypes.SmartTapsData) {
    QUERY_DATES = `/Export?Limit=-1&Sort=temperature&Order=desc&StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}`;
    exportName = "Taps";
  } else if (type === ExportTypes.WorstKegData) {
    QUERY_DATES = `/Export?Limit=-1&Sort=keglife&Order=desc&StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&KegLifeLimit=3`;
    exportName = "Worst-Keg-Freshnesses";
  } else if (type === ExportTypes.WarmestBeerData) {
    QUERY_DATES = `/Export?Limit=-1&Sort=temperature&Order=desc&StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}`;
    exportName = "Warmest-Beer-Sevices";
  } else if (type === ExportTypes.BrandsData) {
    QUERY_DATES = `/Export?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}&WithSales=true`;
    exportName = "Brands";
  }
  const response = await axios.get(mainUrl + QUERY_DATES, {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Disposition': "attachment; filename=Perfect-Beer-Export.xlsx",
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'arraybuffer',
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', "Pubinno-" + exportName + '-CSV.xlsx');
  document.body.appendChild(link);
  link.click();
};
export const convertDate = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  const convertedData = yyyy + "-" + mm + "-" + dd;

  return convertedData;
};
export const ExportTypes = {
  QualityWidgetSalesData: 1,
  WidgetSalesData: 2,
  WorstQualityLocationsData: 3,
  QualityLocationsData: 4,
  WorstEfficiencysData: 5,
  EfficiencysData: 6,
  LastAddedLocationsData: 7,
  Locations: 8,
  WidgetInstallationsData: 9,
  SkippedMaintenanceData: 10,
  SmartTapsData: 11,
  WorstKegData: 12,
  WarmestBeerData: 13,
  BrandsData: 14,
}
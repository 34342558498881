import React from "react";
import { makeStyles } from "@mui/styles";
import translate from "../../../i18n/translate";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    maxWidth: 245,
    marginTop: 25,
  },

  dark: {
    height: "13px",
    width: "13px",
    backgroundColor: "#043042",
  },
  light: {
    height: "13px",
    width: "13px",
    backgroundColor: "#E3E7EE",
  },
  align: {
    display: "flex",
    alignItems: "center",
  },
  textAlign: {
    marginLeft: "10px",
    fontFamily: "RobotoRegular",
    fontSize: "1em",
    fontWeight: "bold",
  },
}));

const ColorInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.align}>
        <div className={classes.dark} />
        <p className={classes.textAlign}>{translate("locations")}</p>
      </div>
      <div className={classes.align} style={{ marginLeft: 20 }}>
        <div className={classes.light} />
        <p className={classes.textAlign}>{translate("taps")}</p>
      </div>
    </div>
  );
};

export default ColorInfo;

/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from "../locales";

export default {
  [LOCALES.TURKISH]: {
    search: "Ara",
    export: "Aktar",
    // header
    sendFeedback: "Geri Bildirim Gönder",
    send: "Gönder",
    feedback: "Geri Bildirim",
    feedbackMessage:
      "Tüm geri bildirimleriniz yazılım geliştirme ekiplerimizle paylaşılıyor ve ürünlerimizi iyileştirmek için bize yardımcı oluyor. Eğer bir bug bildirmek istiyorsanız support@pubinno.com adresine e-posta atabilirsiniz. Bu sayede destek mühendislerimiz sizinle daha hızlı iletişime geçeceklerdir.",
    settings: "Ayarlar",
    logout: "Çıkış",
    signout: "Çıkış",
    emptyNotification: "Size bir bildirim gönderdiğimizde burada görünecek.",
    // subheader
    subHeaderWelcome: "Pubinno Insights'a Hoşgeldiniz",
    allSelected: "Tümü Seçili",
    // menu
    home: "Ana Sayfa",
    brands: "Markalar",
    quality: "Kalite",
    salesInsight: "Sales Insights",
    premium: "Premium",
    // submenu
    salesDashboard: "Satış Analizleri",
    kegOptimization: "Fıçı Optimizasyonu",
    segmentReports: "Segment Raporları",
    efficiencyTrend: "Verimlilik Trendi",
    monthlyPredictions: "Aylık Tahminler",
    // total
    totalChartTitle: "Toplam Litre",
    totalChartSubTitle7:
      "Son 7 gün içinde tüm Pubinno Akıllı Musluklarından servis edilen toplam litreleri görebilirsiniz.",
    totalChartSubTitle30:
      "Son 30 gün içinde tüm Pubinno Akıllı Musluklarından servis edilen toplam litreleri görebilirsiniz.",
    totalChartSubTitleCustomDate:
      " tarihleri arasındaki tüm Pubinno Akıllı Musluklarından servis edilen toplam litreleri görebilirsiniz.",
    totalSales: "Toplam Satış",
    efficiency: "Fıçı Verimliliği",
    qualityScore: "Kalite Skoru",
    // efficiency
    bestEfficiency: "En yüksek verimlilikler",
    worstEfficiency: "En düşük verimlilikler",
    // last added locations
    lastAddedLocations: "Son Eklenen Noktalar",
    // locations
    locationsChartTitle: "Konumlar ve Akıllı Musluklar",
    locationsChartSubTitle7:
      "Son 7 gün içindeki toplam aktif Akıllı Muslukların ve konumların sayılarını görebilirsiniz.",
    locationsChartSubTitle30:
      "Son 30 gün içindeki toplam aktif Akıllı Muslukların ve konumların sayılarını görebilirsiniz.",
    locationChartSubTitleCustomDate:
      " tarihleri arasındaki toplam aktif Akıllı Muslukların ve konumların sayılarını görebilirsiniz.",
    newLocations: "Yeni Noktalar",
    newTaps: "Yeni Musluklar",
    locationsInfoText: "Toplam Pubinno konumları ve Akıllı Musluk sayıları",
    locations: "Noktalar",
    taps: "Musluklar",
    totalSalesScore: "Toplam Satış Skoru",
    // brands table
    brand: "Marka", // Sistemde bu farklı görünüyor, markalar yazıyor büyük harfle (necdet) - canlıdaki doğru
    bestSellingLocation: "En Çok Satış Yapan Nokta", // Bunlar sistemde büyük harfli ama burda küçük, anlamadım (necdet) - canlıdaki doğru
    leastSellingLocation: "En Az Satış Yapan Nokta", // Bunlar sistemde büyük harfli ama burda küçük, anlamadım (necdet) - canlıdaki doğru
    totalLiter: "Toplam Litre", // Bunlar sistemde büyük harfli ama burda küçük, anlamadım (necdet) - canlıdaki doğru
    // locations table
    locationName: "Nokta",
    city: "Şehir",
    creationDate: "Kurulum Tarihi",
    efficiencyScore: "Fıçı Verimliliği",
    smartTaps: "Akıllı Musluklar",
    region: "Bölge",
    subRegion: "Alt Bölge",
    // quality
    qualityTrend: "Kalite Trendi",
    qualityTrendSubTitle7:
      "Son 7 gün içindeki servis kalitesindeki değişimleri görüntüleyebilirsiniz.",
    qualityTrendSubTitle30:
      "Son 30 gün içindeki servis kalitesindeki değişimleri görüntüleyebilirsiniz.",
    qualityTrendSubTitleCustomDate:
      " tarihleri arasındaki servis kalitesindeki değişimleri görüntüleyebilirsiniz.",
    bestLocations: "En İyi Noktalar",
    worstLocations: "En Kötü Noktalar",
    maintenanceFrequency: "Bakım Sıklığı", // Bu kullanılmıyor sanırım, sonra ekleyecektik diye hatırlıyorum ortalama bakım sıklığı olarak
    averageBeerTemperature: "Ort. Servis Sıcaklığı",
    days: "Gün",
    skippedMaintenance: "Yapılmamış Bakımlar",
    warmestBeer: "En Sıcak Servisler",
    worstKeg: "En Kötü Fıçı Ömürleri",
    last7days: "Son 7 Gün",
    last30days: "Son 30 Gün",
    customDate: "Tarih Aralığı",
    // smart taps
    kegFresh : "Fıçı Tazeliği",
    // send feedback responses
    feedbackSubTitle: "Geri Bildirimlerimiz Bizim İçin Önemli.", // Bu kullanılmıyor sanırım. (necdet)
    feedbackSuccess: "Geri Bildiriminiz İçin teşekkürler!",
    // Login page texts
    loginTitle: "Merhaba",
    loginSubTitle: "Tekrar görüştüğümüze çok sevindik.",
    loginSlider1:
      "İşinizi kuvvetlendirecek tüm gerçek zamanlı fıçı bira verilerini takip edebilirsiniz.",
    loginSlider2:
      "Fıçı bira servis kalitenizi takip edebilir, arttırabilir ve standardize edebilirsiniz",
    loginSlider3:
      "Gerçek zamanlı fıçı bira verilerini kullanan Machine Learning teknolojimizin sunduğu satış analizleri ile iş performansınızı arttırabilirsiniz.",
    loginUser: "Kullanıcı Adı",
    loginPassword: "Şifre",
    loginForgot: "Şifremi Unuttum",
    enterButton: "Giriş",
    loginError: "Hata!",
    errorMessage: "Kullanıcı adı veya parola geçerli değil",
    tryAgain: "Lütfen tekrar deneyin",
    mailAsk: "Emailinizi girin",
    sendButton: "Email Yolla",
    checkMail: "Şifrenizi yenilemek için mail kutunuzu kontrol edin",
    loginBack: "Giriş Sayfasına Geri Dön",
    renewedPassword: "Şifreniz yenilendi",
    askNewPassword: "Lütfen Yeni Şifrenizi Girin",
    newPassword: "Yeni Şifre",
    confirmPassword: "Yeni Şifre Tekrar",
    renewButton: "Şifrenizi Yenileyin",
    // settings
    timeZone: "Saat",
    language: "Dil",
    measure: "Ölçü Birimi",
    userName: "Kullanıcı Adı",
    email: "E-mail",
    password: "Şifre",
    submit: "Gönder",
    passwordChangeSuccess: "Şifreniz değiştirildi!",
    passwordOldError: "Eski ve yeni şifreniz aynı olamaz!",
    passwordConfirmError: "Yeni şifreleriniz birbiriyle aynı değil.",
    settingError:
      "Üzgünüz ama şu anda isteiğinizi gerçekleştiremiyoruz, lütfen daha sonra tekrar deneyiniz.",
    languageOrTimeUpdate: "Ayarlarınız güncellendi!",
    passwordCharError: "At least 6 characters are required.",
    // not-found
    wentWrong: "Something Went Wrong",
    sorry: " Sorry.. we can't find the page you are looking for :(",
    perhaps: "Perhaps it's because:",
    moved: "- the page has moved",
    noexist: "- the page no longer exist",
    goBack: "Go Back Home",
    passwordSecureError: "Şifreniz en az bir büyük, bir küçük, bir özel karakter, bir sayı içermelidir ve en az 8 karakterden oluşmalıdur.",
  },
};

import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocationChartComponent from "../chart";
import OutletsInfoCard from "./OutletsInfoCard";
import { eventLogView } from "../../../lib/google-analytics/GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 20px",
    width: "100%",
    height: "292px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%",
      marginLeft: 0,
      marginTop: 20,
    },
  },
  alignComponent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));

const OutletsTaps = ({ dateFilter }) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      eventLogView(
        "Outlet and Taps",
        dateFilter.typ === 1
          ? "7 Days Data Called"
          : dateFilter.typ === 2
          ? "30 Days Data Called"
          : "Custom Date Data Called"
      );
      setIsLoaded(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);
  return (
    <Paper elevation={5} className={classes.root}>
      <div className={classes.alignComponent}>
        <LocationChartComponent isLoaded={isLoaded} dateFilter={dateFilter} />
        <OutletsInfoCard isLoaded={isLoaded} dateFilter={dateFilter} />
      </div>
    </Paper>
  );
};

export default OutletsTaps;

import React, { useContext } from "react";
import RenewPassword from "./RenewPassword";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import translate from "../../i18n/translate";

const Settings = () => {
  const { updatePassword } = useContext(
    UserAuthContext
  );
  const [updatedMessage, setUpdatedMessage] = React.useState({
    message: null,
    show: false,
    success: false,
  });
  const [isPressed, setIsPressed] = React.useState(false);
  const [passwordData, setPasswordData] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdatedMessage({
      show: false,
    });
    eventLogView("Settings Page", "Renew Password Clicked!");
    setIsPressed(true);
    let response = await updatePassword(
      passwordData.oldPassword,
      passwordData.newPassword,
      passwordData.confirmPassword
    );
    setUpdatedMessage({
      message:
        response === 200
          ? translate("passwordChangeSuccess")
          : response === 750
          ? translate("passwordSecureError")
          : response === 800
          ? translate("passwordOldError")
          : response === 900
          ? translate("passwordConfirmError")
          : translate("settingError"),
      success: response === 200 && true,
      show: true,
    });
    setIsPressed(false);
    setPasswordData({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <>
      <RenewPassword
        handleSubmit={handleSubmit}
        isPressed={isPressed}
        setIsPressed={setIsPressed}
        passwordData={passwordData}
        setPasswordData={setPasswordData}
        updatedMessage={updatedMessage}
      />
    </>
  );
};
export default Settings;

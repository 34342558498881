import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Skeleton } from "@mui/material";

const CustomLocationChartComponent = ({ datas, isLoaded, dateFilter }) => {
  const dailySumOptions = {
    day: "numeric",
    weekday: "short",
  };
  const monthlyDateOptions = {
    day: "numeric",
    month: "long",
  };
  const dateRange =
    dateFilter.endDate !== null
      ? Math.abs(dateFilter.endDate - dateFilter.startDate) /
        (1000 * 60 * 60 * 24)
      : 7;
  let tickRotation = 60;
  let dataList = [];
  if (datas.days != null && datas.days.length > 0) {
    dataList = datas.days.map(({ day, totalLocation, totalTaps }) => {
      const convertDate = new Date(day);
      const payload = {
        locations: totalLocation,
        taps: totalTaps,
        days: convertDate.toLocaleDateString(
          "en-Ca",
          dateRange < 25 ? dailySumOptions : monthlyDateOptions
        ),
      };
      return payload;
    });
  } else if (datas.week != null && datas.week.length > 0) {
    dataList = datas.week.map(({ week, totalLocation, totalTaps }) => {
      const payload = {
        locations: totalLocation,
        taps: totalTaps,
        days: week,
      };
      return payload;
    });
  } else if (datas.month != null && datas.month.length > 0) {
    dataList = datas.month.map(({ month, year, totalLocation, totalTaps }) => {
      const payload = {
        locations: totalLocation,
        taps: totalTaps,
        days: month + "-" + year,
      };
      return payload;
    });
  }
  const locationValue = dataList.map((item) => item.locations);
  const tabsValue = dataList.map((item) => item.taps);
  const values = locationValue.concat(tabsValue);
  const maxValue = Math.max(...values);
  if (isLoaded) {
    return (
      <ResponsiveBar
        maxValue={maxValue + 50}
        data={dataList}
        axisBottom={{
          tickRotation: tickRotation,
          tickSize: 0.1,
          tickPadding: 15,
        }}
        axisLeft={{
          format: (n) => Intl.NumberFormat().format(n),
        }}
        keys={["locations", "taps"]}
        indexBy="days"
        margin={{ top: 25, right: 20, bottom: 40, left: 60 }}
        enableLabel={false}
        tooltip={(rawData) =>
          rawData.id + ": " + Intl.NumberFormat().format(rawData.value)
        }
        groupMode="grouped"
        colors={["#262626", "#E3E7EE"]}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#eed312",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#38bcb2",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        minValue="auto"
        padding={0.5}
        innerPadding={1}
      />
    );
  }
  return <Skeleton animation="wave" variant="rectangular" height="100%" />;
};

export default CustomLocationChartComponent;

import React from "react";

const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.223"
      height="15.333"
      viewBox="0 0 10.223 15.333"
    >
      <g id="Group_166" data-name="Group 166" transform="translate(-12 -6)">
        <g id="Group_165" data-name="Group 165" transform="translate(12 6)">
          <path
            id="Path_291"
            data-name="Path 291"
            d="M17.111,6A5.112,5.112,0,0,0,12,11.111c0,2.684,4.562,9.729,4.754,10.027a.426.426,0,0,0,.716,0c.192-.3,4.754-7.343,4.754-10.027A5.112,5.112,0,0,0,17.111,6Zm0,14.116c-1.457-2.313-4.26-7.152-4.26-9a4.26,4.26,0,0,1,8.519,0C21.371,12.964,18.568,17.8,17.111,20.116Z"
            transform="translate(-12 -6)"
            fill="#9ca7b6"
          />
        </g>
        <g
          id="Ellipse_73"
          data-name="Ellipse 73"
          transform="translate(15 8.336)"
          fill="#fff"
          stroke="#9ca7b6"
          strokeWidth="0.75"
        >
          <circle cx="2" cy="2" r="2" stroke="none" />
          <circle cx="2" cy="2" r="1.625" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;

import React from "react";
import { makeStyles } from "@mui/styles";
import translate from "../../../i18n/translate";
import DatePicker from "./DatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    height: 35,
    display: "flex",
    width: "120px",
    justifyContent: 'center',
    alignItems: 'center',
    border: "1px solid #E3E7EE !important",
    textAlign: "center",
    padding: "0 auto",
    fontFamily: "RobotoRegular",
    marginRight: '3%',
    "& p": {
      margin: "0",
      fontSize: "1em",
      fontFamily: "RobotoRegular",
    },
    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
      fontFamily: "RobotoRegular",
    },
  },
  vertical: {
    width: "1px",
    height: "23px",
    margin: '0px 10px 0px 10px',
    backgroundColor: "#707070",
  },
}));
const DayOptions = ({ dateFilter, setDateFilter }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <p
          onClick={() => {
            setDateFilter({ typ: 1 ,startDate:null ,endDate:null})
          }}
          style={{
            color: `${dateFilter.typ === 1 ? "#C43042" : " #424242"}`,
            fontWeight: `${dateFilter.typ === 1 ? "bold" : ""}`,
          }}>
          {translate("last7days")}
        </p>
      </div>
      <div className={classes.text}>
        <p
          onClick={() => {
            setDateFilter({ typ: 2 ,startDate:null ,endDate:null});
          }}
          style={{
            color: `${dateFilter.typ === 2 ? "#C43042" : "#424242"}`,
            fontWeight: `${dateFilter.typ === 2 ? "bold" : ""}`,
          }}>
          {translate("last30days")}
        </p>
      </div>
      <div>
        <div style={{
            border: `${dateFilter.typ === 3 ? "1px solid #C43042" : "none"}`,
            color: `${dateFilter.typ === 3 ? "#C43042" : ""}`,
            fontWeight: `${dateFilter.typ === 3 ? "bold" : ""}`,
            marginRight:20
          }}>
          <DatePicker setDateFilter={setDateFilter} dateFilter={dateFilter}/>
        </div>
      </div>
    </div>
  );
};

export default DayOptions;

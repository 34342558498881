import React from "react";

export const upArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.467"
    height="9.75"
    viewBox="0 0 8.467 9.75"
  >
    <g id="up" transform="translate(0.384 0.781)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M370.7,493.619,366.849,489,363,493.619"
        transform="translate(-363 -489)"
        fill="none"
        stroke="#46b85b"
        strokeWidth="1"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        y2="7.699"
        transform="translate(3.849 0.77)"
        fill="none"
        stroke="#46b85b"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const downArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.467"
    height="9.75"
    viewBox="0 0 8.467 9.75"
  >
    <g id="up" transform="translate(8.083 8.969) rotate(180)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M370.7,493.619,366.849,489,363,493.619"
        transform="translate(-363 -489)"
        fill="none"
        stroke="#c43042"
        strokeWidth="1"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        y2="7.699"
        transform="translate(3.849 0.77)"
        fill="none"
        stroke="#c43042"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </g>
  </svg>
);

import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { Divider, Paper, Skeleton } from "@mui/material";
import BestWorstListCard from "../card/BestWorstListCard";
import { CustomersContext } from "../../../lib/context/customers/CustomersContext";
import translate from "../../../i18n/translate";
import { eventLogView } from "../../../lib/google-analytics/GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
  head: {
    textAlign: "center",
  },
  text: {
    cursor: "pointer",
    fontSize: "1.3em",
    fontFamily: "RobotoRegular",
    width: "100%",
    textAlign: "center",
    padding: "0 auto",
    // color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      color: "#656565 !important",
      fontSize: "1.4em",
    },
    webkitTransition: "color .5s, font-size .5s",
    mozTransition: "color .5s, font-size .5s",
    oTransition: "color .5s, font-size .5s",
    transition: "color .5s, font-size .5s",
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.2em",
    },
  },
  textSpan: {
    fontSize: "11px",
  },
  boxStyle: {
    height: "13%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down('lg')]: {
      margin: "0 5px",
    },
  },
  paper: {
    width: "33%",
    margin: "0 0.5%",
    height: "500px",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      height: "500",
      marginLeft: 0,
      marginTop: 20,
    },
  },
  listItem: {
    height: "2.385rem",
  },
  vertical: {
    width: "1px",
    height: "60%",
    backgroundColor: "#707070",
    [theme.breakpoints.down('lg')]: {
      margin: "0",
    },
  },
}));

const BestWorstSection = ({ isHome, dateFilter }) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [bestWorst, setBestWorst] = useState(true);
  const {
    getEfficiencyData,
    bestWorstData,
    getQualityLocationsData,
    qualityLocations,
  } = useContext(CustomersContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      isHome
        ? await getEfficiencyData(bestWorst, dateFilter)
        : await getQualityLocationsData(bestWorst, dateFilter);
      setIsLoaded(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [bestWorst, dateFilter]);
  const handleBestWorstClick = (bool) => {
    let categoryName = isHome
      ? "Home Page / Efficiency Cards"
      : "Quality Page / Locations Cards";
    let actionName = isHome
      ? bool
        ? "Best Efficiency Cards Listed"
        : "Worst Efficiency Cards Listed"
      : bool
      ? "Best Locations Card Listed"
      : "Worst Locations Cards Listed";
    eventLogView(categoryName, actionName);
    setBestWorst(bool);
  };
  return (
    <Paper className={classes.paper} elevation={5}>
      <div className={classes.boxStyle}>
        <p
          className={classes.text}
          style={{
            color: `${!bestWorst ? "#BFBFBF" : " #C43042"}`,
            "&:hover": `${!bestWorst ? "#BFBFBF" : " #C43042"}`,
          }}
          onClick={() => handleBestWorstClick(true)}
        >
          {isHome ? translate("bestEfficiency") : translate("bestLocations")}
          <br />
        </p>
        <div className={classes.vertical} />
        <p
          className={classes.text}
          style={{ color: `${!bestWorst ? "#C43042 " : " #BFBFBF"}` }}
          onClick={() => handleBestWorstClick(false)}
        >
          {isHome ? translate("worstEfficiency") : translate("worstLocations")}
          <br />
        </p>
      </div>
      <Divider />
      {isLoaded ? (
        <>
          {(isHome ? bestWorstData : qualityLocations).map(
            (
              {
                name,
                city,
                county,
                efficiency,
                totalLiters,
                qualityScore,
                id,
                externalCode,
              },
              i
            ) => (
              <div key={Math.random() + IDBCursorWithValue}>
                <BestWorstListCard
                  id={id}
                  shop={name.toLowerCase()}
                  city={city}
                  county={county}
                  percentage={efficiency}
                  totalLiters={totalLiters.toFixed(1)}
                  qualityScore={qualityScore}
                  isHome={isHome}
                  externalCode={externalCode}
                />
                {bestWorstData.length !== i + 1 ? <Divider /> : null}
              </div>
            )
          )}
        </>
      ) : (
        <React.Fragment>
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={37}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={42}
          />
        </React.Fragment>
      )}
    </Paper>
  );
};
export default BestWorstSection;

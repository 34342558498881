import React from "react";

const EmptyNotification = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="38"
      viewBox="0 0 45 38"
    >
      <g id="Group_173" data-name="Group 173" transform="translate(-3425 633)">
        <g id="Group_172" data-name="Group 172">
          <g id="Group_171" data-name="Group 171">
            <rect
              id="Rectangle_1529"
              data-name="Rectangle 1529"
              width="45"
              height="31"
              rx="4"
              transform="translate(3425 -633)"
              fill="#c43042"
            />
            <path
              id="Polygon_4"
              data-name="Polygon 4"
              d="M10.809.661a1,1,0,0,1,1.382,0L21.2,9.277A1,1,0,0,1,20.508,11H2.492A1,1,0,0,1,1.8,9.277Z"
              transform="translate(3460 -595) rotate(180)"
              fill="#c43042"
            />
          </g>
          <text
            id="_0"
            data-name="0"
            transform="translate(3457 -613)"
            fill="#fff"
            fontSize="13"
            fontFamily="Roboto-Medium, Roboto"
            fontWeight="500"
          >
            <tspan x="0" y="0">
              0
            </tspan>
          </text>
        </g>
        <g
          id="Component_58_10"
          data-name="Component 58 – 10"
          transform="translate(3431.133 -627.432)"
        >
          <g id="Notifications" transform="translate(-176.528 -167.467)">
            <g id="alarm-bell" transform="translate(234.528 19.467)">
              <path
                id="Path_39"
                data-name="Path 39"
                d="M10,21.75a1.859,1.859,0,0,0,3.574,0"
                transform="translate(-61.754 145.115)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M12,2.771V.75"
                transform="translate(-61.969 147.25)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                id="Path_41"
                data-name="Path 41"
                d="M11.031,3a6.715,6.715,0,0,1,6.692,6.738c0,6.33,1.338,7.411,1.338,7.411H3s1.338-1.721,1.338-7.411A6.715,6.715,0,0,1,11.031,3Z"
                transform="translate(-61 147.021)"
                fill="rgba(255,255,255,0)"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmptyNotification;

import { TapsContext } from "./TapsContext";
import React, { useState } from "react";
import axios from "axios";
import { TAPS_URL, GetBeerTemperaturesByDistributor, GetKegFreshnessesByDistributor, GET_LOCATIONS_BY_CUSTOMER_ID_URL } from "../../urls/urls";
import { Exports, convertDate, ExportTypes } from "../../../components/export/exports";

const TapsProvider = ({ children }) => {
  const [tapsData, setTapsData] = useState([]);
  const [kegData, setKegData] = useState([]);
  const [warmBeerData, setWarmBeerData] = useState([]);

  const getTapsData = async (dateFilter, locationId) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const QUERY_DATES = `/${locationId}?startDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}&PageSize=1111&PageNumber=1`;

    const response = await axios.get(GET_LOCATIONS_BY_CUSTOMER_ID_URL + QUERY_DATES, {
      headers: { authorization: `Bearer ${localToken}` },
    });

    const { data } = response;
    setTapsData(data.data);

  };

  const getKegData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);

    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);
    const email = localStorage.getItem("email");
    const storageKey = "getKegData-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setKegData(data);
    } else {
      const QUERY_DATES = `?Order=desc&endDate=${convertedEndAt+ "T23:59:59"}&KegLifeLimit=3&PageSize=11111&PageNumber=1`;

      const response = await axios.get(GetKegFreshnessesByDistributor + QUERY_DATES, {
        headers: { authorization: `Bearer ${localToken}` },
      });

      const { data } = response;

      setKegData(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getKegData-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };

  const getWarmBeerData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);
    const email = localStorage.getItem("email");
    const storageKey = "getWarmBeerData-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setWarmBeerData(data);
    } else {
      const QUERY_DATES = `?order=desc&startDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}`;

      const response = await axios.get(GetBeerTemperaturesByDistributor + QUERY_DATES, {
        headers: { authorization: `Bearer ${localToken}` },
      });

      const { data } = response;
      setWarmBeerData(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getWarmBeerData-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };

  const exportWidgetWarmestBeerData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, TAPS_URL, ExportTypes.WarmestBeerData)
  };
  const exportWidgetWorstKegData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, TAPS_URL, ExportTypes.WorstKegData)
  };


  return (
    <TapsContext.Provider 
      value={{
        tapsData: tapsData,
        getTapsData: getTapsData,
        kegData: kegData,
        getKegData: getKegData,
        warmBeerData: warmBeerData,
        getWarmBeerData: getWarmBeerData,
        exportWidgetWarmestBeerData: exportWidgetWarmestBeerData,
        exportWidgetWorstKegData: exportWidgetWorstKegData
      }}
    >
      {children}
    </TapsContext.Provider>
  );
};

export default TapsProvider;

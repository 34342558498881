import React from "react";
import { makeStyles } from "@mui/styles";
import translate from "../../../i18n/translate";
import Flip from "react-reveal/Flip";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 25,
    "& p": {
      margin: "0",
      fontSize: 13,
      fontFamily: "RobotoRegular",
    },
  },
  header: {
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "RobotoRegular",
    color: theme.palette.primary.main,
  },
  subText: {
    color: "#8993A0",
    fontSize: "12px",
    fontFamily: "RobotoRegular",
  },
  bold: {
    color: "#1C273C",
    fontFamily: "RobotoRegular",
    fontSize: "17px",
    fontWeight: "bold",
  },
}));

const OutletsInfoText = ({ locations, tabs }) => {
  const classes = useStyles();

  return (
    <Flip top>
      <div className={classes.root}>
        <p className={classes.header}>{translate("locationsChartTitle")}</p>
        <p className={classes.subText}>{translate("locationsInfoText")}</p>
        <p className={classes.bold}>{`${locations} / ${tabs}`}</p>
      </div>
    </Flip>
  );
};

export default OutletsInfoText;

import { SalesContext } from "./SalesContext";
import React, { useState } from "react";
import axios from "axios";
import { SALES_QUALITY_URL, SALES_URL } from "../../urls/urls";
import { Exports, convertDate, ExportTypes } from "../../../components/export/exports"

const SalesProvider = ({ children }) => {
  const [currentWeekSalesData, setCurrentWeekSalesData] = useState({
    totalLiters: null,
    totalLitersChange: null,
    totalSold: null,
    totalSoldChange: null,
    efficiency: null,
    efficiencyChange: null,
    qualityScore: null,
    qualityScoreChange: null,
    temperatureAvgChange: null,
    temperatureAvg: null,
    dailySums: [],
    weeklySums: [],
    monthlySums: [],
  });

  const [previousWeekSalesData, setPreviousWeekSalesData] = useState({
    totalLitersPrevious: null,
    totalLitersChange: null,
    totalSoldPrevious: null,
    totalSoldChange: null,
    efficiencyPrevious: null,
    efficiencyChange: null,
    temperatureAvgChange: null,
    temperatureAvg: null,
    qualityScorePrevious: null,
    qualityScoreChange: null,
    dailySumsPrevious: [],
    weeklySumsPrevious: [],
    monthlySumsPrevious: [],
  });


  const getSalesData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    endAt.setDate(endAt.getDate());
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getSalesData-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setCurrentWeekSalesData({
        totalLiters: data.totalLiters,
        totalLitersPrevious: data.totalLitersPrevious,
        totalLitersChange: data.totalLitersChange,
        totalSold: data.totalSold,
        totalSoldChange: data.totalSoldChange,
        efficiency: data.efficiency,
        efficiencyChange: data.efficiencyChange,
        qualityScore: data.qualityScore,
        temperatureAvgChange: data.temperatureAvgChange,
        temperatureAvg: data.temperatureAvg,
        qualityScoreChange: data.qualityScoreChange,
        dailySums: data.dailySums,
        weeklySums: data.weeklySums,
        monthlySums: data.monthlySums,
      });

      setPreviousWeekSalesData({
        totalLiters: data.totalLitersPrevious,
        totalLitersChange: data.totalLitersChange,
        totalSold: data.totalSoldPrevious,
        totalSoldChange: data.totalSoldChange,
        efficiency: data.efficiencyPrevious,
        efficiencyChange: data.efficiencyChange,
        qualityScore: data.qualityScorePrevious,
        temperatureAvgChange: data.temperatureAvgChange,
        temperatureAvg: data.temperatureAvg,
        qualityScoreChange: data.qualityScoreChange,
        dailySumsPrevious: data.dailySumsPrevious,
        weeklySumsPrevious: data.weeklySumsPrevious,
        monthlySumsPrevious: data.monthlySumsPrevious,
      });
    } else {
      const QUERY_DATES = `?StartDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}`;

      const response = await axios.get(SALES_URL + QUERY_DATES, {
        headers: { authorization: `Bearer ${localToken}` },
      });

      const { data } = response;


      setCurrentWeekSalesData({
        totalLiters: data.totalLiters,
        totalLitersPrevious: data.totalLitersPrevious,
        totalLitersChange: data.totalLitersChange,
        totalSold: data.totalSold,
        totalSoldChange: data.totalSoldChange,
        efficiency: data.efficiency,
        efficiencyChange: data.efficiencyChange,
        qualityScore: data.qualityScore,
        temperatureAvgChange: data.temperatureAvgChange,
        temperatureAvg: data.temperatureAvg,
        qualityScoreChange: data.qualityScoreChange,
        dailySums: data.dailySums,
        weeklySums: data.weeklySums,
        monthlySums: data.monthlySums,
      });

      setPreviousWeekSalesData({
        totalLiters: data.totalLitersPrevious,
        totalLitersChange: data.totalLitersChange,
        totalSold: data.totalSoldPrevious,
        totalSoldChange: data.totalSoldChange,
        efficiency: data.efficiencyPrevious,
        efficiencyChange: data.efficiencyChange,
        qualityScore: data.qualityScorePrevious,
        temperatureAvgChange: data.temperatureAvgChange,
        temperatureAvg: data.temperatureAvg,
        qualityScoreChange: data.qualityScoreChange,
        dailySumsPrevious: data.dailySumsPrevious,
        weeklySumsPrevious: data.weeklySumsPrevious,
        monthlySumsPrevious: data.monthlySumsPrevious,
      });
      const oldKey = Object.keys(localStorage).find(i => i.includes("getSalesData-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data));
    }
  };

  const getSalesQualityData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    endAt.setDate(endAt.getDate());
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getSalesQualityData-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setCurrentWeekSalesData({
        totalLiters: data.totalLiters,
        totalLitersPrevious: data.totalLitersPrevious,
        totalLitersChange: data.totalLitersChange,
        totalSold: data.totalSold,
        totalSoldChange: data.totalSoldChange,
        efficiency: data.efficiency,
        efficiencyChange: data.efficiencyChange,
        qualityScore: data.qualityScore,
        temperatureAvgChange: data.temperatureAvgChange,
        temperatureAvg: data.temperatureAvg,
        qualityScoreChange: data.qualityScoreChange,
        dailySums: data.dailySums,
        weeklySums: data.weeklySums,
        monthlySums: data.monthlySums,
      });

      setPreviousWeekSalesData({
        totalLiters: data.totalLitersPrevious,
        totalLitersChange: data.totalLitersChange,
        totalSold: data.totalSoldPrevious,
        totalSoldChange: data.totalSoldChange,
        efficiency: data.efficiencyPrevious,
        efficiencyChange: data.efficiencyChange,
        qualityScore: data.qualityScorePrevious,
        temperatureAvgChange: data.temperatureAvgChange,
        temperatureAvg: data.temperatureAvg,
        qualityScoreChange: data.qualityScoreChange,
        dailySumsPrevious: data.dailySumsPrevious,
        weeklySumsPrevious: data.weeklySumsPrevious,
        monthlySumsPrevious: data.monthlySumsPrevious,
      });
    } else {
      const QUERY_DATES = `?StartDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}`;

      const response = await axios.get(SALES_QUALITY_URL + QUERY_DATES, {
        headers: { authorization: `Bearer ${localToken}` },
      });

      const { data } = response;


      setCurrentWeekSalesData({
        totalLiters: data.data.totalLiters,
        totalLitersPrevious: data.data.totalLitersPrevious,
        totalLitersChange: data.data.totalLitersChange,
        totalSold: data.data.totalSold,
        totalSoldChange: data.data.totalSoldChange,
        efficiency: data.data.efficiency,
        efficiencyChange: data.data.efficiencyChange,
        qualityScore: data.data.qualityScore,
        temperatureAvgChange: data.data.temperatureAvgChange,
        temperatureAvg: data.data.temperatureAvg,
        qualityScoreChange: data.data.qualityScoreChange,
        dailySums: data.data.dailySums,
        weeklySums: data.data.weeklySums,
        monthlySums: data.data.monthlySums,
      });

      setPreviousWeekSalesData({
        totalLiters: data.data.totalLitersPrevious,
        totalLitersChange: data.data.totalLitersChange,
        totalSold: data.data.totalSoldPrevious,
        totalSoldChange: data.data.totalSoldChange,
        efficiency: data.data.efficiencyPrevious,
        efficiencyChange: data.data.efficiencyChange,
        qualityScore: data.data.qualityScorePrevious,
        temperatureAvgChange: data.data.temperatureAvgChange,
        temperatureAvg: data.data.temperatureAvg,
        qualityScoreChange: data.data.qualityScoreChange,
        dailySumsPrevious: data.data.dailySumsPrevious,
        weeklySumsPrevious: data.data.weeklySumsPrevious,
        monthlySumsPrevious: data.data.monthlySumsPrevious,
      });
      const oldKey = Object.keys(localStorage).find(i => i.includes("getSalesQualityData-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };

  const exportWidgetSalesData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, SALES_URL, ExportTypes.WidgetSalesData)
  };

  const exportQualityWidgetSalesData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, SALES_QUALITY_URL, ExportTypes.QualityWidgetSalesData)
  };


  return (
    <SalesContext.Provider
      value={{
        currentWeekSalesData: currentWeekSalesData,
        previousWeekSalesData: previousWeekSalesData,
        getSalesData: getSalesData,
        getSalesQualityData: getSalesQualityData,
        exportWidgetSalesData: exportWidgetSalesData,
        exportQualityWidgetSalesData: exportQualityWidgetSalesData,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesProvider;

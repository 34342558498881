import React from "react";
import { makeStyles } from "@mui/styles";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& p": {
      margin: "0",
      fontSize: 13,
      fontFamily: "RobotoRegular",
    },
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
      backgroundColor: "#F8F8F8",
      borderRadius: "5px",
      border: "1px solid #B5B5B5",
      transition: "transform .2s",
    },
  },
  alignItems: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    margin: "3.4px 0",
  },
  bold: {
    fontWeight: "bold",
    color: "black",
    textTransform: "capitalize",
  },
  location: {
    color: "#A4A8B1",
  },
  brand: {
    fontSize: "10px",
    color: "#A4A8B1",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const LastAddedLocationItem = ({ shop, tap, county, date, city, externalCode }) => {

  const classes = useStyles();
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const localeDate = new Date(date);

  const handleChar = (text) => {
    return text;
  };

  return (
    <div className={classes.root}>
      <Fade right>
          <div className={classes.alignItems}>
            <p className={`${classes.bold}`}>{handleChar(shop)}</p>
            <div>
              <p className={`${classes.bold}`}>{tap} Taps</p>
            </div>
          </div>

          <div className={classes.alignItems}>
            <p className={`${classes.location}`}>
              SAP Code: {externalCode ? externalCode : "-"}
            </p>
            <p className={classes.brand}>
              {handleChar(
                localeDate.toLocaleDateString('en-Ca', dateOptions)
              )}
            </p>
          </div>
      </Fade>
    </div>
  );
};

export default LastAddedLocationItem;

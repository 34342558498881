export function dateFormatForSort(value) {
    if (value && value.trim().length > 0) {
        var valueList = value.split(".");
        var newDateStr = valueList[1] + "-" + valueList[0] + "-" + valueList[2];
        var stringData = newDateStr.split('_')[0];
        var newValue = new Date(stringData);
        return newValue;
    } else if (value == null) {
        return "";
    }
    return value;
}
import React from "react";

const LeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.394"
      height="29.696"
      viewBox="0 0 15.394 29.696"
    >
      <path
        id="Path_270"
        data-name="Path 270"
        d="M0,14.333,14.7,0l14.3,14.333"
        transform="translate(14.691 0.349) rotate(90)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
    </svg>
  );
};

export default LeftIcon;

import React from "react";
import { makeStyles } from "@mui/styles";
import Total from "../../components/total/Total";
import BestWorstSection from "../../components/efficiency/best-worst/BestWorstSection";
import LastAddedLocations from "../../components/location-taps/last-added-location/LastAddedLocation";
import OutletsTaps from "../../components/location-taps/outlets-taps/OutletsTaps";
import InstallationsProviders from "../../lib/context/installations/InstallationsProvider";
import SalesProvider from "../../lib/context/sales/SalesProvider";
import BrandsProvider from "../../lib/context/brands/BrandsProvider";
import CustomersProvider from "../../lib/context/customers/CustomersProvider";

const useStyles = makeStyles((theme) => ({
  alignTop: {
    display: "flex",
    marginTop: ".6%",
    [theme.breakpoints.down('lg')]: {
      display: "block",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      minWidth: "600px",
    },
  },
  alignDown: {
    display: "flex",
    width: "100%",
    marginTop: "1%",
    [theme.breakpoints.down('lg')]: {
      display: "block",
    },
  },
  downLeft: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
    },
  },
  downRight: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
      marginLeft: "0 !important",
    },
  },
}));

const HomePage = ({dateFilter}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.alignTop}>
        <SalesProvider>
          <BrandsProvider>
            <Total dateFilter={dateFilter}/>
          </BrandsProvider>
        </SalesProvider>
        <CustomersProvider>
          <BestWorstSection isHome={true} dateFilter={dateFilter}  />
        </CustomersProvider>
      </div>

      <div className={classes.alignDown}>
        <div
          style={{ width: "67%", height: "100%", margin: "0 0.5%" }}
          className={classes.downLeft}
        >
          <SalesProvider>
            <InstallationsProviders>
              <OutletsTaps dateFilter={dateFilter}/>
            </InstallationsProviders>
          </SalesProvider>
        </div>

        <div
          style={{ width: "33%", height: "100%", margin: "0 0.5%" }}
          className={classes.downRight}
        >
          <CustomersProvider>
            <LastAddedLocations
              title="Last Added Locations"
              days={false}
              scroll={false}
            />
          </CustomersProvider>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

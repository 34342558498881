const hasNumber = (value) => {
  return new RegExp(/[0-9]/).test(value);
};

const hasMixed = (value) => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

const hasSpecial = (value) => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
};

export const strengthColor = (count) => {
  if (count < 2) return { color: "#C43042", width: "20%" };

  if (count < 3) return { color: "yellow", width: "40%" };

  if (count < 4) return { color: "orange", width: "60%" };

  if (count < 5) return { color: "lightgreen", width: "80%" };

  if (count < 6) return { color: "green", width: "100%" };
};

export const strengthIndicator = (value) => {
  let strengths = 0;

  if (value.length > 5) strengths++;

  if (value.length > 7) strengths++;

  if (hasNumber(value)) strengths++;

  if (hasSpecial(value)) strengths++;

  if (hasMixed(value)) strengths++;

  return strengths;
};

import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { InstallationsContext } from "../../../lib/context/installations/InstallationsContext";
import translate from "../../../i18n/translate";
import CustomLocationChartComponent from "./CustomLocationChartComponent";
import { format } from "date-fns";
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "65%",
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 10,
      width: "100%",
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
      marginBottom: 20,
    },
    "& p": {
      margin: "0",
      fontFamily: "RobotoRegular",
    },
  },
  header: {
    fontSize: "1.3em",
    fontFamily: "RobotoRegular",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  subText: {
    color: "#8993A0",
    fontFamily: "RobotoRegular",
    fontSize: "1em",
    margin: "5px 0 20px 0",
  },
  chart: {
    height: "180px",
    width: "100%",
    [theme.breakpoints.down('lg')]: {
      width: "95%",
    },
    [theme.breakpoints.down('md')]: {
      height: "200px",
      width: "95%",
    },
  },
}));

const LocationChart = ({ dateFilter }) => {
  const classes = useStyles();
  const { getInstallationsData, installationData } = useContext(
    InstallationsContext
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const fetch = async () => {
      setIsLoaded(false)
      await getInstallationsData(dateFilter);
      setIsLoaded(true);
    };
    fetch();
    // eslint-disable-next-line
  }, [dateFilter]); 
  return (
    <div className={classes.root}>
      <div>
        <p className={classes.header}>{translate("locationsChartTitle")}</p>
        <p className={classes.subText}>
          {dateFilter.typ === 1
            ? translate("locationsChartSubTitle7")
            : dateFilter.typ === 2
              ? translate("locationsChartSubTitle30")
              : localStorage.getItem("userLanguage") === '"tr-TR"'
                ? format(dateFilter.startDate, "dd-MM-yyyy") + " ve " + format(dateFilter.endDate, "dd-MM-yyyy") + intl.formatMessage({ id: "locationChartSubTitleCustomDate" })
                : "Between " + format(dateFilter.startDate, "dd-MM-yyyy") + " and " + format(dateFilter.endDate, "dd-MM-yyyy")
                + ", total number of active Smart Taps and locations."}
        </p>
      </div>
      <div className={classes.chart}>
           <CustomLocationChartComponent
              datas={installationData}
              isLoaded={isLoaded}
              dateFilter={dateFilter}
            />
      </div>
    </div>
  );
};

export default LocationChart;

import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import { UserMenuItem, StyledUserMenu } from "./HeaderStyles";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import ProfileImage from '../../assets/profile.png'

const UserMenu = ({ isUserInfoOpen, closeUserMenu }) => {
  const { getLogOut } = useContext(UserAuthContext);
  var getUserDetail = JSON.parse(localStorage.getItem("userAuth"));

  const handleLogout = async () => {
    await getLogOut();
    eventLogView("Header", "Log Out Clicked!");
    return <Redirect to="/" />;
  };
  return (
    <StyledUserMenu
      id="user-menu"
      anchorEl={isUserInfoOpen}
      keepMounted
      open={Boolean(isUserInfoOpen)}
      onClose={closeUserMenu}
    >
      <UserMenuItem key={Math.random()}>
        <div>
          <img height="40px" src={ProfileImage} alt="pubinno logo" />
        </div>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: "bold", color: "#043042", marginLeft: 20 }}
        >
          {getUserDetail.user.firstName}
        </Typography>
      </UserMenuItem>
      <UserMenuItem key={Math.random()}>
        <Link
          onClick={() => eventLogView("Header", "Setting Clicked!")}
          to="/settings"
        >
          <Typography
            variant="subtitle2"
            style={{ color: "#b1b0b0", marginLeft: 60 }}
          >
            <FormattedMessage id="settings" />
          </Typography>
        </Link>
      </UserMenuItem>
      <UserMenuItem onClick={handleLogout} key={Math.random()}>
        <Typography
          variant="subtitle2"
          style={{ color: "#b1b0b0", marginLeft: 60 }}
          onClick={handleLogout}
        >
          <FormattedMessage id="logout" />
        </Typography>
      </UserMenuItem>
    </StyledUserMenu>
  );
};

export default UserMenu;

/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    search: "Search",
    export: "Export",
    // header
    sendFeedback: "Send Feedback",
    feedback: "Feedback",
    send: "Send",
    feedbackMessage:
      "All feedback goes to our software development teams and helps us improve our products. If you have a bug you want to raise, please e-mail to support@pubinno.com instead so a support engineer can get in touch with you.",
    settings: "Settings",
    logout: "Log out",
    signout: "Sign Out",
    emptyNotification:
      "Your notifications will be shown here when we send them",
    // subheader
    subHeaderWelcome: "Welcome to Pubinno Insights",
    allSelected: "All Selected",
    // menu
    home: "Home",
    brands: "Brands",
    quality: "Quality",
    salesInsight: "Sales Insight",
    premium: "Premium",
    // submenu
    salesDashboard: "Sales Analytics",
    kegOptimization: "Keg Optimization",
    segmentReports: "Segment Reports",
    efficiencyTrend: "Efficiency Trend",
    monthlyPredictions: "Monthly Predictions",
    // total
    totalChartTitle: "Total Liters",
    totalChartSubTitle7:
      "You may view total liters served from all Pubinno Smart Taps in the last 7 days.",
    totalChartSubTitle30:
      "You may view total liters served from all Pubinno Smart Taps in the last 30 days.",
    totalChartSubTitleCustomDate:
      ", total liters served from all Pubinno Smart Taps.",
    totalSalesScore: "Total Sales",
    efficiency: "Keg Yield",
    qualityScore: "Quality Score",
    // efficiency
    bestEfficiency: "Best Efficiencies",
    worstEfficiency: "Worst Efficiencies",
    // last added locations
    lastAddedLocations: "Last Added Locations",
    // locations
    locationsChartTitle: "Locations and Smart Taps",
    locationsChartSubTitle7:
      "You may view the sum of Smart Taps and locations installed in the last 7 days.",
    locationsChartSubTitle30:
      "You may view the sum of Smart Taps and locations installed in the last 30 days.",
    locationChartSubTitleCustomDate:
      " between dates, You can see the total number of active Smart Taps and locations.",
    newLocations: "New Locations",
    newTaps: "New Taps",
    locationsInfoText: "Total number of Pubinno locations and Smart Taps",
    locations: "Locations",
    taps: "Taps",
    // brands table
    brand: "Brand",
    bestSellingLocation: "Best Selling Location",
    leastSellingLocation: "Least Selling Location",
    bestSelling: "BEST SELLING LOCATION",
    leastSelling: "LEAST SELLING LOCATION",
    totalLiter: "Total Liters",
    // locations table
    locationName: "Location Name",
    city: "City",
    creationDate: "Installation Date",
    totalSales: "Total Sales",
    efficiencyScore: "Keg Yield",
    smartTaps: "Smart Taps",
    region: "Region",
    subRegion: "Sub Region",
    // quality
    qualityTrend: "Quality Trend",
    qualityTrendSubTitle7:
      "You may view service quality changes in the last 7 days.",
    qualityTrendSubTitle30:
      "You may view service quality changes in the last 30 days.",
    qualityTrendSubTitleCustomDate  :
      " between dates, You can view the changes in service quality.",
    bestLocations: "Best Locations",
    worstLocations: "Worst Locations",
    maintenanceFrequency: "Maintenance Frequency",
    averageBeerTemperature: "Average Beer Temperature",
    days: "days",
    skippedMaintenance: "Skipped Maintenances",
    warmestBeer: "Warmest Beer Services",
    worstKeg: "Worst Keg Freshnesses",
    last7days: "Last 7 Days",
    last30days: "Last 30 Days",
    customDate: "Date Range",
    // smart taps
    kegFresh: "Keg Freshness",
    // send feedback responses
    feedbackSubTitle: "Your feedbacks are important to us.",
    feedbackSuccess: "Thanks for your feedback!",
    // Login page texts
    loginTitle: "Welcome",
    loginSubTitle: "It's good to see you again.",
    loginSlider1:
      "Keep track of all real time draft beer data to empower your business",
    loginSlider2:
      "Track, improve and standardize your draft beer service quality",
    loginSlider3:
      "Gain sales insights through our Machine Learning technology over real time draft beer data to boost your business performance",
    loginUser: "Username",
    loginPassword: "Password",
    loginForgot: "Forgot your password?",
    enterButton: "Log in",
    loginError: "Error!",
    errorMessage: "Username or password doesn't match",
    tryAgain: "Please try again",
    mailAsk: "Enter your e-mail",
    sendButton: "Send E-mail",
    checkMail: "Please check your mailbox to reset password",
    loginBack: "Back to login page",
    renewedPassword: "Your password has been renewed",
    askNewPassword: "Please enter your new password",
    newPassword: "New Password",
    confirmPassword: "Confirm password",
    renewButton: "Renew Your Password",
    // settings
    timeZone: "Time Zone",
    language: "Language",
    measure: "Measure",
    userName: "User Name",
    email: "E-mail",
    password: "Password",
    submit: "Submit",
    passwordChangeSuccess: "Your password has been changed!",
    passwordOldError: "Old and new password can not be same!",
    passwordConfirmError: "Your new passwords does not match.",
    passwordCharError: "At least 6 characters are required.",
    settingError:
      "Sorry but we are currently unable to complete your request, please check your inputs  and try it later.",
    languageOrTimeUpdate: "Your settings are updated!",
    // not-found
    wentWrong: "Something Went Wrong",
    sorry: " Sorry.. we can't find the page you are looking for :(",
    perhaps: "Perhaps it's because:",
    moved: "- the page has moved",
    noExist: "- the page no longer exist",
    goBack: "Go Back Home",
    passwordSecureError: "Your password must contain at least one uppercase, one lowercase, one special character, one number, and must be at least 8 characters.",
  },
};

import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import translate from "../../i18n/translate";
import { Link } from "react-router-dom";
import NotFoundImage from '../../assets/not-found.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      margin: "0",
      fontFamily: "RobotoMedium",
      padding: "0",
    },
    [theme.breakpoints.down('sm')]: {
      display: "block",
    },
  },
  bigText: {
    fontSize: "9.5rem",
    marginBottom: 35,
    [theme.breakpoints.down('lg')]: {
      fontSize: "8rem",
    },
  },
  middleText: {
    fontSize: "1.8rem",
    marginBottom: "35px !important",
    [theme.breakpoints.down('lg')]: {
      fontSize: "1.7rem",
    },
  },
  smallText: {
    fontSize: "1rem",
    [theme.breakpoints.down('lg')]: {
      fontSize: ".9rem",
    },
  },
  img: {
    width: "40%",
    marginRight: "65px !important",
    [theme.breakpoints.down('md')]: {
      minWidth: "300px  ",
    },
  },
  [theme.breakpoints.down('md')]: {
    display: "block",
  },
  marginFix: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ab2a39",
    },
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        src={NotFoundImage}
        className={classes.img}
        alt="not found"
      />

      <div className={classes.text}>
        <p className={classes.bigText}>404</p>
        <p className={classes.middleText}>{translate("wentWrong")}</p>
        <p className={classes.smallText}>{translate("sorry")}</p>
        <p className={classes.smallText} style={{ marginBottom: 35 }}>
          {translate("perhaps")}
        </p>
        <p className={classes.smallText}> {translate("moved")}</p>
        <p className={classes.smallText} style={{ marginBottom: 35 }}>
          {translate("noExist")}
        </p>
        <Link to="/home">
          <Button
            className={classes.marginFix}
            variant="contained"
            color="primary"
          >
            {translate("goBack")}
          </Button>
        </Link>
      </div>
      <div style={{ height: 60 }} />
    </div>
  );
};

export default NotFound;

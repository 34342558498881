import axios from 'axios';

export const PROXY_URL = "https://cors-anywhere.herokuapp.com/"; // for avoid CORS error- THIS LINK WILL REMOVE TO FINAL PRODUCTION

const BASE_API_URL = "https://api.perfect.beer";
const BASE_URL = "https://api.perfect.beer/api/v1" ;
const BASE_URL_WITHOUT_VERSION = "https://api.perfect.beer/api";

// const BASE_API_URL = "https://api-staging.perfect.beer";
// const BASE_URL = "https://api-staging.perfect.beer/api/v1" ;
// const BASE_URL_WITHOUT_VERSION = "https://api-staging.perfect.beer/api";

export const AUTH_URL = `${BASE_URL_WITHOUT_VERSION}/AnonymousAccount/Authenticate`;

export const SMART_TAPS_URL = `${BASE_URL}/Taps/GetTapsByDistributor`;

export const BRANDS_URL = `${BASE_URL}/Brands`;

export const FORGOT_URL = `${BASE_URL_WITHOUT_VERSION}/AnonymousAccount/ForgotPassword`;

export const VALIDATE_URL = `${BASE_URL}/User/validateResetCode`;

export const CHANGE_URL = `${BASE_URL_WITHOUT_VERSION}/AnonymousAccount/ResetPassword`;

export const TIMEZONE_URL = `${BASE_API_URL}/timeZones`;

export const SETTINGS_URL = `${BASE_URL_WITHOUT_VERSION}/Account/ChangeUserPassword`;

export const INSTALLATION_BASE_URL = `${BASE_URL}/Installations`;

export const FEEDBACK_POST = `${BASE_URL}/Feedbacks`;

export const NOTIFICATION_URL = `${BASE_URL}/Notifications`;

export const INSIGHT_DASHBOARD_AUTH_URL = `${BASE_URL}/Insights/authenticate`;

export const INSIGHT_DASHBOARD_URL = `${BASE_URL}/Insights`;

export const MAINTENANCE_URL = `${BASE_URL}/PerfectBeer/Maintenances`;

export const GET_LOCATIONS_BY_CUSTOMER_ID_URL = `${BASE_URL}/PerfectBeer/SaleReport`;

export const GET_BRANDS_BY_DISTRIBUTOR_ID_URL = `${BASE_URL}/PerfectBeer/BeerBrandReport`;

export const GET_BEER_BRANDS_ID_NAME_LOGO_URL = `${BASE_URL}/PerfectBeer/GetBestWorstLocationBeerBrandSales`;

export const TAPS_URL = `${BASE_URL}/PerfectBeer/GetAllVirtualTaps`;

export const CUSTOMERS_URL = `${BASE_URL}/PerfectBeer/GetLocationsTapDetailByDistributor`;

export const BESTWORST_LOCATIONS_URL = `${BASE_URL}/PerfectBeer/QualityScores`;

export const BESTWORST_URL = `${BASE_URL}/PerfectBeer/Efficiencies`;

export const LOCATIONS_URL = `${BASE_URL}/PerfectBeer/Locations`;

export const GET_LATEST_CUSTOMERS_OF_DISTRIBUTOR = `${BASE_URL}/PerfectBeer/LatestLocations`;

export const GetKegFreshnessesByDistributor = `${BASE_URL}/PerfectBeer/KegFreshnesses`;

export const GetBeerTemperaturesByDistributor = `${BASE_URL}/PerfectBeer/BeerTemperatures`;

export const USER_ME_URL = `${BASE_URL}/PerfectBeer/UserInformation`;

// export const SALES_URL = `${BASE_URL}/perfectBeer/GetSalesSummaryDailyWeeklyOrMonthly`;
export const SALES_URL = `${BASE_URL}/perfectBeer/TotalLitersSummary`;

export const SALES_QUALITY_URL = `${BASE_URL}/perfectBeer/QualitySummary`;

export const INSTALLATION_SALES_URL = `${BASE_URL}/perfectBeer/Installations`;

axios.interceptors.response.use(
    response => response,
    (error) => {
		if (error.response && error.response.status === 401) {
			localStorage.removeItem("userToken");
			localStorage.removeItem("userAuth");
			localStorage.removeItem("userLanguage");
			localStorage.removeItem("timeZone");
			window.location.reload();
        }

		return Promise.reject(error);
    },
);
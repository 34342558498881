import React from "react";

const EfficiencyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.174"
      height="12.876"
      viewBox="0 0 11.174 12.876"
    >
      <g
        id="Group_173"
        data-name="Group 173"
        transform="translate(-64.329 -442.567)"
      >
        <path
          id="Path_320"
          data-name="Path 320"
          d="M4699.616,2256.386a2.223,2.223,0,0,0,1.8-.259,2.31,2.31,0,0,0,1-1.465l.14-.582a2.336,2.336,0,0,0-.235-1.77,2.487,2.487,0,0,0-3.312-.783A2.337,2.337,0,0,0,4698,2253l-.138.582a2.3,2.3,0,0,0,.24,1.76A2.264,2.264,0,0,0,4699.616,2256.386Zm-.65-2.543.138-.582a1.574,1.574,0,0,1,.53-.885,1.179,1.179,0,0,1,1.735.413,1.563,1.563,0,0,1,.079,1.029l-.139.583a1.535,1.535,0,0,1-.529.881,1.05,1.05,0,0,1-.945.182,1.086,1.086,0,0,1-.79-.6A1.528,1.528,0,0,1,4698.966,2253.843Z"
          transform="translate(-4633.457 -1808.616)"
          fill="#9ca7b6"
        />
        <path
          id="Path_321"
          data-name="Path 321"
          d="M4742.875,2332.452a2.319,2.319,0,0,0-1.881.271,2.408,2.408,0,0,0-1.042,1.524l-.146.613a2.393,2.393,0,0,0,.249,1.835,2.354,2.354,0,0,0,1.571,1.087,2.309,2.309,0,0,0,1.865-.271,2.407,2.407,0,0,0,1.043-1.528l.146-.613a2.4,2.4,0,0,0-.25-1.831A2.326,2.326,0,0,0,4742.875,2332.452Zm.657,2.645-.145.613a1.613,1.613,0,0,1-.55.919,1.09,1.09,0,0,1-.984.193,1.123,1.123,0,0,1-.819-.621,1.594,1.594,0,0,1-.082-1.069l.145-.613a1.6,1.6,0,0,1,.553-.914,1.224,1.224,0,0,1,1.8.426A1.589,1.589,0,0,1,4743.531,2335.1Z"
          transform="translate(-4670.107 -1882.419)"
          fill="#9ca7b6"
        />
        <path
          id="Path_322"
          data-name="Path 322"
          d="M4712.11,2262.09l-.606-4.493-4.2-.858-.209,1.024,2.743.56-8.9,8.891.91.918,8.834-8.823.394,2.92Z"
          transform="translate(-4636.607 -1813.95)"
          fill="#9ca7b6"
        />
      </g>
    </svg>
  );
};

export default EfficiencyIcon;

import React, { useContext, useEffect } from "react";
import LoginLogoCard from "../login/LoginLogoCard";
import ForgotPassword from "./ForgotPassword";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import { makeStyles } from "@mui/styles";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  shadow: {
    backgroundColor: "#F8F8F8",
    boxShadow: " 0 3px 6px rgba(0,0,0,0.16), 0 8px 25px rgba(0,0,0,0.23)",
    width: "58%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('lg')]: {
      display: "block",
      margin: "20px 0",
    },
    [theme.breakpoints.down('md')]: {
      width: "80%",
    },
  },
  card: {
    padding: 0,
  },
}));
const LoginPage = (props) => {
  const { userAuth, setUserAuth } = useContext(UserAuthContext);

  useEffect(() => {
    try {
      const localStatus = localStorage.getItem("userAuth");
      setUserAuth(Boolean(localStatus));
    } catch (error) {}
    // eslint-disable-next-line
  }, [userAuth]);

  const classes = useStyles();
  if (!userAuth) {
    return (
      <div className={classes.shadow}>
        <LoginLogoCard />
        <ForgotPassword />
      </div>
    );
  } else return <Redirect to="/home" />;
};

export default LoginPage;

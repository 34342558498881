import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import UserProvider from "./lib/context/user/UserProvider";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import StyledEngineProvider from '@mui/material/StyledEngineProvider'

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_DOMAIN_CLIENT_ID}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        audience={'PubinnoApps'}
        cookieDomain={window.location.origin}
      >
        <UserProvider>
          <App />
        </UserProvider>
      </Auth0Provider>
    </BrowserRouter>
  </StyledEngineProvider>,
  document.getElementById("root")
);

import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { VALIDATE_URL } from "../../lib/urls/urls";
import axios from "axios";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ValidateResetCode = () => {
  let query = useQuery();
  const [isValid, setIsValid] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);

  let resetToken = query.get("code");

  const body = {
    resetCode: resetToken,
  };
  const header = { ContentType: "application / json" };

  React.useEffect(() => {
    axios
      .post(VALIDATE_URL, body, header)
      .then((res) => {
        if (res.status === 200) {
          setIsValid(true);
          setIsLoaded(true);
        }
      })
      .catch((err) => {
        setIsLoaded(true);
      });
  });

  return (
    <>
      {isLoaded ? (
        isValid ? (
          <Redirect to={`/renew-password/${resetToken}`} />
        ) : (
          "Wrong Token!"
        )
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default ValidateResetCode;

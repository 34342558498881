import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { withStyles } from "@mui/styles";

export const StyledMenu = withStyles({
  paper: {
    border: "5px solid #F0F0F0",
    backgroundColor: "#white",
    borderRadius: 8,
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles(() => ({
  root: {
    backgroundColor: "#F0F0F0",
    "&:focus": {
      backgroundColor: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {},
    },
  },
}))(MenuItem);

// When click person icon, below menu appear
export const StyledUserMenu = withStyles({
  paper: {
    width: "max-content",
    border: "3px solid #F0F0F0",
    padding: 5,
    borderRadius: 8,
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const UserMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: "Roboto",
    backgroundColor: "white",
    "&:focus": {
      backgroundColor: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {},
    },
  },
}))(MenuItem);

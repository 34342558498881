import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Badge } from "@mui/material";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Feedback from "./Feedback";
import MoreIcon from "@mui/icons-material/MoreVert";
import StyledUserMenu from "./UserMenu";
import Notification from "./Notification";
import Info from "./Info";
import MobileHeaderMenu from "./MobileHeaderMenu";
import NotificationIcon from "./NotificationIcon";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import PubinnoTransparentSilver from '../../assets/pubinno_transparent-silver.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    margin: "0 30px",
  },
  btn: {
    "& .MuiButton-label ": {
      fontSize: "11px !important",
      textTransform: "capitalize",
    },
    marginRight: 5,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      //width: "220px",
      width: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  customBadge: {
    border: "1px solid #fff",

    "& .MuidBadge-badge": {
      height: "10px !important",
    },
  },
  notificationSubText: {
    fontWeight: "bold",
  },
  headerBadge: {
    "&:hover": {
      cursor: "pointer",
    },
    "& .MuiBadge-anchorOriginTopRightRectangular": {
      transform: "scale(.8) translate(30%, -20%)",
      padding: "0 !important",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      border: "2px solid #fff",
    },
    [theme.breakpoints.down('md')]: {
      color: `${theme.palette.primary.main}`,
    },
  },
  notificationAlign: {
    display: "flex",
    justifyContent: "space-around",
  },

  headerIcons: {
    "& .MuiSvgIcon-root": {
      padding: "0 !important",
    },
    padding: 0,
    width: "38px",
    "&:hover": {
      cursor: "pointer",
    },
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      color: theme.palette.primary.main,
    },
  },
  sendIcon: {
    width: "2px",
  },
}));

const Header = () => {
  const classes = useStyles();
  const [isInfoOpen, setIsInfoOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getUser, notificationHelper } = useContext(UserAuthContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [count, setCount] = useState(0);

  const handleNotificationClick = () => {
    setCount(count + 1);
  };
  const openModelHandle = (bool) => {
    eventLogView(
      "Feedback",
      bool ? "Feedback Modal Opened!" : "Feedback Modal Closed!"
    );
    setIsModalOpen(bool);
  };
  useEffect(() => {
    const fetch = async () => {
      await getUser();
      // eslint-disable-next-line
      await notificationHelper?.getNotification();
      setIsLoaded(true);
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    eventLogView("Header", "Mobile Menu Opened!");
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
    eventLogView("Header", "Mobile More Opened!");
  };

  const handleNotificationMenuClose = () => {
    setAnchorEl(null);
    eventLogView("Header", "Notifications Closed! ");
  };
  const handleNotificationMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    eventLogView("Header", "Notifications Opened! ");

    handleNotificationClick();
  };

  const handleInfoMenuClose = () => {
    setIsInfoOpen(null);
    eventLogView("Header", "Info Menu Closed!");
  };

  //user menu
  const handleUserMenu = (e) => {
    eventLogView("Header", "User Menu Clicked!");
    setIsUserInfoOpen(e.currentTarget);
  };
  const closeUserMenu = () => {
    eventLogView("Header", "User Menu Closed!");
    setIsUserInfoOpen(null);
  };

  // feedback menu
  const handleOpenModal = () => {
    openModelHandle(true);
  };
  const handleCloseModal = () => {
    openModelHandle(false);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <MobileHeaderMenu
      isMobileMenuOpen={isMobileMenuOpen}
      mobileMoreAnchorEl={mobileMoreAnchorEl}
      handleMobileMenuClose={handleMobileMenuClose}
      handleOpenModal={handleOpenModal}
      handleClick={handleNotificationMenuOpen}
      handleUserMenu={handleUserMenu}
      mobileMenuId={mobileMenuId}
      handleInfoClick={handleInfoMenuClose}
    />
  );

  return (
    <div className={classes.root}>
      <Link to="/home">
        <img
          style={{ width: "80px" }}
          src={PubinnoTransparentSilver}
          alt="pubinno logo"
        />
      </Link>

      {isLoaded && (
        <>
          <div className={classes.sectionDesktop}>
            <div
              onClick={handleNotificationMenuOpen}
              style={{ marginRight: -8 }}
            >
              <Badge
                overlap="rectangular"
                badgeContent={
                  notificationHelper?.notifications
                    ? notificationHelper?.notifications?.length
                    : 0
                }
                className={classes.headerBadge}
              >
                <NotificationIcon className={classes.headerIcons} />
              </Badge>
            </div>
            <div onClick={handleUserMenu} style={{ marginRight: -13 }}>
              <PersonIcon className={classes.headerIcons} />
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <div
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              style={{ paddingLeft: 20 }}
            >
              <MoreIcon />
            </div>
          </div>
        </>
      )}

      {renderMobileMenu}

      <Info
        notifications={notificationHelper?.notifications}
        anchorEl={isInfoOpen}
        handleClose={handleInfoMenuClose}
        handleClick={handleNotificationClick}
        count={count}
      />
      <Notification
        notifications={notificationHelper?.notifications}
        anchorEl={anchorEl}
        handleClose={handleNotificationMenuClose}
        handleClick={handleNotificationClick}
        count={count}
      />

      <StyledUserMenu
        isUserInfoOpen={isUserInfoOpen}
        closeUserMenu={closeUserMenu}
      />

      {isModalOpen && (
        <Feedback
          handleOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Header;

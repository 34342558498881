import { TimezonesContext } from "./TimezonesContext";
import React, { useState } from "react";
import axios from "axios";
import { TIMEZONE_URL } from "../../urls/urls";

const TimezonesProvider = ({ children }) => {
  const [timezones, setTimeZones] = useState([]);

  const getTimezonesData = async () => {
    const localToken = localStorage.getItem("userToken");

    let res = await axios.get(TIMEZONE_URL, {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });
    const { data } = res;

    setTimeZones(data);
  };

  return (
    <TimezonesContext.Provider
      value={{
        timezones: timezones,
        getTimezonesData: getTimezonesData,
      }}
    >
      {children}
    </TimezonesContext.Provider>
  );
};

export default TimezonesProvider;

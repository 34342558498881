import React, { forwardRef, useContext } from "react";
import MaterialTable from "@material-table/core";
import StyledEngineProvider from '@mui/material/StyledEngineProvider'

import { TapsContext } from "../../lib/context/taps/TapsContext";
import AddBox from "@mui/icons-material/AddBox";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { Box, Skeleton } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import translate from "../../i18n/translate";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <CodeIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const cellStyle = {
  fontFamily: "RobotoRegular",
  fontSize: "14px",
  color: "#043042",
  // fontWeight: "bold",
  textAlign: "left",
  padding: 1,
};
const headerStyle = {
  padding: "16px 0",
};

const options = {
  search: false,
  toolbar: false,
  isLoading: true,
  paging: false,
  draggable: false,
  cellStyle: cellStyle,
  headerStyle: headerStyle,
  padding: "dense",
};

const date = (date) => {
  return new Date(date).toLocaleDateString();
};

const TableRow = ({ rowData, dateFilter }) => {
  const { getTapsData, tapsData } = useContext(TapsContext);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      await getTapsData(dateFilter, rowData.rowData.id);
      setIsLoaded(true);
    };
    fetchData();

    // eslint-disable-next-line
  }, [dateFilter, rowData]);

  const row = tapsData?tapsData.map((item) => {
    let payload = {
      tab: `${item.name}`,
      totalSales: `${item.totalLiters ? item.totalLiters.toFixed(1) : "0"}`,
      efficiency: `${item.efficiency ? item.efficiency.toFixed(2) * 100 : "0"}`,
      qualityScore: `${item.qualityScore ? item.qualityScore.toFixed(0) : "0"}`,
      creationDate: `${date(item.creationDate)}`,
    };

    return payload;
  }):[];

  const columns = [
    {
      title: translate("brands"),
      field: "tab",
      cellStyle: {
        fontWeight: "500",
        fontFamily: "RobotoRegular",
      },
      headerStyle: {
        paddingLeft: "15px",
      },
    },
    {
      title: translate("totalSales"),
      field: "totalSales",
      render: (rowData) => {
        return (
          <div>
            <p style={{ fontWeight: "bold", fontFamily: "RobotoRegular" }}>
              {Intl.NumberFormat().format(rowData.totalSales)} lt
            </p>
          </div>
        );
      },
    },

    {
      title: translate("efficiencyScore"),
      field: "efficiency",
      render: (rowData) => {
        return (
          <div>
            <p style={{ fontWeight: "bold", fontFamily: "RobotoRegular" }}>
              {parseInt(rowData.efficiency)} %
            </p>
          </div>
        );
      },
    },
    {
      title: translate("qualityScore"),
      field: "qualityScore",
      render: (rowData) => {
        return (
          <div>
            <p style={{ fontWeight: "bold", fontFamily: "RobotoRegular" }}>
              {rowData.qualityScore === "-1" ? (
                "-"
              ) : (
                  <>{Number(rowData.qualityScore).toFixed(1)}</>
                )}
            </p>
          </div>
        );
      },
    }
  ];
  const componentStyle = {
    Container: "Box",
  };
 
  return (
    <Box marginLeft="3%" display="flex" flexDirection="row">
      {isLoaded ? (
        <div style={{ width: "75%" }}>
          <StyledEngineProvider injectFirst>
            <MaterialTable
              columns={columns}
              data={row}
              icons={tableIcons}
              options={options}
              components={componentStyle}
            />
          </StyledEngineProvider>
        </div>
      ) : (
          <React.Fragment>
            <Skeleton
              style={{ margin: "10px 0" }}
              animation="wave"
              variant="rect"
              width="100%"
              height="100px"
            />
          </React.Fragment>
        )}
    </Box>
  );
};
export default TableRow;

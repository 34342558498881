import React from "react";
import { makeStyles } from "@mui/styles";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";

const useStyle = makeStyles((theme) => ({
  nav: {
    "&.MuiSvgIcon-root": {
      fontSize: "0.8rem !important",
    },
  },
}));
const Indicator = ({ activePage, onClick }) => {
  const classes = useStyle();
  if (activePage) {
    return (
      <div onClick={onClick}>
        <FiberManualRecordIcon color="secondary" className={classes.nav} />
      </div>
    );
  }
  return (
    <div onClick={onClick}>
      <FiberManualRecordOutlinedIcon
        color="secondary"
        className={classes.nav}
      />
    </div>
  );
};

export default Indicator;

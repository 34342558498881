import React from "react";
import FooterLogo from "./FooterLogo";
import Social from "./Social";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: "#C43042",
    padding: "5px 25px",
  },
  social: {
    textAlign: "right",
    marginRight: "10px",
  },
}));
export default function Footer() {
  const style = useStyles();
  return (
    <div className={style.root}>
      <FooterLogo />
      <Social />
    </div>
  );
}

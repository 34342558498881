import React from "react";
import { makeStyles } from "@mui/styles";
import { upArrow, downArrow } from "../../../table-locations/Arrows";
const useStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      padding: 0,
      margin: "0",
    },
  },
  liter: {
    fontWeight: "bold",
    fontSize: "1.25em",
    fontFamily: "RobotoMedium",
  },
  percentageUp: {
    fontWeight: "bold",
    fontSize: "1em",
    color: "#46B85B",
  },
  percentageDown: {
    fontWeight: "bold",
    fontSize: "1em",
    color: theme.palette.primary.main,
  },
  brandName: {
    fontWeight: "bold",
    fontSize: "1em",
    color: "#8492A5",
    fontFamily: "RobotoMedium",
  },
  alignLiters: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    textAlign: "center",
  },
  alignPercentage: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
    marginLeft: 15,
    width: "max-content",
  },
  imgStyle: {
    width: "50px",
    height: "100%",
    objectFit: "cover",
  },
}));

const LogoCard = ({
  brandLogo,
  totalLiters,
  totalLitersChange,
  brandName,
  symbol,
  isLoaded,
  isTotal,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.alignLiters}>
        <img className={classes.imgStyle} src={brandLogo} alt="brand kogo" />
      </div>
      <div className={classes.alignLiters}>
        <p className={classes.liter}>
          {totalLiters ? isTotal ? Intl.NumberFormat().format(totalLiters.toFixed(1)) : totalLiters.toFixed(1) : totalLiters} {symbol}
        </p>
        <div className={classes.alignPercentage}>
          {totalLitersChange < 0 ? (
            <span style={{ marginRight: 5 }}>{downArrow}</span>
          ) : (
            <span style={{ marginRight: 5 }}>{upArrow}</span>
          )}
          <p
            className={
              totalLitersChange < 0
                ? classes.percentageDown
                : classes.percentageUp
            }
          >
            {Math.abs(totalLitersChange.toFixed(2)) >= 300
              ? " <300"
              : Math.abs(totalLitersChange.toFixed(2))}
            %
          </p>
        </div>
      </div>
      <p className={`${classes.brandName} ${classes.alignLiters}`}>
        {brandName}
      </p>
    </div>
  );
};

export default LogoCard;

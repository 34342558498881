import React from "react";
import { makeStyles } from "@mui/styles";
import Flip from "react-reveal/Flip";
import { upArrow, downArrow } from "../../table-locations/Arrows";
const ByDayCard = ({ title, liters, percentage, type }) => {
  const useStyle = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& p": {
        margin: "0",
        fontSize: 13,
        fontFamily: "RobotoRegular",
      },
    },
    title: {
      fontWeight: "bold",
    },
    score: {
      fontWeight: "bold",
    },
    percent: {
      fontSize: 12,
      fontWeight: "bold",
      color: percentage > 0 ? "#46B85B" : theme.palette.primary.main,
      alignItems: "baseline",
    },
    alignBottom: {
      display: "flex",
      // alignItems: "center",
    },
  }));

  const classes = useStyle();

  return (
    <Flip top>
      <div className={classes.root}>
        <p className={classes.title}>{title}</p>
        <div className={classes.alignBottom}>
          <p className={classes.score}>{`${liters} ${type}`}</p>
          <p className={classes.percent}>
            <span style={{ marginLeft: "10px", marginRight: "5px" }}>
              {percentage <= 0 ? downArrow : upArrow}
            </span>
            {Math.abs(percentage) >= 300 ? " <300" : Math.abs(percentage).toFixed(1)} %
          </p>
        </div>
      </div>
    </Flip>
  );
};

export default ByDayCard;

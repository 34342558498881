import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { makeStyles } from "@mui/styles";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import VimeoLogo from '../../assets/vimeo.png'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "38px",
    [theme.breakpoints.down('md')]: {
      width: "30px",
    },
  },
  socialIcons: {
    marginTop: "5px",
    width: "50%",
    "& a": {
      color: "white",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      marginLeft: 10,
      padding: 5,
    },
  },
  vimeo: {
    width: "22px",
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: "2px",
    },
  },
}));

const Social = () => {
  const classes = useStyles();

  const menuClickHandler = (link) => {
    let categoryName = "Footer";
    let actionName = `${link} clicked`;

    eventLogView(categoryName, actionName);
  };

  return (
    <div className={classes.socialIcons}>
      <a
        href="https://www.instagram.com/pubinno/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => menuClickHandler("Instagram")}
      >
        <InstagramIcon className={classes.icon} />
      </a>
      <a
        href="https://www.facebook.com/Pubinno/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => menuClickHandler("Facebook")}
      >
        <FacebookIcon className={classes.icon} />
      </a>
      <a
        href="https://twitter.com/pubinno"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => menuClickHandler("Twitter")}
      >
        <TwitterIcon className={classes.icon} />
      </a>
      <a
        href="https://www.linkedin.com/company/pubinno/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => menuClickHandler("Linkeding")}
      >
        <LinkedInIcon className={classes.icon} />
      </a>
      <a
        href="https://vimeo.com/user40545679"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => menuClickHandler("Vimeo")}
      >
        <img
          src={VimeoLogo}
          className={`${classes.icon} ${classes.vimeo}`}
          alt="vimeo logo"
        />
      </a>
    </div>
  );
};

export default Social;

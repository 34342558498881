import React from "react";
import CustomersProvider from "../../lib/context/customers/CustomersProvider";
import TapsProvider from "../../lib/context/taps/TapsProvider";
import LocationsTable from "../../components/table-locations";

const LocationsPage = ({dateFilter}) => {
  return (
    <CustomersProvider>
      <TapsProvider>
        <LocationsTable dateFilter={dateFilter} />
      </TapsProvider>
    </CustomersProvider>
  );
};

export default LocationsPage;

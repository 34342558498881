import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Skeleton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation } from "react-router-dom";
import translate from "../../i18n/translate";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import DayOptions from "../location-taps/outlets-taps/DayOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: "0 15px",
    height: 60,
    "& .makeStyles": {
      root: {
        padding: "0 !important",
      },
    },
    "& p": {
      margin: "0",
      fontFamily: "RobotoRegular",
      color: "#303447",
    },
    "& a": {
      color: "#303447",
    },
  },
  alignLeft: {
    width: 505,
    [theme.breakpoints.down('sm')]: {
      width: "100vw",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    color: "#043042",
    fontWeight: "bold",
    fontSize: "20px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
      marginRight: "60px",
    },
  },
  export: {
    marginTop: 2
  },
}));

const SubHeader = ({ dateFilter, setDateFilter }) => {
  const classes = useStyles();
  const { user } = useContext(UserAuthContext);
  const { pathname } = useLocation();
  var getUserDetail = JSON.parse(localStorage.getItem("userAuth"));

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item style={{ padding: 0 }} className={"cLogoArea"}>
          <div
            className={classes.alignLeft}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <Grid
              container
              className={classes.logo}
              spacing={0}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <div>
                  {getUserDetail.isLogin ? (
                    <img
                      src={user.activeAccount.accountLogo}
                      alt="brand logo"
                      style={{ width: "80px", height: "100%" }}
                    />
                  ) : (
                      <Skeleton
                        style={{ margin: "10px 0" }}
                        variant="rectangular"
                        animation="wave"
                        width="80px"
                        height={40}
                      />
                    )}
                </div>
              </Grid>
              <Grid item className={"welcomeMessage"}>
                <Typography variant="subtitle1" className={classes.text}>
                  {translate("subHeaderWelcome")}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <div className="subHeaderRight">
          {
            pathname === "/brands" || pathname === "/home" || pathname === "/quality" || pathname === "/locations" || pathname === "/smarttaps"
              ? <Grid container
                direction="row" style={{ flexWrap: "initial" }}>
                <Grid item>
                  <DayOptions dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Grid>
              </Grid>
              : null}
        </div>
      </Grid>
    </div >
  );
};

export default SubHeader;

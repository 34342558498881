import React, { useContext, useEffect } from "react";
import LoginLogoCard from "./LoginLogoCard";
import LoginInputCard from "./LoginInputCard";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import { makeStyles } from "@mui/styles";
import { Redirect, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  shadow: {
    backgroundColor: "#F8F8F8",
    boxShadow: " 0 3px 6px rgba(0,0,0,0.16), 0 8px 25px rgba(0,0,0,0.23)",
    width: "58%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('lg')]: {
      display: "block",
      margin: "20px 0",
    },
    [theme.breakpoints.down('md')]: {
      width: "80%",
    },
  },
  card: {
    padding: 0,
  },
}));
const LoginPage = () => {
  const { setUserAuth } = useContext(UserAuthContext);
  const localStatus = JSON.parse(localStorage.getItem("userAuth"));

  useEffect(() => {
    try {
      setUserAuth(localStatus?.isLogin);
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  const location = useLocation();

  const classes = useStyles();
  if (!localStatus?.isLogin) {
    return (
      <div className={classes.shadow}>
        <LoginLogoCard />
        <LoginInputCard />
      </div>
    );
  } else {
    if (location.state !== undefined) {
      if (location.state.from.pathname === "/locations") {
        return <Redirect to="/locations" />;
      } else if (location.state.from.pathname === "/brands") {
        return <Redirect to="/brands" />;
      } else if (location.state.from.pathname === "/quality") {
        return <Redirect to="/quality" />;
      } else if (location.state.from.pathname === "/settings") {
        return <Redirect to="/settings" />;
      }
      else if (location.state.from.pathname === "/home") {
        return <Redirect to="/home" />;
      } 
      return <Redirect to="/not-found" />;
    }
    if (location.state === undefined && location.pathname === "/") {
      return <Redirect to="/home" />;
    }
  }
};

export default LoginPage;
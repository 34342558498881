import React, { useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { Typography, Button, Box, Alert, IconButton, InputAdornment, TextField } from "@mui/material";
import ApprovedIcon from "./ApprovedIcon";
import { Link } from "react-router-dom";
import LoginLogoCard from "../login/LoginLogoCard";
import PrevIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { CHANGE_URL } from "../../lib/urls/urls";
import translate from "../../i18n/translate";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import { strengthIndicator, strengthColor } from "./Strenght";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation } from "react-router-dom";
import RedLogoSlogan from '../../assets/redLogo_slogan.png'

const useStyles = makeStyles((theme) => ({
  shadow: {
    backgroundColor: "#F8F8F8",
    boxShadow: " 0 3px 6px rgba(0,0,0,0.16), 0 8px 25px rgba(0,0,0,0.23)",
    width: "58%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('lg')]: {
      display: "block",
      margin: "20px 0",
    },
    [theme.breakpoints.down('md')]: {
      width: "80%",
    },
  },
  root: {
    padding: "0 80px",
    [theme.breakpoints.down('lg')]: {
      padding: "80px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "20px",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.dark,
      fontStyle: "italic",
      fontSize: "1rem",
    },
    "& MuiInput-root": {
      "&:hover": {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  form: {
    width: "50%",
    margin: "auto 0",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
  zeroPadding: {
    padding: "2px",
  },
  marginFix: {
    width: "100%",
    marginTop: 60,
    "&:hover": {
      backgroundColor: "#ab2a39",
    },
  },
  marginFix3: {
    width: "100%",
    marginTop: 50,
    "&:hover": {
      backgroundColor: "#ab2a39",
    },
  },
  logo: {
    marginBottom: 70,
    width: "130px",
    [theme.breakpoints.down('xl')]: {
      width: "65%",
    },
    [theme.breakpoints.down('lg')]: {
      width: "25%",
    },
  },
  text: {
    fontSize: "1em",
    fontStyle: "italic",
    marginTop: 20,
    cursor: "pointer",
  },
  text2: {
    fontSize: "1em",
    fontStyle: "italic",
    marginTop: 20,
  },
  text3: {
    fontFamily: "RobotoCondensed",
    fontSize: "1.1em",
    fontStyle: "italic",
    marginTop: -10,
    color: "#444444",
  },
  text4: {
    fontFamily: "RobotoCondensed",
    fontSize: "1.1em",
    fontStyle: "italic",
    marginTop: 10,
    color: "#444444",
  },
  border: {
    bordorBottom: "1px solid black",
  },
}));

const InputCustom = withStyles({
  root: {
    "& label.Mui-underline": {
      color: ` "#C43042"`,
      marginTop: "5px",
      "&:hover": {
        borderBottomColor: "#C433042",
      },
    },
    "& label.Mui-focused": {
      color: "#C43042",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#C43042",
      "&:hover": {
        borderBottomColor: "#C43042",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#C43042",
      "&:hover": {
        borderBottomColor: "#C43042",
      },
    },
  },
})(TextField);

const RenewPassword = () => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
    isSent: false,
  });
  const [isValid, setIsValid] = useState(false);
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = (location.search.split("?token=")[1]).split("&")[0];
    const currentUrl = location.pathname;
    const email = currentUrl.split("/");
    const body = {
      token: token,
      email:  email[2],
      password: password.password,
      confirmPassword: password.confirmPassword,
    };

    const header = { ContentType: "application / json" };
    var response;
    try {
       response = await axios.post(CHANGE_URL, body, header);
      if (response.status === 200) {
        setPassword({ ...password, isSent: true });
      }else if(response.status === 400){
        setErrorMessage(response.data.Message)
      }
    } catch (error) {
      setErrorMessage(error.response.data.Message)
    }

    // Google Analytics
    eventLogView("Forgot Password", "New Password set Button Clicked!");
  };

  const handleChange = (prop) => (event) => {
    setPassword({ ...password, [prop]: event.target.value });
  };

  const handlePasswordMatch = (password, confirmPassword) => {
    if (password === confirmPassword) return true;
    else {
      return false;
    }
  };

  const strength = strengthIndicator(password.password);
  const color = strengthColor(strength);

  const approvedScreen = (
    <>
      <ApprovedIcon />
      <div className={classes.border}>
        <Typography className={classes.text4} variant="body2">
          {translate("renewedPassword")}
        </Typography>
      </div>
      <Link to="/login">
        <Button
          className={classes.marginFix}
          variant="contained"
          color="primary"
          type="submit"
          title="Giriş"
          startIcon={<PrevIcon />}
        >
          {translate("loginBack")}
        </Button>
      </Link>
    </>
  );

  const newPassword = (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Typography className={classes.text2} variant="body2" color="primary">
        {translate("askNewPassword")}
      </Typography>
      <InputCustom
        required
        onChange={handleChange("password")}
        value={password.password}
        fullWidth
        type={showPassword1 ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.zeroPadding}
                aria-label="password visibility"
                onClick={() => setShowPassword1(!showPassword1)}
                size="large">
                {showPassword1 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyUp={() =>
          setIsValid(
            handlePasswordMatch(password.password, password.confirmPassword)
          )
        }
        id="password"
      />
      <div
        style={{
          marginTop: 5,
          height: "5px",
          width: color.width,
          backgroundColor: color.color,
        }}
      />
      <InputCustom
        required
        label={translate("confirmPassword")}
        fullWidth
        value={password.passwordConfirm}
        onChange={handleChange("confirmPassword")}
        type={showPassword2 ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.zeroPadding}
                aria-label="password visibility"
                onClick={() => setShowPassword2(!showPassword2)}
                size="large">
                {showPassword2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyUp={() =>
          setIsValid(
            handlePasswordMatch(password.password, password.confirmPassword)
          )
        }
        id="confirmPassword"
      />
      {!isValid ? (
        <Alert severity="error" style={{ marginTop: 5 }}>
          Password do not match.
        </Alert>
      ) : null}
       {errorMessage ? (
        <Alert severity="error" style={{ marginTop: 5 }}>
          {errorMessage}
        </Alert>
      ) : null}
      <Button
        className={classes.marginFix}
        variant="contained"
        color="primary"
        type="submit"
        title={translate("enterButton")}
      >
        {translate("renewButton")}
      </Button>
    </form>
  );

  return (
    <div className={classes.shadow}>
      <LoginLogoCard />
      <div className={classes.form}>
        <div className={classes.root}>
          <Box margin="0 auto 30px auto" className={classes.logo}>
            <img
              width="100%"
              src={RedLogoSlogan}
              alt="Pubinno Red Logo"
            />
          </Box>

          {!password.isSent ? newPassword : approvedScreen}
        </div>
      </div>
    </div>
  );
};

export default RenewPassword;

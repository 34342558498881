import ReactGa from "react-ga";

export const initGa = (userId) => {
  ReactGa.initialize("UA-153316735-1", { gaOptions: { userId: userId } });
};

export const logPageView = (location) => {
  ReactGa.set({ page: location.pathname });
  ReactGa.pageview(location.pathname);
};

export const eventLogView = (categoryNme, actionName) => {
  ReactGa.event({
    category: categoryNme,
    action: actionName,
  });
};

 export const GaLogin = (userId) => {
   ReactGa.set({ userId: userId });
 };

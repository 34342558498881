import React from "react";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import translate from "../../../i18n/translate";

const style = {
  buttonColor: "#00a23e",
  buttonHeight: "10px",
  buttonWidth: "15px",
};

const ExportIcon = () => {
  const location = useLocation();

  const useStyles = makeStyles((theme) => ({
    rootTable: {
      cursor: "pointer",
      "&:hover": {
        fontWeight: "bold",
      },
    },
    root: {
      cursor: "pointer",
      "&:hover": {
        fontWeight: "bold",
      },
    },
    exportIcon: {
      borderBottom: "2px solid #00a23e",
      paddingBottom: "2px !important",
    },
    exportIconTable: {
      borderBottom: "2px solid #00a23e",
    },
    text: {
      color: "#00a23e",
      fontSize: "14px",
      fontFamily: "Roboto, Helvetica, sans-serif",
      paddingRight: "10px",
      paddingBottom: "1px",
    },
  }));
  const classes = useStyles();
  return (
    <div
      className={
        location.pathname === "/quality" ? classes.root : classes.rootTable
      }
    >
      <span className={classes.text}>{translate("export")}</span>
      <span
        className={
          location.pathname === "/quality"
            ? classes.exportIcon
            : classes.exportIconTable
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={style.buttonWidth}
          height={style.buttonHeight}
          viewBox="0 0 14.494 10"
        >
          <path
            id="ExportIcon"
            d="M7.247,0l7.247,5H9.662v5H4.831V5H0Z"
            transform="translate(14.494 10) rotate(180)"
            fill={style.buttonColor}
          />
        </svg>
      </span>
    </div>
  );
};

export default ExportIcon;

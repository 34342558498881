import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { Skeleton } from "@mui/material";
const TotalChartCustomDate = ({
    previousData,
    currentData,
    dateFilter,
    isTotal,
    isLoaded,
}) => {
    const dailySumOptions = {
        day: "numeric",
        weekday: "short",
    };
    const weeklySumOptions = {
        day: "numeric",
        month: "short",  //August 13 & August 20
    };
    const monthlyDateOptions = {
        month: "short",
        year: "numeric"
    };
    let previousDailyDataList, currentDailyDataList, currentWeeklyDataList, previousWeeklyDataList, previousMonthlyDataList, currentMonthlyDataList;
    const dateRange = dateFilter.endDate !== null ? Math.abs(dateFilter.endDate - dateFilter.startDate) / (1000 * 60 * 60 * 24) : 7;

    let tickRotation = 0;
    if (currentData.dailySums && currentData.dailySums.length > 0 && previousData.dailySumsPrevious && previousData.dailySumsPrevious.length > 0) {
        try {
            currentDailyDataList = currentData.dailySums ? currentData.dailySums.length > 0 ? currentData.dailySums.map(
                ({ day, totalLiters, qualityScore }) => {
                    let convertDate = new Date(day);
                    if (isTotal) {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 25 ? dailySumOptions : monthlyDateOptions),
                            y: totalLiters ? totalLiters.toFixed(1) : 0,
                        };
                    } else {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 25 ? dailySumOptions : monthlyDateOptions),
                            y: qualityScore ? qualityScore.toFixed(1) : 0,
                        };
                    }
                }
               
            ) : [] : [];

            previousDailyDataList = previousData.dailySumsPrevious ? previousData.dailySumsPrevious.length > 0 ? previousData.dailySumsPrevious.map(
                ({ day, totalLiters, qualityScore }) => {
                    let convertDate = new Date(day);
                    convertDate.setDate(convertDate.getDate())
                    if (isTotal) {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 25 ? dailySumOptions : monthlyDateOptions),
                            y: totalLiters ? totalLiters.toFixed(1) : 0,
                        };
                    } else {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 25 ? dailySumOptions : monthlyDateOptions),
                            y: qualityScore ? qualityScore.toFixed(1) : 0,
                        };
                    }
                }
            ) : [] : [];
      

        } catch (error) {
            currentDailyDataList = [];
            previousDailyDataList = [];
        }

        if (dateRange < 8) {
            tickRotation = 60;
        } else {
            tickRotation = 60
        }
    } else if (currentData.weeklySums && currentData.weeklySums.length > 0 && previousData.weeklySumsPrevious && previousData.weeklySumsPrevious.length > 0) {
        try {
            currentWeeklyDataList = currentData.weeklySums ? currentData.weeklySums.length > 0 ? currentData.weeklySums.map(
                ({ weekStartDate, totalLiters, qualityScore }) => {
                    let convertDate = new Date(weekStartDate);
                    if (isTotal) {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 28 ? weeklySumOptions : dailySumOptions),
                            y: totalLiters ? totalLiters.toFixed(1) : 0,
                        };
                    } else {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 28  ? weeklySumOptions : dailySumOptions),
                            y: qualityScore ? qualityScore.toFixed(1) : 0,
                        };
                    }
                }
            ) : [] : [];
            
            previousWeeklyDataList = previousData.weeklySumsPrevious ? previousData.weeklySumsPrevious.length > 0 ? previousData.weeklySumsPrevious.map(
                ({ weekStartDate, totalLiters, qualityScore }) => {
                    let convertDate = new Date(weekStartDate);
                    convertDate.setDate(convertDate.getDate())
                    if (isTotal) {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 28  ? weeklySumOptions : dailySumOptions),
                            y: totalLiters ? totalLiters.toFixed(1) : 0,
                        };
                    } else {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange < 28  ? weeklySumOptions : dailySumOptions),
                            y: qualityScore ? qualityScore.toFixed(1) : 0,
                        };
                    }
                }
            ) : [] : [];
        } catch (error) {
            currentWeeklyDataList = [];
            previousWeeklyDataList = [];
        }

        if (dateRange < 8) {
            tickRotation = 60;
        } else {
            tickRotation = 60
        }
    } else if (currentData.monthlySums && currentData.monthlySums.length > 0 && previousData.monthlySumsPrevious && previousData.monthlySumsPrevious.length > 0) {
        try {
            currentMonthlyDataList = currentData.monthlySums ? currentData.monthlySums.length > 0 ? currentData.monthlySums.map(
                ({ month, totalLiters, qualityScore }) => {
                    let convertDate = new Date(month);
                 
                    if (isTotal) {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange > 29 ? monthlyDateOptions : weeklySumOptions),
                            y: totalLiters ? totalLiters.toFixed(1) : 0,
                        };
                    } else {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange > 29 ? monthlyDateOptions : weeklySumOptions),
                            y: qualityScore ? qualityScore.toFixed(1) : 0,
                        };
                    }
                }
            ) : [] : [];
            previousMonthlyDataList = previousData.monthlySumsPrevious ? previousData.monthlySumsPrevious.length > 0 ? previousData.monthlySumsPrevious.map(
                ({ month, totalLiters, qualityScore }) => {
                    let convertDate = new Date(month);
                    convertDate.setDate(convertDate.getDate())
                    if (isTotal) {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange > 29 ? monthlyDateOptions : weeklySumOptions),
                            y: totalLiters ? totalLiters.toFixed(1) : 0,
                        };
                    } else {
                        return {
                            x: convertDate.toLocaleDateString('en-Ca', dateRange > 29 ? monthlyDateOptions : weeklySumOptions),
                            y: qualityScore ? qualityScore.toFixed(1) : 0,
                        };
                    }
                }
            ) : [] : [];
        } catch (error) {
            currentMonthlyDataList = [];
            previousMonthlyDataList = [];
        }

        if (dateRange < 8) {
            tickRotation = 0;
        } else {
            tickRotation = 60
        }
    }


    let data;
    let previousValues = "";
    let currentValues = "";
    if (currentData.dailySums && currentData.dailySums.length > 0) {
        previousValues = previousDailyDataList && previousDailyDataList.length > 0 ? previousDailyDataList.map((item) => item.y) : [];
        currentValues = currentDailyDataList && currentDailyDataList.length > 0 ? currentDailyDataList.map((item) => item.y) : [];
        data = [
            { id: "Previous Period", data: previousDailyDataList ? previousDailyDataList : [] },
            { id: "Current Period", data: currentDailyDataList ? currentDailyDataList : [] },
        ];
    } else if (currentData.weeklySums && currentData.weeklySums.length > 0) {
        previousValues = previousWeeklyDataList && previousWeeklyDataList.length > 0 ? previousWeeklyDataList.map((item) => item.y) : [];
        currentValues = currentWeeklyDataList && currentWeeklyDataList.length > 0 ? currentWeeklyDataList.map((item) => item.y) : [];
        data = [{ id: "Previous Period", data: previousWeeklyDataList ? previousWeeklyDataList : [] },
        { id: "Current Period", data: currentWeeklyDataList ? currentWeeklyDataList : [] },
        ];
    } else if (currentData.monthlySums && currentData.monthlySums.length > 0) {
        previousValues = previousMonthlyDataList && previousMonthlyDataList.length > 0 ? previousMonthlyDataList.map((item) => item.y) : [];
        currentValues = currentMonthlyDataList && currentMonthlyDataList.length > 0 ? currentMonthlyDataList.map((item) => item.y) : [];
        data = [{ id: "Previous Period", data: previousMonthlyDataList ? previousMonthlyDataList : [] },
        { id: "Current Period", data: currentMonthlyDataList ? currentMonthlyDataList : [] },
        ];
    } else {
        currentValues = [];
        previousValues = [];

        data = [{ 
            id: "Previous Period", data: [] 
        }, 
        { id: "Current Period", data: [] 
    }];
    }

    const values = currentValues.concat(previousValues);
    const maxValue = Math.max(...values);

    if (isLoaded) {
        return (
            <ResponsiveLine
                enableSlices="x"
                data={data}
                axisBottom={{
                    tickRotation: tickRotation,
                    tickSize: 1,
                    tickPadding: 10,
                }}
                axisLeft={{
                    format: n => isTotal ? Intl.NumberFormat().format(n) : n
                }}
                colors={["#B7B7B7", "#3B3B3B"]}
                margin={{ top: 5, right: 30, bottom: 40, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                    type: "linear",
                    min: "auto",
                    max: isTotal ? maxValue + 100 : maxValue === -1 || maxValue === 0 ? maxValue + 110 : maxValue + 2,
                    stacked: false,
                    reverse: false,
                }}
                useMesh={true}
                pointSize={5}
                pointColor="#ffffff"
                enableGridX={false}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                sliceTooltip={({ slice }) => (
                    <div
                        style={{
                            borderRadius: "10px",
                            boxShadow:
                                "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                        }}
                    >
                        {slice.points.map((point) => (
                            <div
                                key={Math.random() * Math.random()}
                                style={{
                                    backgroundColor: "white",
                                    color: point.serieColor,
                                    padding: "3px 10px",
                                }}
                            >
                                <strong>{point.serieId}:</strong>
                                <strong>{isTotal ? ` ${Intl.NumberFormat().format(point.data.yFormatted)}` : ` ${point.data.yFormatted}`}</strong>
                                <span>{isTotal ? " lt" : "%"}</span>
                            </div>
                        ))}
                    </div>
                )}
            />
        );
    }
    return <Skeleton animation="wave" variant="rectangular" height="100%" />;
};

export default TotalChartCustomDate;

import React from "react";
import { Box, Skeleton } from "@mui/material";
const TableSkeleton = ({ rowCount }) => {
  const skeletonArray = Array(rowCount).fill(Math.random());

  return (
    <div style={{ maxWidth: "100%" }}>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          <Skeleton
            variant="text"
            width="200px"
            height="50px"
            color="#e0e0e9"
          />
        </div>
        <div>
          <Skeleton variant="text" width="150px" height="50px" />
        </div>
      </Box>

      <br />
      {skeletonArray.map((skeleton) => (
        <Skeleton
          key={Math.random() * skeleton}
          variant="rectangular"
          width="100%"
          style={{ display: "block", margin: "10px 0" }}
          height="70px"
        />
      ))}
    </div>
  );
};

export default TableSkeleton;

import React from "react";
import { makeStyles } from "@mui/styles";
import SilverGlassImage from '../../assets/silver_glass.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
  imageStyle: {
    height: "25px",
    display: "inline",
    maxWidth: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "12px",
      padding: "0px 0",
      marginLeft: "8px",
    },
  },
  textStyle: {
    fontFamily: "RobotoCondensed",
    fontSize: "1em",
    marginLeft: "5px",
    fontStyle: "italic",
    color: "white",
  },
}));

const FooterLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        className={classes.imageStyle}
        alt="Pubinno logo"
        src={SilverGlassImage}
      />
      <span className={classes.textStyle}>The Future of Draft Beer</span>
    </div>
  );
};

export default FooterLogo;

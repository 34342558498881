import React from "react";

const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.283"
      height="15.932"
      viewBox="0 0 15.283 15.932"
    >
      <path
        id="Path_289"
        data-name="Path 289"
        d="M388.546,10.173l-7.041-6.8a.6.6,0,0,0-.834,0l-7.041,6.777a.6.6,0,0,0,.833.866l.126-.121v7.04a1.2,1.2,0,0,0,1.2,1.2h4.092V14.726a.08.08,0,0,1,.08-.08h2.246a.08.08,0,0,1,.08.08v4.413h4.092a1.2,1.2,0,0,0,1.2-1.2V10.916l.125.121a.6.6,0,0,0,.789.04h0A.6.6,0,0,0,388.546,10.173Zm-1.762,7.762a.4.4,0,0,1-.4.4h-3.289v-3.61a.884.884,0,0,0-.883-.883h-2.246a.884.884,0,0,0-.883.883v3.61h-3.289a.4.4,0,0,1-.4-.4V10.123l5.278-5.08a.6.6,0,0,1,.834,0l5.28,5.1Z"
        transform="translate(-373.447 -3.206)"
        fill="#9ca7b6"
      />
    </svg>
  );
};

export default HomeIcon;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Badge } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import NotificationIconRed from "./NotificationIconRed";
import PersonIcon from "@mui/icons-material/Person";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { UserAuthContext } from "../../lib/context/user/UserAuth";

const useStyles = makeStyles((theme) => ({
  headerBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangular": {
      transform: "scale(.8) translate(30%, -20%)",
      padding: "0 !important",
      fontSize: "10px",
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
      border: "2px solid #C43042",
    },
    [theme.breakpoints.down('md')]: {
      color: `${theme.palette.primary.main}`,
    },
  },
  headerIcons: {
    "& .MuiSvgIcon-root": {
      padding: "0 !important",
    },
    padding: 0,
    "&:hover": {
      cursor: "pointer",
    },
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      color: theme.palette.primary.main,
    },
  },
}));

const MobileHeaderMenu = ({
  isMobileMenuOpen,
  mobileMoreAnchorEl,
  handleMobileMenuClose,
  handleOpenModal,
  handleClick,
  handleUserMenu,
  mobileMenuId,
}) => {
  const classes = useStyles();
  const { notificationHelper } = React.useContext(UserAuthContext);

  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleOpenModal}>
        <MessageOutlinedIcon className={classes.headerBadge} />
        <p style={{ marginLeft: 8 }}> Send Feedback</p>
      </MenuItem>
      <MenuItem onClick={handleClick}>
        <Badge
          overlap="rectangular"
          badgeContent={
            notificationHelper?.notifications
              ? notificationHelper?.notifications?.length
              : 0
          }
          className={classes.headerBadge}
        >
          <NotificationIconRed className={classes.headerIcons} />
        </Badge>
        <p style={{ marginLeft: 8 }}>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleUserMenu}>
        <PersonIcon className={classes.headerBadge} />
        <p style={{ marginLeft: 8 }}>Profile</p>
      </MenuItem>
    </Menu>
  );
};

export default MobileHeaderMenu;

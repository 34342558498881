import { BrandsContext } from "./BrandsContext";
import React, { useState } from "react";
import axios from "axios";
import { BRANDS_URL, GET_BRANDS_BY_DISTRIBUTOR_ID_URL, GET_BEER_BRANDS_ID_NAME_LOGO_URL } from "../../urls/urls";
import { Exports, ExportTypes } from "../../../components/export/exports";

const BrandsProvider = ({ children }) => {
  const [brandsData, setBrandsData] = useState([]);
  const [beerBrand, setBeerBrand] = useState([]);

  const convertDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    const convertedData = yyyy + "-" + mm + "-" + dd;

    return convertedData;
  };

  const getBrandsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getBrandsData-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      setBrandsData(JSON.parse(await localStorage.getItem(storageKey)));
    } else {
      const QUERY_DATES = `?StartDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}&PageNumber=1&PageSize=11111`;
      let response = await axios.get(GET_BRANDS_BY_DISTRIBUTOR_ID_URL + QUERY_DATES, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      const { data } = response;
      setBrandsData(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getBrandsData-"))
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };

  const getBrandIdNameLogo = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getBrandIdNameLogo-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(await localStorage.getItem(storageKey)) ?? "";
      setBeerBrand(data);
    } else {
      const QUERY_DATES = `?StartDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}&PageNumber=1&PageSize=11111`;
      let response = await axios.get(GET_BEER_BRANDS_ID_NAME_LOGO_URL + QUERY_DATES, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      const { data } = response;
      setBeerBrand(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getBrandIdNameLogo-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  }


  const exportBrandsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, BRANDS_URL, ExportTypes.BrandsData)
  };
  return (
    <BrandsContext.Provider
      value={{
        brandsData: brandsData,
        getBrandsData: getBrandsData,
        exportBrandsData: exportBrandsData,
        getBrandIdNameLogo: getBrandIdNameLogo,
        beerBrand: beerBrand
      }}
    >
      {children}
    </BrandsContext.Provider>
  );
};

export default BrandsProvider;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { createTheme } from '@mui/material/styles';
import { Route, Switch, useLocation } from "react-router-dom";
import Drawer from "./components/drawer";
import Home from "./pages/home";
import Quality from "./pages/quality";
import SettingsPage from "./pages/settings";
import Login from "./components/login";
import ForgotPassword from "./components/forgot-password";
import RenewPassword from "./components/forgot-password/RenewPassword";
import ValidateResetCode from "./components/forgot-password/ValidateResetCode";
import { PrivateRoute } from "./lib/private-route/PrivateRoute";
import BrandsTable from "./pages/brands";
import LocationsTable from "./pages/locations";
import NotFound from "./pages/not-found";
import { I18nProvider } from "./i18n";
import "./App.css";
import { initGa, logPageView } from "./lib/google-analytics/GoogleAnalytics";

const theme = createTheme(({
  palette: {
    primary: {
      light: "#F8F8F8",
      main: "#C43042", // red
      dark: "#424242",
      contrastText: "#fff",
    },
    secondary: {
      main: "#707070", // grey
      contrastText: "#F7B346", // orange
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    menu: "RobotoMedium",
    h1: {
      fontSize: "40px",
    },

    h2: {
      fontFamily: "OswaldDemiBold",
      fontSize: "2.5em",
    },

    h3: {
      fontFamily: "Oswald",
      fontSize: "1.75em",
    },
    h6: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
    },
    subtitle1: {
      fontFamily: "RobotoBoldCondensed",
      fontSize: "1.15em",
    },
    body1: {
      fontFamily: "RobotoRegular",
      fontSize: "0.75rem",
    },
    body2: {
      fontFamily: "RobotoRegularItalic",
      fontSize: "0.75rem",
    },
  },
}));

function App() {
  const [language, setLanguage] = useState(
    navigator.language !== "tr-TR" ? "en-US" : navigator.language
  );
  
  const userLanguage = localStorage.getItem("userLanguage");
  var getUserDetail = JSON.parse(localStorage.getItem("userAuth"));
  initGa(getUserDetail?.user?.email.split("@")[0]);

  let location = useLocation();
  useEffect(() => {
    logPageView(location);
    let checkLanguage = JSON.parse(localStorage.getItem("userLanguage"));
    checkLanguage && setLanguage(checkLanguage);
  }, [location, userLanguage]);
  const [dateFilter, setDateFilter] = useState({ typ: 1, startDate: null, endDate: null })
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <I18nProvider locale={language ? language : "en-EN"}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ValidateResetCode} />
            <Route exact path="/go-to-reset-password/:slug" component={RenewPassword} />
            <PrivateRoute>
              <Drawer dateFilter={dateFilter} setDateFilter={setDateFilter}>
                <Route exact path="/locations/">
                  <LocationsTable dateFilter={dateFilter}></LocationsTable>
                </Route>
                <Route exact path="/locations-detail/:slug">
                  <LocationsTable dateFilter={dateFilter}></LocationsTable>
                </Route>
                <Route exact path={"/brands"}>
                  <BrandsTable dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Route>
                <Route exact path="/home">
                  <Home dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Route>
                <Route exact path="/quality">
                  <Quality dateFilter={dateFilter} setDateFilter={setDateFilter} />
                </Route>
                <Route exact path="/settings" component={SettingsPage} />
                <Route exact path="/not-found" component={NotFound} />
              </Drawer>
            </PrivateRoute>
          </Switch>
        </I18nProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
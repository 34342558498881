import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Settings from "../../components/settings/Settings";
import TimezonesProvider from "../../lib/context/timezones/TimezonesProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,

    [theme.breakpoints.down('lg')]: {
      maxWidth: "100%",
    },
  },
}));

const SettingsPage = ({ handleLanguage }) => {
  const classes = useStyles();

  return (
    <Paper elevation={5} className={classes.root}>
      <TimezonesProvider>
        <Settings handleLanguage={handleLanguage} />
      </TimezonesProvider>
    </Paper>
  );
};

export default SettingsPage;

import React from "react";

const MonthlyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.649"
      height="13.289"
      viewBox="0 0 15.649 13.289"
    >
      <path
        id="Path_323"
        data-name="Path 323"
        d="M1233.469,1249.672l-1.65-2.331h-1.153a2.145,2.145,0,0,1-2.143-2.143v-6.673a2.146,2.146,0,0,1,2.143-2.143h11.363a2.145,2.145,0,0,1,2.143,2.143v6.673a2.145,2.145,0,0,1-2.143,2.143h-6.91Zm-2.8-12.287a1.143,1.143,0,0,0-1.141,1.141v6.673a1.142,1.142,0,0,0,1.141,1.141h1.671l1.132,1.6,1.132-1.6h7.429a1.142,1.142,0,0,0,1.141-1.141v-6.673a1.143,1.143,0,0,0-1.141-1.141Z"
        transform="translate(-1228.523 -1236.383)"
        fill="#9ca7b6"
      />
    </svg>
  );
};

export default MonthlyIcon;

import React from "react";
import { makeStyles } from "@mui/styles";
import Slide from "react-reveal/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: '41.5px',
    "& p": {
      margin: ".06% 0",
      padding: "0",
      fontSize: 13,
      fontFamily: "RobotoRegular",
    },
  },
  linkedRoot: {
    width: "100%",
    height: '41px',
    "& p": {
      margin: ".06% 0",
      padding: "0",
      fontSize: 13,
      fontFamily: "RobotoRegular",
    },
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
      backgroundColor: "#F8F8F8",
      borderRadius: "5px",
      border: "1px solid #B5B5B5",
      transition: "transform .2s",
    },
  },
  alignItems: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    margin: "1px 0",
  },
  bold: {
    fontWeight: "bold",
    color: "black",
    textTransform: "capitalize",
  },
  smallText: {
    fontSize: "1.05em",
  },
  location: {
    color: "#A4A8B1",
  },
  brand: {
    color: "#A4A8B1",
    fontWeight: "bold",
  },
  percentageUp: {
    fontWeight: "bold",
    fontSize: "1em",
    color: "#46B85B",
  },
  percentageDown: {
    fontWeight: "bold",
    fontSize: "1em",
    color: theme.palette.primary.main,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const BestWorstListCard = ({
  shop,
  percentage,
  city,
  county,
  totalLiters,
  isHome,
  qualityScore,
  externalCode
}) => {
  const classes = useStyles();

  const handleChar = (text) => {
    return text;
  };


  if (isHome) {
    return (
      <div className={classes.linkedRoot}>
        <Slide right duration={300}>
          <div className={classes.alignItems}>
            <p className={`${classes.bold}`}>{handleChar(shop)}</p>
            <div>
              <p className={`${classes.bold}`}>
                {(isHome ? percentage ? (handleChar(percentage) * 100).toFixed(0) : 0 : handleChar(qualityScore) * 100)} %
              </p>
            </div>
          </div>

          <div className={classes.alignItems}>
            <p className={`${classes.location} ${classes.smallText}`}>
            SAP Code: {externalCode ? externalCode : "-"}
            </p>
            <p className={classes.brand}>{handleChar(totalLiters + " lt")}</p>
          </div>
          {/* </Link> */}
        </Slide>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Slide right duration={300}>
        <div className={classes.alignItems}>
          <p className={`${classes.bold}`}>{handleChar(shop)}</p>
          <div>
            <p className={`${classes.bold}`}>
              {isHome
                ? handleChar(percentage)
                : handleChar(Math.round(qualityScore))}
            </p>
          </div>
        </div>

        <div className={classes.alignItems}>
          <p className={`${classes.location} ${classes.smallText}`}>
          SAP Code: {externalCode ? externalCode : "-"}
          </p>
          <p className={classes.brand}>{handleChar(totalLiters + " lt")}</p>
        </div>
      </Slide>
    </div>
  );
};

export default BestWorstListCard;

import React, { forwardRef, useContext } from "react";
import { BrandsContext } from "../../lib/context/brands/BrandsContext";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import {
  makeStyles,
} from "@mui/styles";

import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import AddBox from "@mui/icons-material/AddBox";
import CodeIcon from "@mui/icons-material/Code";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { Typography } from "@mui/material";
import ExportIcon from "../table-locations/export-icon/ExportIcon";
import { upArrow, downArrow } from "../table-locations/Arrows";
import translate from "../../i18n/translate";
import TableSkeleton from "../table-locations/TableSkeleton";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import Tooltip from '@mui/material/Tooltip';

// Define icons where using in table
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <ExportIcon />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear style={{ display: "none" }} {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => (
    <Search style={{ textAlign: "right" }} {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref) => <CodeIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const cellStyle = {
  fontFamily: "Roboto",
  fontSize: "11px",
  color: "#043042",
};

const tableTitleText = (
  <Typography
    style={{ color: "#C43042", fontSize: "16px", fontWeight: "bold" }}
  >
    {translate("brands")}
  </Typography>
);

const tableStyle = makeStyles((theme) => ({
  table: {
    width: "100%",
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      width: "calc(100%)",
    },
  },
  sevenDays: {
    position: "absolute",
    left: "calc(100% - 300px)",
    top: "15px",
    width: "170px",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      left: "calc(100% - 250px)",
      width: "160px",
    },
  },
  search: {
    border: "1px solid #E3E7EE",
    height: "30px",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
  },
}));

const Index = ({ dateFilter }) => {
  const classes = tableStyle();
  const { getBrandsData, brandsData, getBrandIdNameLogo, beerBrand } = useContext(BrandsContext);
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      await getBrandsData(dateFilter);
      await getBrandIdNameLogo(dateFilter);
      eventLogView(
        "Brands Table",
        dateFilter.typ === 1 ? "7 Days Data Called"
          : dateFilter.typ === 1
            ? "30 Days Data Called"
            : "Custom Date Data Called");

      setIsLoaded(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);
  const columns = [
    {
      title: translate("brands"),
      field: "brandText",
      customSort: (a, b) => a.brandText.localeCompare(b.brandText),
      render: (rowData) => {
        return (
          <div style={{ textAlign: "center" }}>
            <img
              src={rowData.brandImg}
              alt="Brand Logo"
              style={{ width: "50px", height: "100%" }}
            />
            <p style={{ color: "#8492A5", fontWeight: "bold", fontSize: 14 }}>
              {rowData.brandText}
            </p>
          </div>
        );
      },

      headerStyle: {
        textAlign: "center",
        paddingLeft: "3%",
      },
    },
    {
      title: translate("bestSellingLocation"),
      field: "maxShopSearch",
      customSort: (a, b) => a.maxShopSearch.localeCompare(b.maxShopSearch),
      render: (rowData) => {
        return (
          <Tooltip title={"SAP:" + (rowData.bestExternalCode ? rowData.bestExternalCode : " ---")}>
            <div>
              <p style={{ fontFamily: "RobotoMedium", fontSize: 15 }}>
                {rowData.maxShop}
              </p>
              <p
                style={{
                  fontFamily: "RobotoRegular",
                  fontSize: 12,
                  color: "#8492A5",
                }}
              >
                {rowData.maxCounty} - {rowData.maxCity}
              </p>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: translate("leastSellingLocation"),
      field: "minShopSearch",
      customSort: (a, b) => a.minShopSearch.localeCompare(b.minShopSearch),
      render: (rowData) => {
        return (
          <Tooltip title={rowData.worstExternalCode ? "SAP:" + (rowData.worstExternalCode ? rowData.worstExternalCode : " ---") : ""} >
            <div>
              <p
                style={{
                  fontFamily: "RobotoMedium",
                  fontSize: 15,
                  textTransform: "capitalize",
                }}
              >
                {rowData.minShop}
              </p>
              <p
                style={{
                  fontFamily: "RobotoRegular",
                  fontSize: 12,
                  color: "#8492A5",
                }}
              >
                {rowData.minCounty} - {rowData.minCity}
              </p>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: translate("totalLiter"),
      field: "totalLiter",
      render: (rowData) => {
        return (
          <div>
            <p
              style={{
                fontFamily: "RobotoRegular",
                fontSize: 19,
                fontWeight: "bold",
              }}
            >
              {Intl.NumberFormat().format(rowData.totalLiter.toFixed(1))} lt
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  fontSize: 12,
                  fontWeight: "bold",
                  color: `${rowData.totalLiterPercent > 0 ? "#46B85B" : "#C43042"
                    }`,
                }}
              >
                <span style={{ marginLeft: "5%", marginRight: "1%" }}>
                  {rowData.totalLiterPercent > 0 ? upArrow : downArrow}
                </span>
                {rowData.totalLiterPercent * 100 >= 300
                  ? " <300"
                  : (rowData.totalLiterPercent * 100).toFixed(1)}{" "}
                %
              </span>
            </p>
          </div>
        );
      },
    },
    {
      title: translate("efficiency"),
      field: "efficiency",
      defaultSort: "desc",
      render: (rowData) => {
        return (
          <div>
            <p
              style={{
                fontFamily: "RobotoRegular",
                fontSize: 19,
                fontWeight: "bold",
              }}
            >
              {rowData.efficiency === "-" ? rowData.efficiency : rowData.efficiency + '%'}
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  fontSize: 12,
                  fontWeight: "bold",
                  color: `${rowData.efficiencyPercent > 0 ? "#46B85B" : "#C43042"
                    }`,
                }}
              >
                <span style={{ marginLeft: "5%", marginRight: "1%" }}>
                  {rowData.efficiencyPercent === "-" ? null : rowData.efficiencyPercent === 0 ? null : rowData.efficiencyPercent > 0 ? upArrow : downArrow}
                </span>
                {rowData.efficiencyPercent === "-" ? null : Math.abs(rowData.efficiencyPercent * 100) >= 300
                  ? " <300"
                  : (rowData.efficiencyPercent * 100).toFixed(1)}{rowData.efficiencyPercent === "-" ? null : " %"}
              </span>
            </p>
          </div>
        );
      },
    },
  ];

  const data = brandsData.map(
    ({
      brandId,
      brandLogo,
      brandName,
      totalLiters,
      totalLitersChange,
      efficiency,
      efficiencyChange,
    }) => {
      let bb = beerBrand ? beerBrand.find(bb => bb.brandId === brandId) : null;
      let bestLocation = bb && bb.bestLocation ? bb.bestLocation : null
      let worstLocation = bb && bb.worstLocation ? bb.worstLocation : null
      let payload = {
        brandText: brandName.trimStart(),
        brandImg: brandLogo,
        maxShop: bestLocation ? bestLocation.locationName.trimStart() : "-",
        bestExternalCode: bestLocation ? bestLocation.externalCode : "",
        worstExternalCode: worstLocation ? worstLocation.externalCode : "",
        maxShopSearch: `${bestLocation
          ? bestLocation.locationName +
          bestLocation.county +
          bestLocation.city
          : "-"
          }`,
        maxCounty: bestLocation ? bestLocation.county : "-",
        maxCity: bestLocation ? bestLocation.city : "-",
        minShop: worstLocation ? worstLocation.locationName : "-",
        minShopSearch: `${worstLocation
          ? worstLocation.locationName +
          worstLocation.county +
          worstLocation.city
          : "-"
          }`,
        minCounty: worstLocation ? worstLocation.county ? worstLocation.county.trimStart() : "-" : "-",
        minCity: worstLocation ? worstLocation.city ? worstLocation.city : "-" : "-",
        totalLiter: totalLiters,
        totalLiterPercent: totalLitersChange ? totalLitersChange.toFixed(2) : 0,
        efficiency: efficiency ? efficiency.toFixed(1) : "-",
        efficiencyPercent: efficiencyChange ? efficiencyChange.toFixed(2) : "-",
      };

      return payload;
    }
  );
  //Overriding table head adding custom component
  const components = {
    Toolbar: (props) => (
      <div>
        <MTableToolbar {...props} />
      </div>
    ),
  };

  // All options for material table component
  const options = {
    padding: "dense",
    searchFieldAlignment: "left",
    exportButton: false,
    searchFieldStyle: {
      border: "1px solid #E3E7EE",
      height: "30px",
      width: "200px",
    },
    headerStyle: {
      backgroundColor: "#F0F0F0",
      color: "#043042",
      textTransform: "Uppercase",
      fontSize: "12px",
    },
    thirdSortClick: false,
    paging: false,
    exportFileName: "Pubinno-Brands-CSV",
    // exportButton: false,
    detailPanelType: "single",
    cellStyle: cellStyle,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    rowStyle: {
      border: "2px solid #f0f0f0",
      fontFamily: "Roboto",
      "&:hover": {
        borderColor: "red !important",
      },
    },
  };

  const localization = {
    toolbar: { exportTitle: "" },
  };

  if (isLoaded) {
    return (
      <div className={classes.table}>
        <StyledEngineProvider injectFirst>
          <MaterialTable
            title={tableTitleText}
            options={options}
            columns={columns}
            data={data}
            localization={localization}
            icons={tableIcons}
            components={components}
          />
        </StyledEngineProvider>
      </div>
    );
  }
  return <TableSkeleton rowCount={9} />;
};

export default Index;

import { Route, Redirect } from "react-router-dom";
import React, { useContext } from "react";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
export const PrivateRoute = ({ children, ...other }) => {
  let { userAuth } = useContext(UserAuthContext);
  return (
    <Route
      {...other}
      render={(props) => {
        if (userAuth) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles, withStyles } from "@mui/styles";
import Flip from "react-reveal/Flip";
import { upArrow, downArrow } from "../../table-locations/Arrows";
const selectColor = (percent) => {
  if (percent <= 100 && percent > 80) {
    return "green";
  } else if (percent <= 80 && percent > 40) {
    return "orange";
  } else return "red";
};

const ByDayCard = ({ title, liters, percentage, type, isLoaded, isPercentageShow = true }) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 4,
      borderRadius: 2,
      width: "60%",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: selectColor(liters),
    },
  }))(LinearProgress);

  const useStyle = makeStyles((theme) => ({
    root: {
      "& p": {
        margin: "0",
        fontFamily: "RobotoRegular",
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: 20,
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: 14,
    },
    percent: {
      fontSize: 12,
      fontWeight: "bold",
      color: percentage > 0 ? "#46B85B" : theme.palette.primary.main,
      alignItems: "baseline",
    },
    alignData: {
      display: "flex",
      alignItems: "center",
    },
  }));

  const classes = useStyle();

  return (
    <Flip top>
      <div className={classes.root}>
        <p className={classes.title}>{title}</p>
        <div className={classes.alignData}>
          <p className={classes.title}>{`${liters} ${liters === "-" ? "" : type}`}</p>
          <p className={classes.percent}>
            <span style={{ marginLeft: "10px", marginRight: "5px" }}>
              {percentage === "-" ? null : percentage === 0 ? null : percentage <= 0 ? downArrow : upArrow}
            </span>
            {isPercentageShow ? percentage === "-" ? null : Math.abs(percentage) >= 300 ? " <300 %" : Math.abs(percentage).toFixed(2) + " %" : ""}
          </p>
        </div>

        {type === "%" && (
          <BorderLinearProgress
            style={{ marginTop: "0.6em" }}
            variant="determinate"
            value={parseInt(liters)}
          />
        )}
      </div>
    </Flip>
  );
};

export default ByDayCard;

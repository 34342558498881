import React, { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Paper, Skeleton } from "@mui/material";
import LastAddedLocationItem from "./location-card/LastAddedLocationItem";
import { CustomersContext } from "../../../lib/context/customers/CustomersContext";
import translate from "../../../i18n/translate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",

    [theme.breakpoints.down('lg')]: {
      width: "100%",
      height: "100%",
      margin: "20px 0 30px 0",
    },
    [theme.breakpoints.down('md')]: {
      margin: "20px 0 50px 0",
    },
  },
  list: {
    overflow: "auto",
    maxHeight: 235,
  },
  boxStyle: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  title: {
    fontSize: "1.3em",
    width: "70%",
    fontFamily: "RobotoRegular",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const LastAddedLocation = () => {
  const classes = useStyles();
  const { lastAddedLocations, getLastAddedLocations } = useContext(
    CustomersContext
  );
  const [isLoaded, setIsLoaded] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getLastAddedLocations();
      setIsLoaded(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <Paper className={classes.root} elevation={5}>
      <div className={classes.boxStyle}>
        <p className={classes.title}>{translate("lastAddedLocations")}</p>
      </div>
      <Divider />

      {isLoaded ? (
        <>
          {lastAddedLocations.map(
            ({ locationName, smartTapCount, county, createdDate, city, externalCode }, i) => (
              <div key={Math.random() + createdDate}>
              <LastAddedLocationItem
                shop={locationName}
                county={county}
                date={createdDate}
                tap={smartTapCount}
                city={city}
                externalCode={externalCode}
              />
              {lastAddedLocations.length === i + 1 ? null : <Divider />}
            </div>
            )
          )}
        </>
      ) : (
        <React.Fragment>
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={40}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={40}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={40}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={42}
          />
          <Skeleton
            style={{ margin: "5px 0" }}
            variant="rectangular"
            animation="wave"
            width="100%"
            height={45}
          />
        </React.Fragment>
      )}
    </Paper>
  );
};

export default LastAddedLocation;

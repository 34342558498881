import React from "react";

const KegIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.849"
      height="17.184"
      viewBox="0 0 13.849 17.184"
    >
      <g
        id="Group_171"
        data-name="Group 171"
        transform="translate(-3932.072 -2052.054)"
      >
        <path
          id="Path_312"
          data-name="Path 312"
          d="M3943.816,2069.238h-9.638a1.768,1.768,0,0,1-1.766-1.766v-1a.937.937,0,0,1,0-1.444v-3.2a.937.937,0,0,1,0-1.444v-3.2a.937.937,0,0,1,0-1.444v-2.866a.815.815,0,0,1,.814-.814h11.542a.815.815,0,0,1,.814.814v2.866a.937.937,0,0,1,0,1.444v3.2a.937.937,0,0,1,0,1.444v3.2a.937.937,0,0,1,0,1.444v1A1.768,1.768,0,0,1,3943.816,2069.238Zm-10.59-16.407a.037.037,0,0,0-.037.037v3.34l-.239.1a.16.16,0,0,0,0,.3l.239.1v4.151l-.239.1a.16.16,0,0,0,0,.3l.239.1v4.151l-.239.1a.16.16,0,0,0,0,.3l.239.1v1.473a.99.99,0,0,0,.989.989h9.638a.99.99,0,0,0,.989-.989V2066l.239-.1a.16.16,0,0,0,0-.3l-.239-.1v-4.151l.239-.1a.16.16,0,0,0,0-.3l-.239-.1V2056.7l.239-.1a.16.16,0,0,0,0-.3l-.239-.1v-3.34a.037.037,0,0,0-.037-.037Z"
          fill="#9ca7b6"
        />
        <path
          id="Path_313"
          data-name="Path 313"
          d="M4019.659,2086.148H4016.7a.793.793,0,0,1,0-1.586h2.958a.793.793,0,1,1,0,1.586Zm-2.958-1.2a.4.4,0,0,0,0,.809h2.958a.4.4,0,0,0,0-.809Z"
          transform="translate(-79.184 -30.704)"
          fill="#9ca7b6"
        />
      </g>
    </svg>
  );
};

export default KegIcon;

import React from "react";

const NotificationIconRed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.561"
      height="21.712"
      viewBox="0 0 17.561 21.712"
    >
      <g id="Group_171" data-name="Group 171" transform="translate(0.75 0.75)">
        <path
          id="Path_39"
          data-name="Path 39"
          d="M10,21.75a1.859,1.859,0,0,0,3.574,0"
          transform="translate(-3.754 -2.885)"
          fill="none"
          stroke="#c43042"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M12,2.771V.75"
          transform="translate(-3.969 -0.75)"
          fill="none"
          stroke="#c43042"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M11.031,3a6.715,6.715,0,0,1,6.692,6.738c0,6.33,1.338,7.411,1.338,7.411H3s1.338-1.721,1.338-7.411A6.715,6.715,0,0,1,11.031,3Z"
          transform="translate(-3 -0.979)"
          fill="rgba(255,255,255,0)"
          stroke="#c43042"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default NotificationIconRed;

import React, { useContext } from "react";
import PercentageCard from "./ByDayCard";
import { makeStyles } from "@mui/styles";
import OutletsInfoText from "./OutletsInfoText";
import ColorInfo from "./ColorInfo";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { InstallationsContext } from "../../../lib/context/installations/InstallationsContext";
import { Skeleton } from "@mui/material";
import translate from "../../../i18n/translate";
const OutletsInfoCard = ({ isLoaded }) => {
  const { width } = useWindowDimensions();
  const { installationData } = useContext(InstallationsContext);

  const handlePaddingLeft = (width) => {
    if (width > 1420 && width < 1570) {
      return 40;
    }
    if (width > 1340 && width < 1420) {
      return 35;
    }
    if (width > 1145 && width < 1340) {
      return 15;
    }
    if (width < 1145) {
      return 0;
    } else {
      return 80;
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "42%",
      paddingLeft: handlePaddingLeft(width),
      marginTop: !isLoaded ? 9 : 0,
      [theme.breakpoints.down('lg')]: {
        width: "50%",
      },
    },
    vertical: {
      width: "1px",
      height: "55px",
      backgroundColor: "#CFCDCD",
      position: "relative",
      right: 6,
    },
    alignBouncyData: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    alignBounceContainer: {
      marginTop: 15,
      width: "100%",
    },
  }));

  const classes = useStyles();

  if (isLoaded) {
    return (
      <div className={classes.root}>
        <div className={classes.alignBounceContainer}>
          <div className={classes.alignBouncyData}>
            <div>
              <PercentageCard
                title={translate("newLocations")}
                liters={installationData.newLocation}
                percentage={installationData.newLocationChange*100}
                type=""
                isLoaded={isLoaded}
              />
            </div>
            <div className={classes.vertical} />
            <div>
              <PercentageCard
                title={translate("newTaps")}
                liters={installationData.newTaps}
                percentage={installationData.newTapChange*100}
                type=""
                isLoaded={isLoaded}
              />
            </div>
          </div>
        </div>
        <OutletsInfoText
          locations={installationData.totalLocations}
          tabs={installationData.totalTaps}
          isLoaded={isLoaded}
        />

        <ColorInfo />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Skeleton
        style={{ margin: "10px 0" }}
        variant="rectangular"
        height="50px"
        animation="wave"
      />
      <Skeleton
        style={{ margin: "10px 0" }}
        variant="rectangular"
        height="50px"
        animation="wave"
      />
      <Skeleton
        style={{ margin: "10px 0" }}
        variant="rectangular"
        height="50px"
        animation="wave"
      />
    </div>
  );
};

export default OutletsInfoCard;

import {
  FEEDBACK_POST,
  AUTH_URL,
  USER_ME_URL,
  SETTINGS_URL,
} from "../../urls/urls";
import { UserAuthContext } from "./UserAuth";
import React, { useState } from "react";
import axios from "axios";
import { GaLogin } from "../../google-analytics/GoogleAnalytics";
import { useAuth0 } from "@auth0/auth0-react";

const UserProvider = ({ children }) => {
  const [userAuth, setUserAuth] = useState(false);
  const [user, setUser] = useState({
    username: "",
    logInStatus: false,
    token: "",
    id: "",
    accountType: "",
    timeZoneId: "",
    language: "",
    activeAccount: { accountLogo: "" },
  });
  const {
    logout
  } = useAuth0();

  const updatePassword = async (
    oldPassword,
    newPassword,
    repeatPassword,
    timeZone,
    language
  ) => {
    let localToken = localStorage.getItem("userToken");
    var passw = new RegExp("^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,20}$");
    if (!passw.test(newPassword)) {
      return 750;
    }
    const confirmPasswordStatus = newPassword === repeatPassword ? true : false;
    const checkOldNewPassword = newPassword !== oldPassword ? true : false;
    if (confirmPasswordStatus && checkOldNewPassword) {
      let body = {
        userId:user.id,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmNewPassword: repeatPassword,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localToken}`,
      };
      try {
        let response = await axios.post(SETTINGS_URL, body, {
          headers: headers,
        });
        setUser({
          ...user,
          timeZoneId: timeZone,
          language: language,
        });
        return response.status;
      } catch (error) { }
    } else if (confirmPasswordStatus && !checkOldNewPassword) {
      return 800;
    } else if (!confirmPasswordStatus && checkOldNewPassword) {
      return 900;
    }
  };

  const updateTimeOrLanguage = async (timeZone, language) => {
    let localToken = localStorage.getItem("userToken");

    let body = {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      timeZone: timeZone,
      language: language,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localToken}`,
    };
    try {
      await axios.patch(SETTINGS_URL, body, {
        headers: headers,
      });
      setUser({
        ...user,
        timeZoneId: timeZone,
        language: language,
      });
      localStorage.setItem("userLanguage", JSON.stringify(language));
      return 200;
    } catch (error) {
      console.error(error.message);
    }
  };

  const [loginInfo, setLoginInfo] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  // Getting token and keep tracking info on local storage
  const getLogIn = async () => {
    const body = {
      email: loginInfo.username,
      password: loginInfo.password,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.post(AUTH_URL, body, {
        headers: headers,
      });
      localStorage.setItem("email", JSON.stringify(response.data.data.email));
      localStorage.setItem("userToken", JSON.stringify(response.data.data.jwtToken));
      localStorage.setItem("userAuth", JSON.stringify(true));
      GaLogin(loginInfo.username.split("@")[0])
      setUserAuth(true);
    } catch (error) {
      return error.response.data.status;
    }
  };

  // User info getting from server when home page loaded.
  const getUser = async () => {
    let localToken = localStorage.getItem("userToken");
    var getUserDetail = JSON.parse(localStorage.getItem("userAuth"));
    axios
      .get(USER_ME_URL, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      })
      .then((res) => {
        setUser({
          logInStatus: getUserDetail?.isLogin,
          token: localToken,
          id: res.data.data.id,
          username: getUserDetail?.user?.firstName,
          language: res.data.data.language,
          accountType: findRole(res.data.data.activeAccount.accountType),
          timeZoneId: res.data.data.timeZoneId,
          activeAccount: {
            accountLogo: res.data.data.activeAccount.accountLogo,
          },
        });

        !localStorage.getItem("userLanguage") &&
          localStorage.setItem(
            "userLanguage",
            JSON.stringify(res.data.data.language)
          );
        !localStorage.getItem("timeZone") &&
          localStorage.setItem(
            "timeZone",
            JSON.stringify(res.data.data.timeZoneId)
          );
      })
      .catch((err) => { });
  };

  // User logging out and delete all info from local storage
  const getLogOut = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userAuth");
    localStorage.removeItem("userLanguage");
    localStorage.removeItem("timeZone");
    setLoginInfo({
      password: "",
      showPassword: false,
      username: "",
    });
    setUser({
      token: "",
      logInStatus: false,
      id: "",
      username: "",
      accountType: "",
    });
    setUserAuth(false);
    logout()
  };

  const findRole = (accountType) => {
    switch (accountType) {
      case 0:
        return "Admin";
      case 1:
        return "Distributor";
      case 2:
        return "Customer";

      default:
        break;
    }
  };

  const sendFeedback = async (message) => {
    let localToken = localStorage.getItem("userToken");
    const body = {
      message: message,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localToken}`,
    };

    try {
      let response = await axios.post(FEEDBACK_POST, body, {
        headers: headers,
      });
      return response.status;
    } catch (error) {
      return error.message;
    }
  };


  return (
    <UserAuthContext.Provider
      value={{
        user: user,
        loginHelper: [loginInfo, setLoginInfo],
        getUser: getUser,
        getLogIn: getLogIn,
        getLogOut: getLogOut,
        userAuth: userAuth,
        setUserAuth: setUserAuth,
        sendFeedback: sendFeedback,
        findRole: findRole,
        patchUser: setUser,
        updatePassword: updatePassword,
        updateTimeOrLanguage: updateTimeOrLanguage,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserProvider;

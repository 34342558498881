import React from "react";

const ApprovedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
    >
      <g id="Group_317" data-name="Group 317" transform="translate(-6835 -528)">
        <g
          id="Ellipse_76"
          data-name="Ellipse 76"
          transform="translate(6835 528)"
          fill="none"
          stroke="#c43042"
          stroke-width="1"
        >
          <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
          <circle cx="21.5" cy="21.5" r="21" fill="none" />
        </g>
        <path
          id="Path_285"
          data-name="Path 285"
          d="M6152.453,482.192l6.166,9.051,14.462-18.12"
          transform="translate(694 65.758)"
          fill="none"
          stroke="#c43042"
          stroke-width="3"
        />
      </g>
    </svg>
  );
};

export default ApprovedIcon;

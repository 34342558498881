import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import PubinnoLogo from '../../assets/redLogo_slogan.png'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 80px",
    [theme.breakpoints.down('lg')]: {
      padding: "80px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "20px",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.dark,
      fontStyle: "italic",
      fontSize: "1rem",
    },
    "& MuiInput-root": {
      "&:hover": {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  form: {
    width: "50%",
    margin: "auto 0",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
  zeroPadding: {
    padding: "2px",
  },
  marginFix: {
    width: "100%",
    marginTop: 60,
    "&:hover": {
      backgroundColor: "#ab2a39",
    },
  },
  logo: {
    marginBottom: 70,
    width: "130px",
    [theme.breakpoints.down('xl')]: {
      width: "65%",
    },
    [theme.breakpoints.down('lg')]: {
      width: "25%",
    },
  },
  text: {
    fontSize: "1em",
    fontStyle: "italic",
    marginTop: 20,
    cursor: "pointer",
  },
}));

const LoginInputCard = () => {
  const classes = useStyles();
  const {
    loginWithRedirect,
    isAuthenticated,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const login = () => {
    getAccessTokenSilently({ ignoreCache: true })
      .then((response) => {
        if (user) {
          localStorage.setItem("email", user.email);
          localStorage.setItem(
            "userAuth",
            JSON.stringify({
              isLogin: true,
              token: response,
              user: {
                email: user.email,
                firstName: user.nickname,
                id: user.sub,
                isVerified: user.email_verified,
                jwtToken: response,
                lastName: "",
                phoneNumber: "",
                roles: user["https://schemas.perfect.beer/roles"],
                userSettings: [],
              },
              loading: false,
            })
          );
          localStorage.setItem("userToken", response);
          window.location.href = "/home";
        }
      })
      .catch((e) => {
        loginWithRedirect();
      });
  };
  function clickDiv(el) {
    document.getElementById(el.id).click();
  }
  
  return (
    <div className={classes.form}>
      <div className={classes.root}>
        <Box margin="0 auto 30px auto" className={classes.logo}>
          <img
            width="100%"
            src={PubinnoLogo}
            alt="pubinno red logo"
          />
        </Box>
        {!isAuthenticated ? (
          <Button
            className={classes.marginFix}
            variant="contained"
            color="primary"
            onClick={() => login()}
          >
            Log in
          </Button>
        ) : (
          <Button
            className={classes.marginFix}
            variant="contained"
            color="primary"
            id="auth0LoginButton"
            ref={clickDiv}
            onClick={() => login()}

          >
            Log in
          </Button>
        )}
      </div>
    </div>
  );
};

export default LoginInputCard;

import { CustomersContext } from "./CustomersContext";
import React, { useState } from "react";
import axios from "axios";
import { CUSTOMERS_URL, BESTWORST_URL, BESTWORST_LOCATIONS_URL, GET_LATEST_CUSTOMERS_OF_DISTRIBUTOR, LOCATIONS_URL } from "../../urls/urls";
import { Exports, convertDate, ExportTypes } from "../../../components/export/exports"

const CustomersProvider = ({ children }) => {
  const [lastAddedLocations, setLastAddedLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [bestWorstData, setBestWorstData] = useState({
    id: null,
    name: null,
    totalLiters: null,
    efficiency: null,
  });
  const [qualityLocations, setQualityLocations] = useState({
    id: null,
    name: null,
    city: null,
    county: null,
    creationDate: null,
    tapCount: null,
    totalLiters: null,
    efficiency: null,
    externalCode: null,
  });

  const getEfficiencyData = async (isBestWorst, dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getEfficiencyData-" + email + "-" + isBestWorst + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setBestWorstData(data);
    } else {
      const sort = isBestWorst ? 2 : 1;

      const QUERY_SORT = `?startDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}&limit=10&order=${sort}`;

      const response = await axios.get(BESTWORST_URL + QUERY_SORT, {
        headers: { authorization: `Bearer ${localToken}` },
      });

      const { data } = response;
      setBestWorstData(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getEfficiencyData-" + isBestWorst + "-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };

  const getQualityLocationsData = async (isBestWorst, dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);
    const email = localStorage.getItem("email");
    const storageKey = "getQualityLocationsData-" + email + "-" + isBestWorst + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setQualityLocations(data);
    } else {
      // const MAX_SHOPS = 5;
      const sort = isBestWorst ? 2 : 1;
      const QUERY_SORT = `?startDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}&limit=10&order=${sort}`;
      const response = await axios.get(BESTWORST_LOCATIONS_URL + QUERY_SORT, {
        headers: { authorization: `Bearer ${localToken}` },
      });
      const { data } = response;
      setQualityLocations(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getQualityLocationsData-" + isBestWorst + "-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };

  const getLastAddedLocations = async () => {
    var today = convertDate(new Date());
    const email = localStorage.getItem("email");
    const storageKey = "getLastAddedLocations-" + email + "-" + today;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setLastAddedLocations(data);
    } else {
      const localToken = localStorage.getItem("userToken");

      const LAST_ADDED_SHOPS = 5;

      const QUERY_SORT = `?Limit=${LAST_ADDED_SHOPS}`;

      const response = await axios.get(GET_LATEST_CUSTOMERS_OF_DISTRIBUTOR + QUERY_SORT, {
        headers: { authorization: `Bearer ${localToken}` },
      });

      const { data } = response;

      setLastAddedLocations(data.data); const oldKey = Object.keys(localStorage).find(i => i.includes("getLastAddedLocations-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };

  const getLocations = async (isBestWorst, dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getLocations-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(await localStorage.getItem(storageKey)) ?? "";
      setLocations(data);
    } else {

      const sort = isBestWorst ? "desc" : "asc"
      const QUERY_SORT = `?startDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}&limit=11111&order=${sort}`;
      const response = await axios.get(LOCATIONS_URL + QUERY_SORT, {
        headers: { authorization: `Bearer ${localToken}` },
      });

      const { data } = response;
      setLocations(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getLocations-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };


  const exportLocations = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, LOCATIONS_URL, ExportTypes.Locations, "", "")
  };
  const exportWidgetLastAddedLocationsData = async () => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, "", CUSTOMERS_URL, ExportTypes.LastAddedLocationsData, "asc", 5)
  };

  const exportWidgetEfficiencysData = async () => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, "", BESTWORST_URL, ExportTypes.EfficiencysData, 1, 10)
  };

  const exportWidgetWorstEfficiencysData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, BESTWORST_URL, ExportTypes.WorstEfficiencysData, 2, 10)
  };

  const exportQualityLocationsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, BESTWORST_LOCATIONS_URL, ExportTypes.QualityLocationsData, 2, 10)
  };

  const exportWorstQualityLocationsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, BESTWORST_LOCATIONS_URL, ExportTypes.WorstQualityLocationsData, 1, 10)
  };

  return (
    <CustomersContext.Provider
      value={{
        bestWorstData: bestWorstData,
        getEfficiencyData: getEfficiencyData,
        lastAddedLocations: lastAddedLocations,
        getLastAddedLocations: getLastAddedLocations,
        locations: locations,
        getLocations: getLocations,
        exportLocations: exportLocations,
        qualityLocations: qualityLocations,
        getQualityLocationsData: getQualityLocationsData,
        exportWidgetLastAddedLocationsData: exportWidgetLastAddedLocationsData,
        exportWidgetEfficiencysData: exportWidgetEfficiencysData,
        exportQualityLocationsData: exportQualityLocationsData,
        exportWidgetWorstEfficiencysData: exportWidgetWorstEfficiencysData,
        exportWorstQualityLocationsData: exportWorstQualityLocationsData
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};

export default CustomersProvider;

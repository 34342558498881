import { SmartTapsContext } from "./SmartTapsContext";
import React, { useState } from "react";
import axios from "axios";
import { SMART_TAPS_URL, TAPS_URL } from "../../urls/urls";
import { Exports, convertDate,ExportTypes } from "../../../components/export/exports";

const SmartTapsProvider = ({ children }) => {
  const [smartTapsData, setSmartTapsData] = useState([]);

  const getSmartTapsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);


    const QUERY_DATES = `?StartAt=${convertStartAt}&EndAt=${convertedEndAt+ "T23:59:59"}`;

    const response = await axios.get(SMART_TAPS_URL + QUERY_DATES, {
      headers: { authorization: `Bearer ${localToken}` },
    });

    const { data } = response;
    setSmartTapsData(data.data);
  };

  const exportSmartTapsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, TAPS_URL, ExportTypes.SmartTapsData)
  };

  return (
    <SmartTapsContext.Provider
      value={{
        smartTapsData: smartTapsData,
        getSmartTapsData: getSmartTapsData,
        exportSmartTapsData: exportSmartTapsData
      }}
    >
      {children}
    </SmartTapsContext.Provider>
  );
};

export default SmartTapsProvider;

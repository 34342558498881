import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {TextField, Button, Alert} from "@mui/material";
import translate from "../../i18n/translate";
import { strengthIndicator, strengthColor } from "../forgot-password/Strenght";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 40px 30px ",
    width: "100%",
    "& p": {
      margin: "0",
      fontFamily: "RobotoRegular",
      fontSize: 16,
      fontWeight: "bold",
      color: "#CA3756",
    },
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      height: "100%",
    },
  },
  align: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      height: "100%",
      display: "block",
      margin: "0",
    },
  },
  marginFix: {
    width: 280,
    "&:hover": {
      backgroundColor: "#ab2a39",
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
  },
  text: {
    paddingBottom: 20,
  },
  passwordAlign: {
    marginBottom: 20,
    width: 280,
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
  widthFixOnMobile: {
    width: "40%",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
}));

const RenewPassword = ({
  handleSubmit,
  isPressed,
  passwordData,
  setPasswordData,
  updatedMessage,
}) => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = React.useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setPasswordData({ ...passwordData, [name]: newValue });
  };

  const strength = strengthIndicator(passwordData.newPassword);
  const color = strengthColor(strength);

  const handlePasswordExist = () => {
    if (
      passwordData.newPassword.length !== 0 &&
      passwordData.oldPassword.length !== 0 &&
      passwordData.confirmPassword.length !== 0
    ) {
      setIsDisabled(false);
    } else if (
      passwordData.newPassword.length === 0 &&
      passwordData.oldPassword.length === 0 &&
      passwordData.confirmPassword.length === 0
    ) {
      setIsDisabled(isPressed);
    } else if (passwordData.confirmPassword.length !== 0) {
      setIsDisabled(true);
    } else if (passwordData.oldPassword.length !== 0) {
      setIsDisabled(true);
    } else if (passwordData.newPassword.length !== 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };
  
  useEffect(() => {
    handlePasswordExist();
    // eslint-disable-next-line
  }, [
    passwordData.newPassword,
    passwordData.confirmPassword,
    passwordData.oldPassword,
    isPressed,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.align}>
        <div>
          <p className={classes.text}>{translate("password")}</p>

          <div style={{ display: "block" }}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div>
                <TextField
                  value={passwordData.oldPassword}
                  className={classes.passwordAlign}
                  type="text"
                  name="oldPassword"
                  label="Old Password"
                  variant="standard"
                  onChange={handleChange}
                  required={
                    passwordData.newPassword ||
                    passwordData.confirmPassword ||
                    passwordData.oldPassword
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <TextField
                  value={passwordData.newPassword}
                  className={classes.passwordAlign}
                  type="text"
                  name="newPassword"
                  label="New Password"
                  variant="standard"
                  onChange={handleChange}
                  required={
                    passwordData.newPassword ||
                    passwordData.confirmPassword ||
                    passwordData.oldPassword
                      ? true
                      : false
                  }
                />
              </div>

              <div>
                <TextField
                  value={passwordData.confirmPassword}
                  className={classes.passwordAlign}
                  type="text"
                  name="confirmPassword"
                  variant="standard"
                  label="New Password (Repeat)"
                  onChange={handleChange}
                  required={
                    passwordData.newPassword ||
                    passwordData.confirmPassword ||
                    passwordData.oldPassword
                      ? true
                      : false
                  }
                />
              </div>
              {passwordData.newPassword.length !== 0 && (
                <div
                  style={{
                    marginBottom: 5,
                    height: "5px",
                    width: color.width,
                    backgroundColor: color.color,
                  }}
                />
              )}
              {updatedMessage.show && (
                <div style={{ width: "max-content", margin: "1%" }}>
                  <Alert
                    severity={updatedMessage.success ? "success" : "error"}
                  >
                    {updatedMessage.message}
                  </Alert>
                </div>
              )}
              <Button
                className={classes.marginFix}
                variant="contained"
                color="primary"
                type="submit"
                title="Giriş"
                disabled={isDisabled}
              >
                {translate("submit")}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenewPassword;

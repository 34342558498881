import React from "react";
import { makeStyles } from "@mui/styles";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& p": {
      margin: "0",
      fontSize: 13,
      fontFamily: "RobotoRegular",
      transition: "transform .5s ease",
    },
  },
  alignItems: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    margin: "3.4px 0",
  },
  bold: {
    fontWeight: "bold",
  },
  location: {
    color: "#A4A8B1",
  },
  brand: {
    fontSize: "10px",
    color: "#A4A8B1",
  },
  brandRed: {
    fontSize: "10px",
    color: "#C43042",
  },
  brandGreen: {
    fontSize: "10px",
    color: "#46B85B",
  },
}));

const LastAddedLocationItem = ({ data, isWarmBeer, type }) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

  function dayDifference(date1, date2) {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / MILLISECONDS_PER_DAY);
    return diffDays >= 30 ? "30" : diffDays;
  }

  const localeDate = new Date(); //May 28 2019
  const previousDate = new Date(data.maintenanceDate);
  
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const handleLength = (text, length) => {
    if (text.length > length) return "...";
    return "";
  };

  const handleChar = (text) => {
    if (width < 1360 && width > 1278) {
      return text.slice(0, 18) + handleLength(text, 18);
    } else {
      return text;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.alignItems}>
        <p className={`${classes.bold}`}>
          {type === "maintenance"
            ? handleChar(data.locationName + " - " + data.tapName) : handleChar(data.locationName + " - " + data.virtualTapName)}
        </p>
        <div>
          <p className={`${classes.bold}`}>
            {type === "maintenance"
              ? `More than ${dayDifference(localeDate, previousDate) > 29 ? "+" + dayDifference(localeDate, previousDate) : dayDifference(localeDate, previousDate) } days`
              : type === "worstKeg"
                ? `${data.kegLifeInDays >= 7 ? "+7" : data.kegLifeInDays} days`
                : `${data.temperatureAvg >= 9 ? "+9.0" : Number(data.temperatureAvg).toFixed(1)}` +
                " " +
                String.fromCharCode(176) +
                "C"}
          </p>
        </div>
      </div>

      <div className={classes.alignItems}>
        <p className={`${classes.location}`}>
         SAP Code: {data.externalCode ? data.externalCode : "-"}
        </p>
        <p
          className={
            !isWarmBeer
              ? classes.brand
              : data.temperatureAvgPrevious !== -99 &&
                data.temperatureAvg - data.temperatureAvgPrevious < 0
                ? classes.brandGreen
                : classes.brandRed
          }
        >
          {type === "maintenance"
            ? previousDate.toLocaleDateString('en-Ca', dateOptions)
            : type === "warmestBeer"
              ? `${data.temperatureAvgPrevious !== -99
                ? Number(data.temperatureAvg - data.temperatureAvgPrevious).toFixed(
                  1
                ) +
                " " +
                String.fromCharCode(176) +
                "C"
                : "-"
              }`
              : `${Number(data.totalLiters).toFixed(1)} lt`}
        </p>
      </div>
    </div>
  );
};

export default LastAddedLocationItem;

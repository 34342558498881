import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import "moment/min/locales";
import Tooltip from "@mui/material/Tooltip";
import DateNextIcon from '../../../assets/dateNextIcon.png'
import DateDoubleNextIcon from '../../../assets/dateDoubleNextIcon.png'
import DateDoublePrevIcon from '../../../assets/dateDoublePrevIcon.png'
import DatePrevIcon from '../../../assets/datePrevIcon.png'
import ExpandArrow from '../../../assets/expandArrow.png'


const useStyles = makeStyles(() => ({
  roots: {
    height: 35,
    flexDirection: "row",
    display: "flex",
    textAlign: "center",
    width: 268,
  },
  nextIconsRoot: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
  },
  prevIconsRoot: {
    flexDirection: "row",
    display: "flex",
  },
  nextIcon: {
    height: 30,
    width: 30,
    marginTop: 19,
  },
  prevIcon: {
    height: 30,
    width: 30,
    marginTop: 19,
  },
  yearText: {
    marginLeft: 10,
    fontFamily: "RobotoRegular",
    color: "#303447",
  },
  monthText: {
    fontFamily: "RobotoRegular",
    color: "#303447",
  },
}));
const DatePicker = ({ setDateFilter }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  let m, onMS;
  let startText;
  let endText;
  let dateFormat;

  if (localStorage.getItem("userLanguage") === '"tr-TR"') {
    moment.locale("tr-Tr");
    startText = "Başlama Tarihi";
    endText = "Bitiş Tarihi";
    dateFormat = "Tarih Formatı : dd.MM.yyyy";
  } else {
    moment.locale("en-CA");
    dateFormat = "Date Format : yyyy-MM-dd";
  }
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    
    if (endDate !== null && startDate !== null) {
      setDateFilter({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        typ: 3,
      });
    }
  };
  
  const renderMonthElementt = ({ month, onMonthSelect }) => {
    m = month;
    onMS = onMonthSelect;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={classes.monthText}>
          {" "}
          {moment(month).format("MMMM")}{" "}
        </div>
        <div className={classes.yearText}>{month.year()} </div>
      </div>
    );
  };

  const renderNextIcons = ({ onClick }) => (
    <div className={classes.nextIconsRoot}>
      <div onClick={onClick} style={{ marginRight: 10 }}>
        <img
          className={classes.nextIcon}
          alt=" "
          src={DateNextIcon}
        ></img>
      </div>
      <div
        onClick={() => {
          onMS(m, m.month() + 12);
        }}
        style={{ marginRight: 20 }}
      >
        <img
          className={classes.nextIcon}
          alt=" "
          src={DateDoubleNextIcon}
        ></img>
      </div>
    </div>
  );
  const renderPrevIcons = ({ onClick }) => (
    <div className={classes.prevIconsRoot}>
      <div
        onClick={() => {
          onMS(m, m.month() - 12);
        }}
        style={{ marginLeft: 20 }}
      >
        <img
          className={classes.prevIcon}
          alt=" "
          src={DateDoublePrevIcon}
        ></img>
      </div>
      <div onClick={onClick} style={{ marginLeft: 10 }}>
        <img
          className={classes.prevIcon}
          alt=" "
          src={DatePrevIcon}
        ></img>
      </div>
    </div>
  );

  return (
    <div
      className={classes.roots}
      onClick={() => {
        setStartDate(null);
        setEndDate(null);
      }}
    >
      <DateRangePicker
        key="das"
        //  ref={(c) => setFocusedMonth(c)}
        endDateId={"endDateId"}
        startDateId={"startDateId"}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        isOutsideRange={() => false}
        small
        anchorDirection={"right"}
        hideKeyboardShortcutsPanel
        inputIconPosition={"after"}
        startDatePlaceholderText={startText}
        endDatePlaceholderText={endText}
        customInputIcon={
          <Tooltip title={dateFormat}>
            <img
              className={"dateIcon"}
              alt=" "
              src={ExpandArrow}
            ></img>
          </Tooltip>
        }
        renderNavNextButton={renderNextIcons}
        renderNavPrevButton={renderPrevIcons}
        renderMonthElement={renderMonthElementt}
        minimumNights={0}
      />
    </div>
  );
};

export default DatePicker;
import { InstallationsContext } from "./InstallationsContext";
import React, { useState } from "react";
import axios from "axios";
import { INSTALLATION_BASE_URL, INSTALLATION_SALES_URL } from "../../urls/urls";
import { Exports, convertDate, ExportTypes } from "../../../components/export/exports";
const InstallationsProviders = ({ children }) => {
  const [installationData, setInstallationData] = useState({
    days: [],
    week: [],
    month: [],
    totalLocations: null,
    totalTaps: null,
    newLocations: null,
    newTaps: null,
    newLocationsPrevious: null,
    newTapsPrevious: null,
    newLocationChange: null,
  });

  const getInstallationsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getInstallationsData-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setInstallationData({
        days: data.data.days,
        week: data.data.week,
        month: data.data.month,
        totalLocations: data.data.totalLocations,
        totalTaps: data.data.totalTaps,
        newLocation: data.data.newLocation,
        newTaps: data.data.newTaps,
        newTapsPrevious: data.data.newTapsPrevious,
        newLocationChange: data.data.newLocationChange,
        newTapChange: data.data.newTapChange,
      });
    } else {
      const QUERY_DATES = `?StartDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}`;
      const GET_URL = INSTALLATION_SALES_URL + QUERY_DATES;

      let res = await axios.get(GET_URL, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setInstallationData({
        days: res.data.data.days,
        week: res.data.data.week,
        month: res.data.data.month,
        totalLocations: res.data.data.totalLocations,
        totalTaps: res.data.data.totalTaps,
        newLocation: res.data.data.newLocation,
        newTaps: res.data.data.newTaps,
        newTapsPrevious: res.data.data.newTapsPrevious,
        newLocationChange: res.data.data.newLocationChange,
        newTapChange: res.data.data.newTapChange,
      });
      const oldKey = Object.keys(localStorage).find(i => i.includes("getInstallationsData-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(res.data));
    }
  };
  const exportWidgetInstallationsData = async (dateFilter) => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, dateFilter, INSTALLATION_BASE_URL, ExportTypes.WidgetInstallationsData)
  };


  return (
    <InstallationsContext.Provider
      value={{
        installationData: installationData,
        getInstallationsData: getInstallationsData,
        exportWidgetInstallationsData: exportWidgetInstallationsData
      }}
    >
      {children}
    </InstallationsContext.Provider>
  );
};

export default InstallationsProviders;

import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import ByDayCard from "./ByDayCard";
import { Skeleton } from "@mui/material";
import translate from "../../../i18n/translate";

const LastDaysList = ({ currentData, isLoaded, isTotal }) => {
  
  return (
    <div style={{ marginTop: "3em" }}>
      <Grid container spacing={3}>
        {isLoaded ? (
          <>
            <Grid item xs={12}>
              <ByDayCard
                title={
                  isTotal
                    ? translate("totalSalesScore")
                    : translate("qualityScore")
                }
                liters={
                  isTotal
                    ? Intl.NumberFormat().format(currentData.totalLiters.toFixed(1))
                    : Math.round(currentData.qualityScore).toFixed(1)
                }
                percentage={
                  isTotal
                    ? currentData.totalLiters === 0 && currentData.totalLitersPrevious === 0 ? 0 : currentData.totalLitersChange * 100
                    : currentData.qualityScoreChange.toFixed(2) * 100
                }
                type={
                  isTotal ? (currentData.totalLiters > 100 ? "lt" : "lt") : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ByDayCard
                title={
                  isTotal
                    ? translate("efficiency")
                    : translate("averageBeerTemperature")
                }
                liters={
                  isTotal
                  ? currentData.totalLiters === 0 ? "-" : (Number(currentData.efficiency * 100)).toFixed(1)
                  : currentData.totalLiters === 0 ? "-" : (Number(currentData.temperatureAvg).toFixed(1))
                }
                percentage={
                  isTotal
                    ? currentData.totalLiters === 0 ? "-" : currentData.efficiencyChange ? (currentData.efficiencyChange * 100).toFixed(2) : 0
                    : currentData.totalLiters === 0 ? "-" : currentData.temperatureAvgChange ? (currentData.temperatureAvgChange * 100).toFixed(2) : 0
                }
                isPercentageShow={isTotal ? true : false}
                type={
                  isTotal
                    ? currentData.efficiencyChange > 100
                      ? "%" 
                      : "%"
                    : String.fromCharCode(176) + "C"
                }
              />
            </Grid>
            {isTotal && (
              <Grid item xs={12}>
                <ByDayCard
                  title={translate("qualityScore")}
                  liters={currentData.totalLiters === 0 ? "-" : currentData.qualityScore.toFixed(1)}
                  percentage={currentData.totalLiters === 0 ? "-" : (currentData.qualityScoreChange * 100).toFixed(0)}
                  type={""}
                />
              </Grid>
            )}
          </>
        ) : (
          <React.Fragment>
            <Skeleton
              style={{ margin: "10px 0 10px 12px" }}
              variant="rectangular"
              width="93%"
              height="50px"
            />
            <Skeleton
              style={{ margin: "10px 0 10px 12px" }}
              variant="rectangular"
              width="93%"
              height="50px"
            />
            <Skeleton
              style={{ margin: "10px 0 10px 12px" }}
              variant="rectangular"
              width="93%"
              height="50px"
            />
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

export default LastDaysList;

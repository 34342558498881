import React, { forwardRef, useState, useContext } from "react";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import {
  makeStyles,
} from "@mui/styles";
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import AddBox from "@mui/icons-material/AddBox";
import CodeIcon from "@mui/icons-material/Code";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import TableRow from "./TableRow";
import { Typography, Button } from "@mui/material";
import ExportIcon from "./export-icon/ExportIcon";
import translate from "../../i18n/translate";
import { CustomersContext } from "../../lib/context/customers/CustomersContext";
import TableSkeleton from "./TableSkeleton";
import { useParams, Link } from "react-router-dom";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import Tooltip from '@mui/material/Tooltip';
import { dateFormatForSort } from "../../lib/helpers/date-helper";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <ExportIcon />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear style={{ display: "none" }} {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <CodeIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const tableHeader = (
  <Typography
    style={{ color: "#C43042", fontSize: "16px", fontWeight: "bold" }}
  >
    {translate("locations")}
  </Typography>
);

const tableStyle = makeStyles((theme) => ({
  table: {
    width: "100%",
    marginTop: 10,
    "& p": {
      fontSize: "13px",
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100%)",
    },
  },
  allLocationStyle: {
    color: theme.palette.text.primary,
  },
}));

const Index = ({ dateFilter }) => {
  const classes = tableStyle();
  const [selectedRow, setSelectedRow] = useState(null);
  // eslint-disable-next-line
  const [bestWorst, setBestWorst] = React.useState(true);
  const { getLocations, locations } = useContext(CustomersContext);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const { slug } = useParams();
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      await getLocations(bestWorst, dateFilter);
      eventLogView(
        "Location Table",
        dateFilter.typ === 1
          ? "7 Days Data Called"
          : dateFilter.typ === 2
            ? "30 Days Data Called"
            : "Custom Date Data Called");
      setIsLoaded(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);

  const components = {
    Toolbar: (props) => (
      <div>
        <div
          style={{
            position: "absolute",
            left: "calc(100% - 300px)",
            top: "15px",
            width: "250px",
            zIndex: "1",
            display: "flex",
            justifyContent: "space-around"
          }}
        >
        </div>
        <div>
          <MTableToolbar {...props} />
        </div>
      </div>
    ),
  };

  const row = locations.map((item, index) => {
    let payload = {
      id: item.id,
      customId: index + 1,
      location: item.name.trimStart(),
      locationSearch: item.name,
      segment: item.county,
      segmentSearch: item.county,
      city: item.city,
      citySearch: item.city,
      tabs: item.tapCount,
      totalSales: Number(item.totalLiters).toFixed(1),
      totalSalesSearch: item.totalLiters,
      efficiency: Number(item.efficiency).toFixed(2),
      qualityScore: Number(item.qualityScore).toFixed(1),
      creationDate: new Date(item.creationDate).toLocaleDateString(),
      externalCode: item.externalCode,
      region: item.region,
      subRegion: item.subRegion
    };
    return payload;
  });

  const columns = [
    {
      title: "Index",
      field: "customId",
      filtering: false,
      render: (rowData) => {
        return <p>{rowData.customId}</p>;
      },
    },
    {
      title: "Location Name",
      field: "location",
      filtering: false,
      customSort: (a, b) => a.location.localeCompare(b.location),
      render: (rowData) => {
        return (
          <Tooltip title={"SAP:" + (rowData.externalCode ? rowData.externalCode : " ---")}>
            <p style={{ fontWeight: "bold", color: "#68838E", fontSize: "14px" }}>
              {rowData.location}
            </p>
          </Tooltip>
        );
      },
      defaultFilter: slug === undefined ? "" : slug,
    },
    {
      title: "Total Sales",
      field: "totalSalesSearch",
      filtering: false,
      // Render custom design cell in row
      render: (rowData) => {
        return (
          <p>
            {Intl.NumberFormat().format(rowData.totalSales)}
            <span> lt</span>
          </p>
        );
      },
      defaultSort: "desc",
    },
    {
      title: "Efficiency Score",
      field: "efficiency",
      filtering: false,
      render: (rowData) => {
        return (
          <p>
             {(rowData.efficiency * 100) >= 100 ? (rowData.efficiency * 100) : (rowData.efficiency * 100)}
            <span> %</span>
          </p>
        );
      },
    },
    {
      title: "Quality Score",
      field: "qualityScore",
      filtering: false,
      render: (rowData) => {
        return (
          <p>
            {rowData.qualityScore === "-1" ? "-" : <>{Number(rowData.qualityScore).toFixed(1)}</>}
          </p>
        );
      },
    },
    {
      title: "Smart Taps",
      field: "tabs",
      filtering: false,
      render: (rowData) => {
        return <p>{rowData.tabs}</p>;
      },
    },
    {
      title: "Creation Date",
      customSort: (a, b) => dateFormatForSort(a.creationDate).getTime() - dateFormatForSort(b.creationDate).getTime(),
      field: "creationDate",
      filtering: false,
      render: (rowData) => {
        return <p>{rowData.creationDate}</p>;
      },
    },
    {
      title: "SAP Code",
      customSort: (a, b) => dateFormatForSort(a.externalCode).getTime() - dateFormatForSort(b.externalCode).getTime(),
      field: "externalCode",
      filtering: true,
      render: (rowData) => {
        return <p>{rowData.externalCode === null ? "-" : rowData.externalCode}</p>;
      },
    },
    {
      title: "Region",
      field: "region",
      filtering: true,
      render: (rowData) => {
        return <p>{rowData.region === null ? "-" : rowData.region}</p>;
      },
    },
    {
      title: "Sub Region",
      field: "subRegion",
      filtering: true,
      render: (rowData) => {
        return <p>{rowData.subRegion === null ? "-" : rowData.subRegion}</p>;
      },
    },
  ];


  const localization = {
    toolbar: { exportTitle: "" },
  };

  return (
    <div className={classes.table}>
      {isLoaded ? (
        <StyledEngineProvider injectFirst>
          {slug !== undefined && (
            <Button variant="text">
              <Link className={classes.allLocationStyle} to="/locations">
                All Locations
              </Link>
            </Button>
          )}
          <MaterialTable
            title={tableHeader}
            options={{
              exportAllData: true,
              paging: true,
              emptyRowsWhenPaging: true,
              paginationType: "normal",
              paginationPosition: "bottom",
              numberOfPagesAround: 1,
              showFirstLastPageButtonsState: "all",
              searchFieldAlignment: "left",
              exportButton: true,
              searchFieldStyle: {
                borderBottom: "none",
                border: "1px solid  #E3E7EE",
                height: "30px",
                width: "200px",
              },
              thirdSortClick: false,
              headerStyle: {
                backgroundColor: "#F0F0F0",
                color: "#043042",
                textTransform: "Uppercase",
                fontSize: "12px",
              },
              exportFileName: "Pubinno-Locations-CSV",
              detailPanelType: "single",
              cellStyle: {
                fontFamily: "RobotoRegular",
                fontSize: "11px",
                color: "#043042",
              },
              toolbar: slug === undefined ? true : false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50],
              rowStyle: (rowData) => ({
                border: "2px solid #f0f0f0",
                backgroundColor:
                  rowData.tableData.id === selectedRow && "rgba(4,48,66,0.05)",
              }),
              sorting: true,
              filtering: true,
              exportMenu: [
                {
                  label: "Export CSV",
                  exportFunc: (cols, datas) =>
                    ExportCsv(cols, datas, "Perfect_Beer_Locations_Export"),
                },
              ],
            }}
            columns={columns}
            localization={localization}
            data={row}
            icons={tableIcons}
            onRowSelected={(rowdata) => (
              setSelectedRow(rowdata.tableData.id)
            )}
            detailPanel={(rowData) => (
              <TableRow dateFilter={dateFilter} rowData={rowData} />
            )}
            onRowClick={(event, rowData, togglePanel) => {
              togglePanel();
              eventLogView(
                "Location Table Detail",
                `${rowData.location} - details clicked!`
              );
            }}
            components={components}
          />
        </StyledEngineProvider>
      ) : (
        <div className={classes.table}>
          <TableSkeleton rowCount={10} />
        </div>
      )}
    </div>
  );
};
export default Index;

import React, { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import SkippedMaintenance from "./ScrollableWidget";
import WarmestBeer from "./ScrollableWidget";
import WorstKeg from "./ScrollableWidget";
import translate from "../../../i18n/translate";
import { MaintenancesContext } from "../../../lib/context/maintenances/MaintenancesContext";
import { TapsContext } from "../../../lib/context/taps/TapsContext";

const useStyles = makeStyles((theme) => ({
  alignTop: {
    display: "flex",
    marginTop: ".6%",
    [theme.breakpoints.down('lg')]: {
      display: "block",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 50,
    },
  },
  alignDown: {
    display: "flex",
    width: "100%",
    marginTop: "1%",
    [theme.breakpoints.down('lg')]: {
      display: "block",
    },
  },
  leftChildLeft: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
      marginRight: "0 !important",
    },
  },
  leftChildRight: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
      marginLeft: "0 !important",
    },
  },
  leftDown: {
    [theme.breakpoints.down('lg')]: {
      width: "100% !important",
      display: "block !important",
    },
  },
}));

const QualityDownWidgets = ({dateFilter, setDateFilter}) => {
  const classes = useStyles();
  const [isLoadedMaintenance, setIsLoadedMaintenance] = React.useState(false);
  const [isLoadedWarmBeer, setIsLoadedWarmBeer] = React.useState(false);
  const [isLoadedKeg, setIsLoadedKeg] = React.useState(false);

  const { getMaintenancesData, maintenances } = useContext(MaintenancesContext);
  const { getKegData, kegData, warmBeerData, getWarmBeerData } = useContext(
    TapsContext
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadedMaintenance(false);
      await getMaintenancesData(dateFilter);
      setIsLoadedMaintenance(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadedKeg(false);
      await getKegData(dateFilter);
      setIsLoadedKeg(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadedWarmBeer(false);
      await getWarmBeerData(dateFilter);
      setIsLoadedWarmBeer(true);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);

  return (
    <>
      <div
        style={{ width: "33.333%", height: "100%", margin: "0 0.5%" }}
        className={classes.leftChildLeft}
      >
        <SkippedMaintenance
          title={translate("skippedMaintenance")}
          days={false}
          scroll={true}
          type="maintenance"
          maintenances={maintenances}
          dateFilter={dateFilter}
          isLoaded={isLoadedMaintenance}
          setDateFilterMaintenance={setDateFilter}
        />
      </div>
      <div
        style={{ width: "33.333%", height: "100%", margin: "0 0.5%" }}
        className={classes.leftChildRight}
      >
        <WarmestBeer
          title={translate("warmestBeer")}
          days={true}
          scroll={true}
          warmBeerData={warmBeerData}
          type="warmestBeer"
          isLoaded={isLoadedWarmBeer}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          isWarmBeer={true}
        />
      </div>

      <div
        style={{ width: "33.333%", height: "100%", margin: "0 0.5%" }}
        className={classes.leftChildRight}
      >
        <WorstKeg
          title={translate("worstKeg")}
          days={true}
          scroll={true}
          type="worstKeg"
          kegData={kegData}
          isLoaded={isLoadedKeg}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
        />
      </div>
    </>
  );
};

export default QualityDownWidgets;

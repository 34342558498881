import React from "react";

const SalesInsightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.593"
      height="13.593"
      viewBox="0 0 13.593 13.593"
    >
      <g id="Group_177" data-name="Group 177" transform="translate(-15 -15)">
        <path
          id="Path_324"
          data-name="Path 324"
          d="M27.642,15.777a.174.174,0,0,1,.175.175v9.437a2.432,2.432,0,0,1-2.447,2.427H18.223a2.437,2.437,0,0,1-2.447-2.447V18.223a2.437,2.437,0,0,1,2.447-2.447h9.418m0-.777H18.223A3.216,3.216,0,0,0,15,18.223v7.165a3.211,3.211,0,0,0,3.223,3.2h7.165a3.211,3.211,0,0,0,3.2-3.224V15.952A.953.953,0,0,0,27.642,15Z"
          fill="#9ca7b6"
        />
        <g
          id="Group_174"
          data-name="Group 174"
          transform="translate(21.408 17.117)"
        >
          <path
            id="Path_325"
            data-name="Path 325"
            d="M48.388,34.813a.37.37,0,0,1-.388-.35V26.25a.391.391,0,0,1,.777,0v8.214A.37.37,0,0,1,48.388,34.813Z"
            transform="translate(-48 -25.9)"
            fill="#9ca7b6"
          />
        </g>
        <g
          id="Group_175"
          data-name="Group 175"
          transform="translate(17.913 21.389)"
        >
          <path
            id="Path_326"
            data-name="Path 326"
            d="M30.388,52.541c-.214,0-.388-.078-.388-.175V48.075c0-.1.175-.175.388-.175s.388.078.388.175v4.292C30.777,52.444,30.6,52.541,30.388,52.541Z"
            transform="translate(-30 -47.9)"
            fill="#9ca7b6"
          />
        </g>
        <g
          id="Group_176"
          data-name="Group 176"
          transform="translate(24.904 18.961)"
        >
          <path
            id="Path_327"
            data-name="Path 327"
            d="M66.388,42.468c-.214,0-.388-.117-.388-.272V35.672c0-.155.175-.272.388-.272s.388.117.388.272v6.505C66.777,42.332,66.6,42.468,66.388,42.468Z"
            transform="translate(-66 -35.4)"
            fill="#9ca7b6"
          />
        </g>
      </g>
    </svg>
  );
};

export default SalesInsightIcon;

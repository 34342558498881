import React, { useContext, useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ByBrandList from "../total/bybrand/brand-slider/BrandSlider";
import QualityChart from "./quality-chart";
import ByDaysList from "../total/bydays/ByDaysList";
import { SalesContext } from "../../lib/context/sales/SalesContext";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 20px",
    width: "67%",
    height: "500px",
    margin: "0 0.5%",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      height: "100%",
    },
  },
  alignTop: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "5px 1%",
    height: "70%",
    marginBottom: 10,
    [theme.breakpoints.down('lg')]: {
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
  },
  alignDown: {
    height: "30%",
    paddingTop: 20,
    borderTop: "1px solid #E8E8E8",
    marginTop: "auto",
  },
  alignInfo: {
    [theme.breakpoints.down('lg')]: {
      marginTop: -40,
    },
  },
}));

const Quality = ({ dateFilter, setDateFilter }) => {
  const classes = useStyles();

  const {
    getSalesQualityData,
    currentWeekSalesData,
    previousWeekSalesData,
  } = useContext(SalesContext);
  const [isLoaded, setIsLoaded] = useState(false);
  // eslint-disable-next-line

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      await getSalesQualityData(dateFilter);
      setIsLoaded(true);
    };
    eventLogView(
      "Quality Chart",
      dateFilter.typ === 1
        ? "7 Days Data Called"
        : dateFilter.typ === 2
        ? "30 Days Data Called"
        : "Custom Date Data Called"
    );

    fetchData();
    // eslint-disable-next-line
  }, [dateFilter]);

  return (
    <Paper elevation={5} className={classes.root}>
      <div className={classes.alignTop + " alignTopQ"}>
        <QualityChart
          dateFilter={dateFilter}
          currentWeekSalesData={currentWeekSalesData}
          previousWeekSalesData={previousWeekSalesData}
          isLoaded={isLoaded}
          isTotal={false}
        />
        <ByDaysList
          currentWeekSalesData={currentWeekSalesData}
          previousWeekSalesData={previousWeekSalesData}
          isLoaded={isLoaded}
        />
      </div>
      <div className={classes.alignDown}>
        <ByBrandList dateFilter={dateFilter} setDateFilter={setDateFilter} />
      </div>
    </Paper>
  );
};

export default Quality;

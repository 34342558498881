import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import { UserMenuItem } from "./HeaderStyles";
import { StyledMenu, StyledMenuItem } from "./HeaderStyles";
import EmptyNotification from "./EmptyNotificationIcon";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import Pulse from "react-reveal/Pulse";
import Flip from "react-reveal/Flip";
import translate from "../../i18n/translate";

const useStyles = makeStyles(() => ({
  notificationSubText: {
    fontWeight: "bold",
  },
  notificationAlign: {
    display: "flex",
    justifyContent: "space-around",
  },
  empty: {
    height: "70px",
    padding: "0",
    "&:hover": {
      backgroundColor: "white ",
    },
  },
  notificationMenu: {
    "&:focus": {
      backgroundColor: "white ",
    },
  },
}));

const convertDate = (date) => {
  let convertDate = new Date(date);
  const dateOptions = {
    day: "numeric",
    weekday: "short",
  };

  return convertDate.toLocaleDateString('en-Ca', dateOptions);
};

const Notification = ({
  anchorEl,
  handleClose,
  notifications,
  handleClick,
  count,
}) => {
  const classes = useStyles();
  const { notificationHelper } = React.useContext(UserAuthContext);

  const notificationExist = (
    <div style={{ margin: "-8px 0" }}>
      {notifications?.map(({ subject, body, creationDate, logoUrl }) => (
        <StyledMenuItem
          key={Math.random()}
          className={classes.notificationMenu}
        >
          <ListItemIcon>
            <img height="40px" src={logoUrl} alt="pubinno logo" />
          </ListItemIcon>
          <div className={classes.notificationAlign}>
            <div>
              <Typography variant="body1">{subject}</Typography>
              <Typography className={classes.notificationSubText}>
                {body}
              </Typography>
            </div>
            <div style={{ marginLeft: "45px" }}>
              <Typography variant="body1">
                {convertDate(creationDate)}
              </Typography>
            </div>
          </div>
        </StyledMenuItem>
      ))}
    </div>
  );

  const notificationEmpty = (
    <div style={{ backgroundColor: "white", width: "100%" }}>
      <UserMenuItem className={classes.empty} disableTouchRipple={true}>
        <div style={{ margin: "0 auto", paddingTop: 20 }}>
          <Flip spy={count} duration={300}>
            <EmptyNotification />
          </Flip>
        </div>
      </UserMenuItem>
      <UserMenuItem className={classes.empty} disableTouchRipple={true}>
        <Pulse spy={count} duration={300}>
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: "bold",
              color: "#043042",
              margin: "0 20px",
              paddingBottom: 20,
            }}
          >
            {translate("emptyNotification")}
          </Typography>
        </Pulse>
      </UserMenuItem>
    </div>
  );

  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      onClick={handleClick}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {notificationHelper?.notifications?.length > 0
        ? notificationExist
        : notificationEmpty}
    </StyledMenu>
  );
};

export default Notification;

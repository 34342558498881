import React from "react";
import LastDaysList from "./LastDaysList";
import { makeStyles } from "@mui/styles";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const handlePaddingLeft = (width) => {
  if (width > 1420 && width < 1570) {
    return 40;
  }
  if (width > 1340 && width < 1420) {
    return 35;
  }
  if (width > 1145 && width < 1340) {
    return 15;
  }
  if (width < 1145) {
    return 0;
  } else {
    return 80;
  }
};

const ByDaysList = ({
  currentWeekSalesData,
  isLoaded,
  isTotal,
}) => {
  const { width } = useWindowDimensions();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "42%",
      marginLeft:30,
      paddingLeft: handlePaddingLeft(width),
      marginTop: '6%',
      [theme.breakpoints.down('lg')]: {
        marginTop: '7%',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LastDaysList
        currentData={currentWeekSalesData}
        isLoaded={isLoaded}
        isTotal={isTotal}
      />
    </div>
  );
};

export default ByDaysList;

import { MaintenancesContext } from "./MaintenancesContext";
import React, { useState } from "react";
import axios from "axios";
import { MAINTENANCE_URL } from "../../urls/urls";
import { Exports, ExportTypes, convertDate } from "../../../components/export/exports";

const MaintenancesProvider = ({ children }) => {
  const [maintenances, setMaintenances] = useState([]);

  const getMaintenancesData = async (dateFilter) => {
    const endAt = new Date();
    const startAt = new Date();
    const convertedEndAt = dateFilter.typ === 3 ? convertDate(dateFilter.endDate) : convertDate(endAt);
    const queryDateDiff = dateFilter.typ === 1 ? 7 : dateFilter.typ === 2 ? 30 : dateFilter.startDate - dateFilter.endDate;
    startAt.setDate(startAt.getDate() - queryDateDiff);
    const convertStartAt = dateFilter.typ === 3 ? convertDate(dateFilter.startDate) : convertDate(startAt);

    const email = localStorage.getItem("email");
    const storageKey = "getMaintenancesData-" + email + "-" + convertStartAt + "-" + convertedEndAt;
    const hasData = Object.keys(localStorage).find(i => i === storageKey)
    if (hasData) {
      const data = JSON.parse(localStorage.getItem(storageKey)) ?? "";
      setMaintenances(data);
    } else {
      const localToken = localStorage.getItem("userToken");

      const QUERY_SORT = `?PageSize=11111&PageNumber=1&StartDate=${convertStartAt}&endDate=${convertedEndAt+ "T23:59:59"}`;
      const GET_URL = MAINTENANCE_URL + QUERY_SORT;

      let res = await axios.get(GET_URL, {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      const { data } = res;
      setMaintenances(data.data);
      const oldKey = Object.keys(localStorage).find(i => i.includes("getMaintenancesData-"));
      if (oldKey && oldKey !== storageKey) {
        localStorage.removeItem(oldKey)
      }
      localStorage.setItem(storageKey, JSON.stringify(data.data));
    }
  };
  const exportWidgetSkippedMaintenanceData = async () => {
    const localToken = localStorage.getItem("userToken");
    Exports(localToken, "", MAINTENANCE_URL, ExportTypes.SkippedMaintenanceData)
  };

  return (
    <MaintenancesContext.Provider
      value={{
        maintenances: maintenances,
        getMaintenancesData: getMaintenancesData,
        exportWidgetSkippedMaintenanceData: exportWidgetSkippedMaintenanceData,
      }}
    >
      {children}
    </MaintenancesContext.Provider>
  );
};

export default MaintenancesProvider;

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {Drawer, AppBar, CssBaseline } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Divider, List, Paper } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Header from "../header";
import Footer from "../footer";
import SubHeader from "../header/SubHeader";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import translate from "../../i18n/translate";
import HomeIcon from "./menu-icons/HomeIcon";
import LocationIcon from "./menu-icons/LocationIcon";
import QualityIcon from "./menu-icons/QualityIcon";
import SalesInsightIcon from "./menu-icons/SalesInsightIcon";
import KegIcon from "./menu-icons/KegIcon";
import SegmentIcon from "./menu-icons/SegmentIcon";
import EfficiencyIcon from "./menu-icons/EfficiencyIcon";
import MonthlyIcon from "./menu-icons/MonthlyIcon";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import { useLocation } from "react-router-dom";
import MaintenancesProvider from "../../lib/context/maintenances/MaintenancesProvider";
import TapsProvider from "../../lib/context/taps/TapsProvider";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import SalesProvider from "../../lib/context/sales/SalesProvider";
import BrandsProvider from "../../lib/context/brands/BrandsProvider";
import CustomersProvider from "../../lib/context/customers/CustomersProvider";
import InstallationsProvider from "../../lib/context/installations/InstallationsProvider"
import SmartTapsProvider from "../../lib/context/smarttaps/SmartTapsProvider"

const drawerWidth = 280;

export default function MiniDrawer({ children, dateFilter, setDateFilter }) {
  const { width } = useWindowDimensions();
  const { getUser, notificationHelper } = React.useContext(UserAuthContext);
  const [open, setOpen] = React.useState(true);
  const [openPremium, setOpenPremium] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const { pathname } = useLocation();
  const currentPageUrl = pathname.substring(
    pathname.length - (pathname.length - 1)
  );

  const [isSelected, setIsSelected] = React.useState(currentPageUrl);

  React.useEffect(() => {
    const fetch = async () => {
      await getUser();
      // eslint-disable-next-line
      await notificationHelper?.getNotification();
      setIsLoaded(true);
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  const menuClickHandler = (link) => {
    let categoryName = "Navigation Links";
    let actionName = `${link} Page clicked!`;
    eventLogView(categoryName, actionName);
    setIsSelected(link);
  };

  const MD_WIDTH = 1280;

  React.useEffect(() => {
    if (width < MD_WIDTH) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [width]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& .MuiDrawer-paper": {
        overflowY: "visible !important",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "30px !important",
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    appBarFooter: {
      zIndex: theme.zIndex.drawer + 1,
      top: "auto",
      bottom: 0,
    },
    hide: {
      display: "none",
    },
    drawer: {
      zIndex: theme.zIndex.drawer - 999,
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: 100,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: 100,
      }),
      overflowX: "visible",
      width: theme.spacing(9),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(6),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: -5,
    },
    icons: {
      marginTop: "135px",
    },
    chevronLeftIcon: {
      marginLeft: 265,
      position: "absolute",
      top: "45%",
      marginTop: 40,
      cursor: "pointer",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: 500,
      }),
      [theme.breakpoints.down('md')]: {
        top: "19.5%",
      },
    },
    chevronRightIcon: {
      position: "absolute",
      top: "45%",
      marginTop: 40,
      marginLeft: 60,
      cursor: "pointer",
      [theme.breakpoints.down('md')]: {
        top: "25%",
      },
    },
    chevronBorder: {
      borderRadius: "50%",
      border: "1.5px solid #C43042",
      backgroundColor: "#C43042",
      color: "white",
    },
    sales: {
      fontFamily: theme.typography.menu,
      fontWeight: "bold",
      fontSize: "1.25em",
      "&:hover": {
        color: "#656565 !important",
      },
    },
    premium: {
      color: theme.palette.secondary.contrastText,
      fontFamily: theme.typography.menu,
      fontSize: ".8em",
      marginLeft: 10,
    },
    listItemText: {
      fontFamily: theme.typography.menu,
      fontSize: "1.25em",
      "&:hover": {
        color: "#656565 !important",
      },
    },
    listItemText2: {
      fontFamily: theme.typography.menu,
      fontSize: "1.25em",
    },
    listItem: {
      margin: "0 12px",
      "&:hover": {
        backgroundColor: "inherit",
        "& .MuiListItemIcon-root": {
          transform: "scale(1) translate(-15%, 0)",
          transitionDuration: "0.5s",
        },
      },
    },
    listItem2: {
      "&:hover": {
        backgroundColor: "inherit",
        "& .MuiListItemIcon-root": {
          transform: "scale(1) translate(-15%, 0)",
          transitionDuration: "0.5s",
        },
      },
    },
    listItem3: {
      "&:hover": {
        cursor: "context-menu",
      },
    },
    listItemIcon: {
      color: "#9CA7B6",
    },
    listItemIcon2: {
      color: "#9CA7B6",
      marginLeft: open ? "50px !important" : "60px !important",
    },
    footerFix: {
      height: 28,
      width: "100%",
      [theme.breakpoints.down('lg')]: {
        height: 0,
      },
    },
    tooltip: {
      "& p": {
        margin: "0",
        fontFamily: "RobotoRegular",
      },
    },
  }));

  const classes = useStyles();

  const home = translate("home");
  const locations = translate("locations");
  const quality = translate("quality");
  const salesInsight = translate("salesInsight");
  const premium = translate("premium");
  const kegOptimization = translate("kegOptimization");
  const segmentReports = translate("segmentReports");
  const efficiencyTrend = translate("efficiencyTrend");
  const monthlyPredictions = translate("monthlyPredictions");

  const menuItems = [
    {
      text: home,
      icon: (
        <div>
          <HomeIcon />
        </div>
      ),
      link: "home",
    },
    {
      text: locations,
      icon: (
        <div style={{ marginLeft: 2 }}>
          <LocationIcon />
        </div>
      ),
      link: "locations",
    },
    {
      text: quality,
      icon: (
        <div style={{ marginLeft: 4 }}>
          <QualityIcon />
        </div>
      ),
      link: "quality",
    }
  ];

  const subMenuItems = [
    {
      text: kegOptimization,
      icon: (
        <div style={{ marginLeft: open ? 4 : -47 }}>
          <KegIcon />
        </div>
      ),
      link: "keg-optimization",
    },
    {
      text: segmentReports,
      icon: (
        <div style={{ marginLeft: open ? 4 : -47 }}>
          <SegmentIcon color={"#9ca7b6"} />
        </div>
      ),
      link: "segment-reports",
    },
    {
      text: efficiencyTrend,
      icon: (
        <div style={{ marginLeft: open ? 4 : -45 }}>
          <EfficiencyIcon />
        </div>
      ),
      link: "efficiency-trend",
    },
    {
      text: monthlyPredictions,
      icon: (
        <div style={{ marginLeft: open ? 0 : -48 }}>
          <MonthlyIcon />
        </div>
      ),
      link: "monthly-predictions",
    },
  ];

  const handleSubItemsOpen = () => {
    setOpenPremium(!openPremium);
  };

  const drawerState = open ? (
    <div onClick={handleDrawerClose} className={classes.chevronLeftIcon}>
      <ChevronLeftIcon className={classes.chevronBorder} />
    </div>
  ) : (
      <div onClick={handleDrawerOpen} className={classes.chevronRightIcon}>
        <ChevronRightIcon
          className={classes.chevronBorder}
          onClick={handleDrawerOpen}
        />
      </div>
    );

  const drawerCollapse = (
    <Collapse in={openPremium} timeout={1000}>
      {openPremium &&
        subMenuItems.map(({ text, icon, link }) =>
          link !== "sales-dashboard" ? (
            <Tooltip
              key={link + Math.random()}
              title={
                <p style={{ fontWeight: "bold", fontSize: 14, width: 160 }}>
                  Coming soon...
                  <br />
                  <br />
                  <span
                    style={{
                      fontWeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    Our work continues, we will meet soon. <br />
                    Thank you for your understanding.
                  </span>
                </p>
              }
              arrow
              className={classes.tooltip}
            >
              <ListItem
                key={link + Math.random()}
                className={classes.listItem3}
                disableTouchRipple={true}
              >
                <ListItemIcon  onClick={() => menuClickHandler(link)}  className={classes.listItemIcon2}>
                  {icon}
                </ListItemIcon>
                <Typography
                  className={classes.listItemText2}
                  style={{
                    color: "#d4d4d4",
                  }}
                  onClick={() => menuClickHandler(link)}
                >
                  {open ? text : null}
                </Typography>
              </ListItem>
            </Tooltip>
          ) : open ? (
            <Link key={link + Math.random()} to={`/${link}`}>
              <ListItem
                button
                className={classes.listItem2}
                disableTouchRipple={true}
              >
                <ListItemIcon  onClick={() => menuClickHandler(link)}  className={classes.listItemIcon2}>
                  {icon}
                </ListItemIcon>

                <Typography
                  className={classes.listItemText}
                  style={{
                    color: isSelected === link ? "#C43042" : "#9CA7B6",
                  }}
                  onClick={() => menuClickHandler(link)}
                >
                  {open ? text : null}
                </Typography>
              </ListItem>
            </Link>
          ) : (
                <Tooltip title="Sales Dashboard" arrow>
                  <ListItem
                    button
                    key={link + Math.random()}
                    className={classes.listItem2}
                    disableTouchRipple={true}
                  >
                    <ListItemIcon  onClick={() => menuClickHandler(link)}  className={classes.listItemIcon2}>
                      {icon}
                    </ListItemIcon>

                    <Typography
                      className={classes.listItemText}
                      style={{
                        color: isSelected === link ? "#C43042" : "#9CA7B6",
                      }}
                      onClick={() => menuClickHandler(link)}
                    >
                      {open ? text : null}
                    </Typography>
                  </ListItem>
                </Tooltip>
              )
        )}
    </Collapse>
  );

  const drawerSubItems = open ? (
    <ListItem
      button
      className={classes.listItem}
      disableTouchRipple={true}
      onClick={handleSubItemsOpen}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <SalesInsightIcon />
      </ListItemIcon>
      <div>
        <Typography
          className={classes.sales}
          onClick={() => menuClickHandler("salesInsight")}
          style={{
            color: isSelected === "salesInsight" ? "#C43042" : "#9CA7B6",
          }}
        >
          {translate("salesInsight")}
          <span className={classes.premium}>{premium}</span>
        </Typography>
      </div>
    </ListItem>
  ) : (
      <Tooltip title="Sales Insight" arrow>
        <ListItem
          button
          className={classes.listItem}
          disableTouchRipple={true}
          onClick={handleSubItemsOpen}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <SalesInsightIcon />
          </ListItemIcon>
          <div>
            <Typography
              className={classes.sales}
              style={{
                color: isSelected === "salesInsight" ? "#C43042" : "#9CA7B6",
              }}
              onClick={() => menuClickHandler("salesInsight")}
            >
              {open ? salesInsight : null}
              <span className={classes.premium}>{open ? premium : null}</span>
            </Typography>
          </div>
        </ListItem>
      </Tooltip>
    );

  const menuItem = menuItems.map(({ text, icon, link }) =>
    !open ? (
      <Tooltip title={text} arrow key={link + Math.random()}>
        <ListItem
          button
          className={classes.listItem}
          disableTouchRipple={true}
          onClick={() => menuClickHandler(link)}
        >
          <Link to={`/${link}`} style={{ display: "flex" }}>
            <ListItemIcon  onClick={() => menuClickHandler(link)}  className={classes.listItemIcon}>{icon}</ListItemIcon>
            <Typography
              className={classes.listItemText}
              style={{
                color: isSelected === link ? "#C43042" : "#9CA7B6",
              }}
              onClick={() => menuClickHandler(link)}
            >
              {open ? text : null}
            </Typography>
          </Link>
        </ListItem>
      </Tooltip>
    ) : (
        <ListItem
          button
          key={link}
          className={classes.listItem}
          disableTouchRipple={true}
        >
          <Link to={`/${link}`} style={{ display: "flex" }}>
            <ListItemIcon  onClick={() => menuClickHandler(link)}  className={classes.listItemIcon}>{icon}</ListItemIcon>
            <Typography
              className={classes.listItemText}
              style={{
                color: isSelected === link ? "#C43042" : "#9CA7B6",
              }}
              onClick={() => menuClickHandler(link)}
            >
              {text}
            </Typography>
          </Link>
        </ListItem>
      )
  );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Header />
          <MaintenancesProvider>
            <TapsProvider>
              <SalesProvider>
                <BrandsProvider>
                  <CustomersProvider>
                    <InstallationsProvider>
                      <SmartTapsProvider>
                        {pathname !== "/not-found" && <SubHeader dateFilter={dateFilter} setDateFilter={setDateFilter} />}
                      </SmartTapsProvider>
                    </InstallationsProvider>
                  </CustomersProvider>
                </BrandsProvider>
              </SalesProvider>
            </TapsProvider>
          </MaintenancesProvider>
        </AppBar>
        {pathname !== "/not-found" && (
          <Paper elevation={5}>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <Divider />
              {isLoaded && (
                <List className={classes.icons}>
                  {menuItem}
                  {drawerSubItems}
                  {drawerCollapse}
                </List>
              )}
              {drawerState}
            </Drawer>
          </Paper>
        )}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* {drawerState2} */}
          {isLoaded && <div>{children}</div>}
          <div className={classes.footerFix} />
        </main>

        <AppBar position="fixed" className={clsx(classes.appBarFooter)}>
          <Footer />
        </AppBar>
      </div>
    </>
  );
}

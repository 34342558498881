import React from "react";

const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68.316"
      height="68.316"
      viewBox="0 0 68.316 68.316"
    >
      <g
        id="Group_28"
        data-name="Group 28"
        transform="translate(129.832 5.709)"
      >
        <rect
          id="Rectangle_30"
          data-name="Rectangle 30"
          width="55.032"
          height="36.331"
          transform="translate(-122.656 9.635)"
          fill="#c43042"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M-91.982,26.611l13.526,11.562h0a.786.786,0,0,0,1.113,0l0,0,.116-.11,15.991-11.274a2.309,2.309,0,0,0-.876-.178Z"
          transform="translate(-11.941 -10.196)"
          fill="#fff"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M-95.674-5.709a34.158,34.158,0,0,0-34.158,34.158A34.158,34.158,0,0,0-95.674,62.607,34.158,34.158,0,0,0-61.516,28.449h0A34.158,34.158,0,0,0-95.674-5.709Zm-20.905,28.75h10.056a.78.78,0,0,1,.7.849.78.78,0,0,1-.7.7H-116.58a.781.781,0,0,1-.7-.85A.781.781,0,0,1-116.58,23.041Zm-5.223,7h0a.781.781,0,0,1-.85-.7.78.78,0,0,1,.7-.849.747.747,0,0,1,.146,0H-101a.78.78,0,0,1,.7.849.78.78,0,0,1-.7.7Zm6.592,4.682a.78.78,0,0,1,.766-.794h17.121a.78.78,0,0,1,.849.7.78.78,0,0,1-.7.85.865.865,0,0,1-.146,0h-17.113A.78.78,0,0,1-115.211,34.719Zm42.742,3.423h0a3.9,3.9,0,0,1-3.895,3.895h-32.57a.432.432,0,0,1-.322-.518v0l.171-.685a.431.431,0,0,1,.424-.335h32.3a2.342,2.342,0,0,0,2.334-2.334l2.314-19.393A2.315,2.315,0,0,0-72,17.681L-88.192,29.1a2.341,2.341,0,0,1-3.238.048l-15.744-13.424a.486.486,0,0,1-.04-.687.484.484,0,0,1,.355-.162h32.83a3.9,3.9,0,0,1,3.895,3.895h0Z"
          transform="translate(0 0)"
          fill="#f8f8f8"
        />
      </g>
    </svg>
  );
};

export default SendIcon;

import React, { useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { Typography, Button, Box } from "@mui/material";
import SendIcon from "../login/SendIcon";
import { Link } from "react-router-dom";
import PrevIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { FORGOT_URL } from "../../lib/urls/urls";
import Fade from "react-reveal/Fade";
import translate from "../../i18n/translate";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import RedLogoSlogan from '../../assets/redLogo_slogan.png'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 80px",
    [theme.breakpoints.down('lg')]: {
      padding: "80px",
    },
    [theme.breakpoints.down('md')]: {
      padding: "20px",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.dark,
      fontStyle: "italic",
      fontSize: "1rem",
    },
    "& MuiInput-root": {
      "&:hover": {
        borderBottomColor: theme.palette.primary.main,
      },
    },
  },
  form: {
    width: "50%",
    margin: "auto 0",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
  zeroPadding: {
    padding: "2px",
  },
  marginFix: {
    width: "100%",
    marginTop: 30,
    "&:hover": {
      backgroundColor: "#ab2a39",
    },
  },
  marginFix3: {
    width: "100%",
    marginTop: 10,
    "&:hover": {
      backgroundColor: "#ab2a39",
    },
  },
  logo: {
    marginBottom: 70,
    width: "130px",
    [theme.breakpoints.down('xl')]: {
      width: "65%",
    },
    [theme.breakpoints.down('lg')]: {
      width: "25%",
    },
  },
  text: {
    fontSize: "1em",
    fontStyle: "italic",
    marginTop: 20,
    cursor: "pointer",
  },
  text2: {
    fontSize: "1em",
    fontStyle: "italic",
    marginTop: 20,
  },
  text3: {
    fontFamily: "RobotoCondensed",
    fontSize: "1.1em",
    fontStyle: "italic",
    marginTop: -10,
    color: "#444444",
  },
  text4: {
    fontFamily: "RobotoCondensed",
    fontSize: "1.1em",
    fontStyle: "italic",
    marginTop: 10,
    color: "#444444",
  },
  border: {
    bordorBottom: "1px solid black",
  },
}));

const InputCustom = withStyles({
  root: {
    "& label.Mui-underline": {
      color: "#C43042",
      marginTop: "5px",
      "&:hover": {
        borderBottomColor: "#C433042",
      },
    },
    "& label.Mui-focused": {
      color: "#C43042",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#C43042",
      "&:hover": {
        borderBottomColor: "#C43042",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#C43042",
      "&:hover": {
        borderBottomColor: "#C43042",
      },
    },
  },
})(TextField);

const LoginInputCard = () => {
  const classes = useStyles();
  const [email, setEmail] = useState({ email: "", isSent: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail({ ...email, isSent: true });
    const response = await axios.post(FORGOT_URL, {
      email: email.email,
    });

    if (response.status === 200) {
      setEmail({ ...email, isSent: true });
    }
    // Google Analytics
    eventLogView("Forgot Password", "Send E-Mail Clicked!");
  };

  const enterEmail = (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Fade>
        <Typography className={classes.text2} variant="body2" color="primary">
          {translate("mailAsk")}
        </Typography>
        <InputCustom
          fullWidth
          type="email"
          onChange={(e) => setEmail({ ...email, email: e.target.value })}
          required
          value={email.email}
        />
      </Fade>
      <Fade bottom>
        <Button
          className={classes.marginFix3}
          variant="contained"
          color="primary"
          type="submit"
          title="SEND EMAIL"
        >
          {translate("sendButton")}
        </Button>
      </Fade>
    </form>
  );

  const backToLogin = (
    <>
      <SendIcon />
      <Fade left>
        <div className={classes.border}>
          <Typography className={classes.text3} variant="body2">
            {translate("checkMail")}
          </Typography>
        </div>
      </Fade>

      <Link to="/login">
        <Fade>
          <Button
            className={classes.marginFix}
            variant="contained"
            color="primary"
            type="submit"
            title="Giriş"
            startIcon={<PrevIcon />}
            onClick={() =>
              eventLogView("Forgot Password", "Back To Home Clicked!")
            }
          >
            {translate("loginBack")}
          </Button>
        </Fade>
      </Link>
    </>
  );

  return (
    <div className={classes.form}>
      <div className={classes.root}>
        <Box margin="0 auto 30px auto" className={classes.logo}>
          <img
            width="100%"
            src={RedLogoSlogan}
            alt="Pubinno red logo"
          />
        </Box>

        {email.isSent ? backToLogin : enterEmail}
      </div>
    </div>
  );
};

export default LoginInputCard;

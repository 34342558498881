import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Button, Modal, Alert, TextField } from "@mui/material";
import { UserAuthContext } from "../../lib/context/user/UserAuth";
import { eventLogView } from "../../lib/google-analytics/GoogleAnalytics";
import translate from "../../i18n/translate";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 622,
    height: "max-content",
    [theme.breakpoints.down('md')]: {
      width: "80%",
    },
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  textArea: {
    width: "100%",
    marginTop: "20px",
  },
  notchedOutline: {
    backgroundColor: "rgba(112,112,112,0.1)",
    borderRadius: "5px",
    border: "none",
  },
  subText: {
    margin: "10px 0",
  },
  btn: {
    width: "100%",
    borderRadius: "5px",
    height: "60px",
    "&:hover": {
      backgroundColor: "#b52d3d",
    },
  },
}));

const successMessage = (sendCode) => {
  if (sendCode === 200) {
    return (
      <div>
        <Alert severity="success">{translate("feedbackSuccess")}</Alert>
      </div>
    );
  }
  return (
    <div>
      <Alert severity="error">
        Uoops! <br />
        {translate("feedbackError")}
      </Alert>
    </div>
  );
};

export default function SimpleModal({
  handleOpen,
  handleClose,
  setIsModalOpen,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const { sendFeedback } = useContext(UserAuthContext);
  const [message, setMessage] = React.useState("");
  const [responseCode, setResponseCode] = React.useState(null);

  const getLanguageFrom = JSON.parse(localStorage.getItem("userLanguage"));

  const handleFeedback = async (e) => {
    e.preventDefault();
    eventLogView("Feedback", "Send Feedback Clicked!");
    let response = await sendFeedback(message);
    setResponseCode(response);
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Box marginTop="15px">
          {getLanguageFrom === "en-US" ? (
            <Typography variant="h2" color="primary">
              {translate("send")} <br /> {translate("feedback")}
            </Typography>
          ) : (
            <Typography variant="h2" color="primary">
              {translate("feedback")} <br /> {translate("send")}
            </Typography>
          )}
        </Box>
        <Box
          marginTop="15px"
          style={{ cursor: "pointer" }}
          onClick={() => setIsModalOpen(false)}
        >
          <Typography variant="h6" style={{ color: "#B7B7B7" }}>
            x
          </Typography>
        </Box>
      </div>
      <Box>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.subText}
        >
          {translate("feedbackMessage")}
        </Typography>
      </Box>
      {responseCode === null ? (
        <React.Fragment>
          <Box>
            <TextField
              onChange={(e) => setMessage(e.target.value)}
              id="outlined-multiline-static"
              multiline
              rows={12}
              variant="standard"
              className={classes.textArea}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                  adornedEnd: classes.endAdornment,
                },
              }}
            />
          </Box>
          <Box marginTop="20px">
            <Button
              onClick={(e) => handleFeedback(e)}
              className={classes.btn}
              color="primary"
              variant="contained"
            >
              {translate("send")}
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        successMessage(responseCode)
      )}
      <SimpleModal />
    </div>
  );

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}
